/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoginInput,
  PagedOutputUserOutput,
  RefreshTokenInput,
  TokenResponse,
  UserCreateInput,
  UserOutput,
  UserUpdateInput,
} from '../models/index';
import {
    LoginInputFromJSON,
    LoginInputToJSON,
    PagedOutputUserOutputFromJSON,
    PagedOutputUserOutputToJSON,
    RefreshTokenInputFromJSON,
    RefreshTokenInputToJSON,
    TokenResponseFromJSON,
    TokenResponseToJSON,
    UserCreateInputFromJSON,
    UserCreateInputToJSON,
    UserOutputFromJSON,
    UserOutputToJSON,
    UserUpdateInputFromJSON,
    UserUpdateInputToJSON,
} from '../models/index';

export interface CreateUserRequest {
    userCreateInput: UserCreateInput;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface GetManyUsersRequest {
    page?: number;
    perPage?: number;
}

export interface GetOneUserRequest {
    userId: string;
}

export interface LoginRequest {
    loginInput: LoginInput;
}

export interface RefreshTokenRequest {
    refreshTokenInput: RefreshTokenInput;
}

export interface RevokeUserTokenRequest {
    userId: string;
}

export interface UpdateUserRequest {
    userId: string;
    userUpdateInput: UserUpdateInput;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Create user
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOutput>> {
        if (requestParameters.userCreateInput === null || requestParameters.userCreateInput === undefined) {
            throw new runtime.RequiredError('userCreateInput','Required parameter requestParameters.userCreateInput was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateInputToJSON(requestParameters.userCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutputFromJSON(jsonValue));
    }

    /**
     * Create user
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOutput> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete user
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete user
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     * Get current logged-in user\'s information
     */
    async getCurrentUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutputFromJSON(jsonValue));
    }

    /**
     * Get current logged-in user\'s information
     */
    async getCurrentUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOutput> {
        const response = await this.getCurrentUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get multiple users
     */
    async getManyUsersRaw(requestParameters: GetManyUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedOutputUserOutput>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedOutputUserOutputFromJSON(jsonValue));
    }

    /**
     * Get multiple users
     */
    async getManyUsers(requestParameters: GetManyUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedOutputUserOutput> {
        const response = await this.getManyUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get user by id
     */
    async getOneUserRaw(requestParameters: GetOneUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOutput>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getOneUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutputFromJSON(jsonValue));
    }

    /**
     * Get user by id
     */
    async getOneUser(requestParameters: GetOneUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOutput> {
        const response = await this.getOneUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * User login
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters.loginInput === null || requestParameters.loginInput === undefined) {
            throw new runtime.RequiredError('loginInput','Required parameter requestParameters.loginInput was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/users/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginInputToJSON(requestParameters.loginInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     * User login
     */
    async login(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout user (revoke own refresh token)
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logout user (revoke own refresh token)
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logoutRaw(initOverrides);
    }

    /**
     * Refresh access token
     */
    async refreshTokenRaw(requestParameters: RefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters.refreshTokenInput === null || requestParameters.refreshTokenInput === undefined) {
            throw new runtime.RequiredError('refreshTokenInput','Required parameter requestParameters.refreshTokenInput was null or undefined when calling refreshToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/refresh-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenInputToJSON(requestParameters.refreshTokenInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     * Refresh access token
     */
    async refreshToken(requestParameters: RefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
        const response = await this.refreshTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Revoke another user\'s refresh token (admin function)
     */
    async revokeUserTokenRaw(requestParameters: RevokeUserTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling revokeUserToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}/revoke-token`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revoke another user\'s refresh token (admin function)
     */
    async revokeUserToken(requestParameters: RevokeUserTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.revokeUserTokenRaw(requestParameters, initOverrides);
    }

    /**
     * Update user
     */
    async updateUserRaw(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserOutput>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUser.');
        }

        if (requestParameters.userUpdateInput === null || requestParameters.userUpdateInput === undefined) {
            throw new runtime.RequiredError('userUpdateInput','Required parameter requestParameters.userUpdateInput was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateInputToJSON(requestParameters.userUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutputFromJSON(jsonValue));
    }

    /**
     * Update user
     */
    async updateUser(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserOutput> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
