import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { usePhrases } from "../../../context/PhrasesContext";
import { CustomerEditForm } from "../../layout/CustomerEditForm";

export const CustomerEditPage = () => {
  const { getPhrase } = usePhrases();

  return (
    <Container>
      <Box my={5}>
        <Typography component="h1" variant="h4">
          {getPhrase("CUSTOMER.EDIT_PAGE.TITLE")}
        </Typography>
      </Box>
      <Box>
        <CustomerEditForm />
      </Box>
    </Container>
  );
};
