/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CancellationReason = {
    Other: 'OTHER'
} as const;
export type CancellationReason = typeof CancellationReason[keyof typeof CancellationReason];


export function CancellationReasonFromJSON(json: any): CancellationReason {
    return CancellationReasonFromJSONTyped(json, false);
}

export function CancellationReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancellationReason {
    return json as CancellationReason;
}

export function CancellationReasonToJSON(value?: CancellationReason | null): any {
    return value as any;
}

