import Joi from "joi";
import {
  Configuration,
  DiscountType,
  OrderApi,
  OrderSource,
  OrderTags,
  OrderType,
  ProductCategory,
  StoreBranch,
  TimeOfDay,
} from "../generated-api";
import { getAccessToken } from "../utils/getAccessToken";
import { addressValidationSchema } from "./CustomerApi";

class OrderService extends OrderApi {
  constructor() {
    const config = new Configuration({
      accessToken: getAccessToken,
      basePath: process.env.REACT_APP_API_URL,
    });

    super(config);
  }
}

export const orderService = new OrderService();

// Validation schemas

const ORDER_EQUIPMENT_CREATE_INPUT_SCHEMA = Joi.object({
  equipmentId: Joi.string().trim().required(),
  quantity: Joi.number().integer().min(1).required(),
  pricePerUnit: Joi.number().min(0).required(),
  referenceCode: Joi.string().trim().allow("").optional(),
});

const ORDER_ITEMS_CREATE_INPUT_SCHEMA = Joi.object({
  productId: Joi.string().trim().required(),
  category: Joi.string()
    .valid(...Object.values(ProductCategory))
    .required(),
  quantity: Joi.number().integer().min(1).required(),
  pricePerUnit: Joi.number().min(0).required(),
});

export const ORDER_CONTACT_UPDATE_INPUT_SCHEMA = Joi.object({
  firstName: Joi.string().trim().required(),
  phone: Joi.string().trim().required(),
  // optional
  lastName: Joi.string().trim().optional(),
});

export const ORDER_CONTACT_CREATE_INPUT_SCHEMA = Joi.object({
  firstName: Joi.string().trim().required(),
  lastName: Joi.string().trim().optional(),
  phone: Joi.string().trim().required(),
});

export const ORDER_DISCOUNT_CREATE_INPUT_SCHEMA = Joi.object({
  discountId: Joi.string().trim().required(),
  name: Joi.string().trim().required(),
  type: Joi.string()
    .valid(...Object.values(DiscountType))
    .required(),
  // If type is percentage, value must be between 0 and 100
  value: Joi.number()
    .min(0)
    .required()
    .when("type", {
      is: DiscountType.Percentage,
      then: Joi.number().max(100),
    }),
  productCategory: Joi.string()
    .valid(...Object.values(ProductCategory))
    .required(),
});

export const ORDER_DISCOUNT_UPDATE_INPUT_SCHEMA = Joi.object({
  discountId: Joi.string().trim().required(),
  name: Joi.string().trim().required(),
  type: Joi.string()
    .valid(...Object.values(DiscountType))
    .required(),
  // If type is percentage, value must be between 0 and 100
  value: Joi.number()
    .min(0)
    .required()
    .when("type", {
      is: DiscountType.Percentage,
      then: Joi.number().max(100),
    }),
  productCategory: Joi.string()
    .valid(...Object.values(ProductCategory))
    .required(),
});

export const orderCreateValidationSchema = Joi.object({
  branch: Joi.string()
    .valid(...Object.values(StoreBranch))
    .trim()
    .required(),
  customerId: Joi.string().trim().required(),
  cpf: Joi.string().trim().optional(),
  cnpj: Joi.string().trim().optional(),
  deliveryAddress: addressValidationSchema.required(),
  nf: Joi.string().trim().allow("").optional(),
  siteOrderNumber: Joi.string().trim().allow("").optional(),
  source: Joi.string()
    .valid(...Object.values(OrderSource))
    .required(),
  tags: Joi.array()
    .items(...Object.values(OrderTags))
    .unique()
    .optional(),
  type: Joi.string()
    .valid(...Object.values(OrderType))
    .optional(),
  items: Joi.array()
    .items(ORDER_ITEMS_CREATE_INPUT_SCHEMA)
    .unique((a, b) => a.productId === b.productId)
    .optional(),
  orderEquipment: Joi.array()
    .items(ORDER_EQUIPMENT_CREATE_INPUT_SCHEMA)
    .optional(),
  scheduledDate: Joi.date().required(),
  // Optional and must on the same day or after scheduledDate
  returnDate: Joi.date().min(Joi.ref("scheduledDate")).optional(),
  contacts: Joi.array().items(ORDER_CONTACT_CREATE_INPUT_SCHEMA).optional(),
  timeOfDay: Joi.string()
    .valid(...Object.values(TimeOfDay))
    .required(),
  notes: Joi.string().trim().optional(),
  deliveryFee: Joi.number().min(0).required(),
  discounts: Joi.array().items(ORDER_DISCOUNT_CREATE_INPUT_SCHEMA).optional(),
});

export const ORDER_EQUIPMENT_UPDATE_INPUT_SCHEMA = Joi.object({
  equipmentId: Joi.string().trim().required(),
  quantity: Joi.number().integer().min(1).required(),
  pricePerUnit: Joi.number().min(0).required(),
  referenceCode: Joi.string().trim().optional().allow(""),
});

export const ORDER_ITEMS_UPDATE_INPUT_SCHEMA = Joi.object({
  productId: Joi.string().trim().required(),
  quantity: Joi.number().integer().min(1).required(),
  pricePerUnit: Joi.number().min(0).required(),
  category: Joi.string()
    .valid(...Object.values(ProductCategory))
    .required(),
});

export const ORDER_PAYMENT_UPDATE_INPUT_SCHEMA = Joi.object({
  paymentMethod: Joi.string().required(),
  amount: Joi.number().required(),
  date: Joi.date().required(),
  // optional
  referenceCode: Joi.string().optional().allow(""),
  notes: Joi.string().optional().allow(""),
});

export const orderUpdateValidationSchema = Joi.object({
  branch: Joi.string()
    .valid(...Object.values(StoreBranch))
    .trim()
    .optional(),
  cpf: Joi.string().optional(),
  cnpj: Joi.string().optional(),
  deliveryAddress: addressValidationSchema.optional(),
  source: Joi.string().optional(),
  nf: Joi.string().optional(),
  siteOrderNumber: Joi.string().optional(),
  tags: Joi.array()
    .items(...Object.values(OrderTags))
    .unique()
    .optional(),
  type: Joi.string(),
  items: Joi.array().items(ORDER_ITEMS_UPDATE_INPUT_SCHEMA).optional(),
  orderEquipment: Joi.array()
    .items(ORDER_EQUIPMENT_UPDATE_INPUT_SCHEMA)
    .optional(),
  payments: Joi.array().items(ORDER_PAYMENT_UPDATE_INPUT_SCHEMA).optional(),
  scheduledDate: Joi.date().optional(),
  returnDate: Joi.date().optional(),
  contacts: Joi.array().items(ORDER_CONTACT_UPDATE_INPUT_SCHEMA).optional(),
  timeOfDay: Joi.string().optional(),
  notes: Joi.string().optional(),
  status: Joi.string().optional(),
  deliveryFee: Joi.number().optional(),
  totalAmount: Joi.number().optional(),

  metadata: Joi.object({
    deliveredAt: Joi.date().optional(),
    canceledAt: Joi.date().optional(),
    confirmedAt: Joi.date().optional(),
    equipmentCollectedAt: Joi.date().optional(),
    finalCheckedAt: Joi.date().optional(),
  }).optional(),
  discounts: Joi.array().items(ORDER_DISCOUNT_UPDATE_INPUT_SCHEMA).optional(),
});

export const orderUpdatePaymentsValidationSchema = Joi.object({
  payments: Joi.array().items(ORDER_PAYMENT_UPDATE_INPUT_SCHEMA).required(),
});
