/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerContactCreateInput
 */
export interface CustomerContactCreateInput {
    /**
     * 
     * @type {string}
     * @memberof CustomerContactCreateInput
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactCreateInput
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactCreateInput
     */
    phone: string;
}

/**
 * Check if a given object implements the CustomerContactCreateInput interface.
 */
export function instanceOfCustomerContactCreateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "phone" in value;

    return isInstance;
}

export function CustomerContactCreateInputFromJSON(json: any): CustomerContactCreateInput {
    return CustomerContactCreateInputFromJSONTyped(json, false);
}

export function CustomerContactCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerContactCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phone': json['phone'],
    };
}

export function CustomerContactCreateInputToJSON(value?: CustomerContactCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phone': value.phone,
    };
}

