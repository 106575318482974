import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Drawer,
  Grid,
} from "@mui/material";

import { usePhrases } from "../../context/PhrasesContext";
import { DrawerHeader } from "./Sidebar";

export interface CustomerFilterDrawerCols {
  contacts: boolean;
  address: boolean;
  address2: boolean;
  dob: boolean;
  email: boolean;
  email2: boolean;
  media: boolean;
  phone: boolean;
  phone2: boolean;
}

export const CustomerFilterDrawer: React.FC<{
  cols: CustomerFilterDrawerCols;
  setCols: React.Dispatch<React.SetStateAction<CustomerFilterDrawerCols>>;
  defaults: CustomerFilterDrawerCols;
}> = ({ cols, setCols, defaults }) => {
  // Cols settings
  const [open, setOpen] = useState(false);
  const { getPhrase } = usePhrases();

  const handleColsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.name, event.target.checked);
    const newState = { ...cols, [event.target.name]: event.target.checked };
    setCols(newState);
    // Store the new state in local storage under customerListColumns
    localStorage.setItem("customerListColumns", JSON.stringify(newState));
  };
  // Load the state from local storage when the component mounts
  useEffect(() => {
    const storedCols = localStorage.getItem("customerListColumns");
    if (storedCols) {
      setCols(JSON.parse(storedCols));
    }
  }, [setCols]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  const handleResetColumns = () => {
    setCols(defaults);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Drawer anchor="left" open={open} onClose={() => setOpen(!open)}>
        <DrawerHeader />
        <Box sx={{ width: 450, padding: 2 }}>
          <Typography mt={2} mb={2} variant="h5" textAlign={"left"}>
            {getPhrase("FILTERS")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label={"Test"} fullWidth size="small" />
            </Grid>
          </Grid>
          <Typography mt={3} mb={2} variant="h5" textAlign={"left"}>
            {getPhrase("TABLE_SETTINGS")}
          </Typography>
          <Typography variant="body1" textAlign={"left"}>
            {getPhrase("TABLE_SETTINGS_EXPLANATION")}
          </Typography>
          <Grid container mt={2} spacing={0}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="contacts"
                    size="medium"
                    checked={cols.contacts}
                    onChange={handleColsChange}
                  />
                }
                label={getPhrase("CUSTOMER_CONTACTS")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="address"
                    size="medium"
                    checked={cols.address}
                    onChange={handleColsChange}
                  />
                }
                label={getPhrase("ADDRESS")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="address2"
                    size="medium"
                    checked={cols.address2}
                    onChange={handleColsChange}
                  />
                }
                label={getPhrase("SECONDARY_ADDRESS")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="phone"
                    size="medium"
                    checked={cols.phone}
                    onChange={handleColsChange}
                  />
                }
                label={getPhrase("PHONE")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="phone2"
                    size="medium"
                    checked={cols.phone2}
                    onChange={handleColsChange}
                  />
                }
                label={getPhrase("SECONDARY_PHONE")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="dob"
                    size="medium"
                    checked={cols.dob}
                    onChange={handleColsChange}
                  />
                }
                label={getPhrase("DOB")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="email"
                    size="medium"
                    checked={cols.email}
                    onChange={handleColsChange}
                  />
                }
                label={getPhrase("EMAIL")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="email2"
                    size="medium"
                    checked={cols.email2}
                    onChange={handleColsChange}
                  />
                }
                label={getPhrase("SECONDARY_EMAIL")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="media"
                    size="medium"
                    checked={cols.media}
                    onChange={handleColsChange}
                  />
                }
                label={getPhrase("CUSTOMER_DOCUMENTS")}
              />
            </Grid>
          </Grid>
        </Box>
        <Box display={"flex"} mt={5} justifyContent={"space-around"}>
          <Box width={"40%"}>
            <Button
              fullWidth
              variant="outlined"
              onClick={toggleDrawer(false)}
              sx={{ whiteSpace: "nowrap" }}
            >
              {getPhrase("CLOSE")}
            </Button>
          </Box>
          <Box width={"40%"}>
            <Button
              fullWidth
              color="error"
              variant="outlined"
              onClick={handleResetColumns}
              sx={{ whiteSpace: "nowrap" }}
            >
              {getPhrase("RESET_COLUMNS")}
            </Button>
          </Box>
        </Box>
      </Drawer>

      <Button
        size="small"
        onClick={() => setOpen(true)}
        color="primary"
        variant="contained"
        sx={{ whiteSpace: "nowrap", mx: 2 }}
      >
        {getPhrase("CUSTOMER_FILTERS_AND_TABLE_SETTINGS")}
      </Button>
    </Box>
  );
};
