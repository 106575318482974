/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderMetadataOutputUndefined
 */
export interface OrderMetadataOutputUndefined {
    /**
     * 
     * @type {string}
     * @memberof OrderMetadataOutputUndefined
     */
    transitionedBy: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderMetadataOutputUndefined
     */
    transitionedAt: Date;
}

/**
 * Check if a given object implements the OrderMetadataOutputUndefined interface.
 */
export function instanceOfOrderMetadataOutputUndefined(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transitionedBy" in value;
    isInstance = isInstance && "transitionedAt" in value;

    return isInstance;
}

export function OrderMetadataOutputUndefinedFromJSON(json: any): OrderMetadataOutputUndefined {
    return OrderMetadataOutputUndefinedFromJSONTyped(json, false);
}

export function OrderMetadataOutputUndefinedFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderMetadataOutputUndefined {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transitionedBy': json['transitionedBy'],
        'transitionedAt': (new Date(json['transitionedAt'])),
    };
}

export function OrderMetadataOutputUndefinedToJSON(value?: OrderMetadataOutputUndefined | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transitionedBy': value.transitionedBy,
        'transitionedAt': (value.transitionedAt.toISOString()),
    };
}

