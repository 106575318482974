import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  Button,
  TablePagination,
  TableFooter,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { EquipmentOutput } from "../../../generated-api";
import { usePhrases } from "../../../context/PhrasesContext";
import { equipmentService } from "../../../services/EquipmentApi";
import { useSnackbar } from "../../../context/SnackbarContext";

export const EquipmentListPage: React.FC = () => {
  const [equipments, setEquipments] = useState<EquipmentOutput[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [loading, setLoading] = useState(true);

  const { getPhrase } = usePhrases();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  // dropdown menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedEquipmentId, setSelectedEquipmentId] = useState<string | null>(
    null
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await equipmentService.getEquipments({
        page,
        perPage,
      });
      setEquipments(response.data);
      setTotalCount(response.totalCount);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch equipments", error);
      showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
    }
  }, [page, perPage, showMessage, getPhrase]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClick = (
    customerId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedEquipmentId(customerId); // Set the selected customer ID
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditProduct = () => {
    setAnchorEl(null);
    if (selectedEquipmentId) {
      navigate(`/equipments/${selectedEquipmentId}/edit`);
    }
  };

  const printCurrencyValue = (value: number) => {
    return `${getPhrase("CURRENCY.SYMBOL")} ${value.toFixed(2)}`;
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2, p: 2 }}
      >
        <Typography variant="h5">{getPhrase("EQUIPMENT_LIST")}</Typography>
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => navigate("/equipments/create")}
        >
          {getPhrase("CREATE_EQUIPMENT")}
        </Button>
      </Box>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table size="small" stickyHeader aria-label="equipment table">
            <TableHead>
              <TableRow>
                <TableCell>{getPhrase("EQUIPMENT_NAME")}</TableCell>
                <TableCell align="right">
                  {getPhrase("EQUIPMENT_PRICE_PER_UNIT")}
                </TableCell>
                <TableCell>{getPhrase("EQUIPMENT_CATEGORY")}</TableCell>
                <TableCell align="right">
                  {getPhrase("EQUIPMENT_REFERENCE_CODE")}
                </TableCell>
                <TableCell>{getPhrase("EQUIPMENT_DESCRIPTION")}</TableCell>
                <TableCell>{getPhrase("STATUS")}</TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {getPhrase("ACTIONS")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {equipments.map((equipment) => (
                <TableRow key={equipment.id}>
                  <TableCell component="th" scope="row">
                    {equipment.name}
                  </TableCell>
                  <TableCell align="right">
                    {printCurrencyValue(equipment.pricePerUnit ?? 0)}
                  </TableCell>
                  <TableCell>
                    {getPhrase(`EQUIPMENT_CATEGORY.${equipment.category}`)}
                  </TableCell>
                  <TableCell align="right">{equipment.referenceCode}</TableCell>
                  <TableCell>{equipment.description ?? "-"}</TableCell>
                  <TableCell>
                    {equipment.status === "ACTIVE"
                      ? getPhrase("ACTIVE")
                      : getPhrase("INACTIVE")}
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} justifyContent={"end"}>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleClick(equipment.id, event)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleEditProduct}>
                          {getPhrase("EDIT_EQUIPMENT")}
                        </MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="body2">
                    {getPhrase("TOTAL_EQUIPMENT")}: {totalCount}
                  </Typography>
                </TableCell>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={totalCount}
                  rowsPerPage={perPage}
                  page={page - 1}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) =>
                    setPerPage(parseInt(event.target.value, 10))
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
