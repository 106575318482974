/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EquipmentStatus = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type EquipmentStatus = typeof EquipmentStatus[keyof typeof EquipmentStatus];


export function EquipmentStatusFromJSON(json: any): EquipmentStatus {
    return EquipmentStatusFromJSONTyped(json, false);
}

export function EquipmentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentStatus {
    return json as EquipmentStatus;
}

export function EquipmentStatusToJSON(value?: EquipmentStatus | null): any {
    return value as any;
}

