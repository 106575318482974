/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DiscountCreateInput,
  DiscountOutput,
  DiscountStatus,
  DiscountUpdateInput,
  PagedOutputDiscountOutput,
} from '../models/index';
import {
    DiscountCreateInputFromJSON,
    DiscountCreateInputToJSON,
    DiscountOutputFromJSON,
    DiscountOutputToJSON,
    DiscountStatusFromJSON,
    DiscountStatusToJSON,
    DiscountUpdateInputFromJSON,
    DiscountUpdateInputToJSON,
    PagedOutputDiscountOutputFromJSON,
    PagedOutputDiscountOutputToJSON,
} from '../models/index';

export interface ArchiveDiscountRequest {
    discountId: string;
}

export interface CreateDiscountRequest {
    discountCreateInput: DiscountCreateInput;
}

export interface GetDiscountRequest {
    discountId: string;
}

export interface GetDiscountsRequest {
    page?: number;
    perPage?: number;
    status?: DiscountStatus;
}

export interface UpdateDiscountRequest {
    discountId: string;
    discountUpdateInput: DiscountUpdateInput;
}

/**
 * 
 */
export class DiscountApi extends runtime.BaseAPI {

    /**
     * Delete (archive) discount
     */
    async archiveDiscountRaw(requestParameters: ArchiveDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling archiveDiscount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/discounts/{discountId}`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters.discountId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete (archive) discount
     */
    async archiveDiscount(requestParameters: ArchiveDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveDiscountRaw(requestParameters, initOverrides);
    }

    /**
     * Create a discount
     */
    async createDiscountRaw(requestParameters: CreateDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountOutput>> {
        if (requestParameters.discountCreateInput === null || requestParameters.discountCreateInput === undefined) {
            throw new runtime.RequiredError('discountCreateInput','Required parameter requestParameters.discountCreateInput was null or undefined when calling createDiscount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/discounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiscountCreateInputToJSON(requestParameters.discountCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountOutputFromJSON(jsonValue));
    }

    /**
     * Create a discount
     */
    async createDiscount(requestParameters: CreateDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountOutput> {
        const response = await this.createDiscountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get discount by id
     */
    async getDiscountRaw(requestParameters: GetDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountOutput>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling getDiscount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/discounts/{discountId}`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters.discountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountOutputFromJSON(jsonValue));
    }

    /**
     * Get discount by id
     */
    async getDiscount(requestParameters: GetDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountOutput> {
        const response = await this.getDiscountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get multiple discounts
     */
    async getDiscountsRaw(requestParameters: GetDiscountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedOutputDiscountOutput>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/discounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedOutputDiscountOutputFromJSON(jsonValue));
    }

    /**
     * Get multiple discounts
     */
    async getDiscounts(requestParameters: GetDiscountsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedOutputDiscountOutput> {
        const response = await this.getDiscountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update discount
     */
    async updateDiscountRaw(requestParameters: UpdateDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountOutput>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling updateDiscount.');
        }

        if (requestParameters.discountUpdateInput === null || requestParameters.discountUpdateInput === undefined) {
            throw new runtime.RequiredError('discountUpdateInput','Required parameter requestParameters.discountUpdateInput was null or undefined when calling updateDiscount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/discounts/{discountId}`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters.discountId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DiscountUpdateInputToJSON(requestParameters.discountUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountOutputFromJSON(jsonValue));
    }

    /**
     * Update discount
     */
    async updateDiscount(requestParameters: UpdateDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountOutput> {
        const response = await this.updateDiscountRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
