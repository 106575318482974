import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  Button,
  TablePagination,
  TableFooter,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { DiscountOutput, DiscountType } from "../../../generated-api";
import { usePhrases } from "../../../context/PhrasesContext";
import { useSnackbar } from "../../../context/SnackbarContext";
import { discountService } from "../../../services/DiscountApi";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";

export const DiscountListPage: React.FC = () => {
  const [discounts, setDiscounts] = useState<DiscountOutput[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [loading, setLoading] = useState(true);

  const { getPhrase } = usePhrases();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  // dropdown menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedDiscountId, setSelectedDiscountId] = useState<string | null>(
    null
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await discountService.getDiscounts({
        page,
        perPage,
      });
      setDiscounts(response.data);
      setTotalCount(response.totalCount);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch discounts", error);
      showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
    }
  }, [page, perPage, showMessage, getPhrase]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClick = (
    discountId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedDiscountId(discountId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditDiscount = () => {
    setAnchorEl(null);
    if (selectedDiscountId) {
      navigate(`/discounts/${selectedDiscountId}/edit`);
    }
  };

  const handleArchiveDiscount = () => {
    setAnchorEl(null);
    setIsDeleteDialogOpen(true);
  };

  const performArchive = async () => {
    if (!selectedDiscountId) {
      return;
    }

    try {
      await discountService.archiveDiscount({ discountId: selectedDiscountId });
      showMessage(getPhrase("DISCOUNT_ARCHIVED_SUCCESSFULLY"), "success");
      await fetchData();
    } catch (error) {
      console.error("Failed to archive discount", error);
      showMessage(getPhrase("ERROR.GENERIC.ARCHIVE_DATA"), "error");
    }
  };

  const handleCloseConfirmationDialog = () => {
    setIsDeleteDialogOpen(false);
    setAnchorEl(null);
    setSelectedDiscountId(null);
  };

  const printCurrencyValue = (value: number) => {
    return `${getPhrase("CURRENCY.SYMBOL")} ${value.toFixed(2)}`;
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2, p: 2 }}
      >
        <Typography variant="h5">{getPhrase("DISCOUNT_LIST")}</Typography>
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => navigate("/discounts/create")}
        >
          {getPhrase("CREATE_DISCOUNT")}
        </Button>
      </Box>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table size="small" stickyHeader aria-label="discount table">
            <TableHead>
              <TableRow>
                <TableCell width={"250px"}>
                  {getPhrase("DISCOUNT_NAME")}
                </TableCell>
                <TableCell width={"500px"}>
                  {getPhrase("DISCOUNT_DESCRIPTION")}
                </TableCell>
                <TableCell width={"250px"}>
                  {getPhrase("DISCOUNT_TYPE")}
                </TableCell>
                <TableCell width={"150px"}>
                  {getPhrase("DISCOUNT_VALUE")}
                </TableCell>
                <TableCell width={"150px"}>
                  {getPhrase("PRODUCT_CATEGORY")}
                </TableCell>
                <TableCell width={"50px"}>
                  {getPhrase("DISCOUNT_STATUS")}
                </TableCell>
                <TableCell width={"50px"} sx={{ textAlign: "right" }}>
                  {getPhrase("ACTIONS")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {discounts.map((discount) => (
                <TableRow key={discount.id}>
                  <TableCell>{discount.name}</TableCell>
                  <TableCell>
                    {discount.description ?? getPhrase("NOT_AVAIABLE")}
                  </TableCell>
                  <TableCell>
                    {getPhrase(`DISCOUNT_TYPE.${discount.type}`)}
                  </TableCell>
                  <TableCell>
                    {discount.type === DiscountType.Amount
                      ? printCurrencyValue(discount.value ?? 0)
                      : `${discount.value}%`}
                  </TableCell>
                  <TableCell>
                    {getPhrase(`PRODUCT_CATEGORY.${discount.productCategory}`)}
                  </TableCell>
                  <TableCell>
                    {discount.status === "ACTIVE"
                      ? getPhrase("ACTIVE")
                      : getPhrase("INACTIVE")}
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} justifyContent={"end"}>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleClick(discount.id, event)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleEditDiscount}>
                          {getPhrase("EDIT_DISCOUNT")}
                        </MenuItem>
                        <MenuItem onClick={handleArchiveDiscount}>
                          {getPhrase("ARCHIVE_DISCOUNT")}
                        </MenuItem>
                        <ConfirmationDialog
                          title={`${getPhrase("ARCHIVE_DISCOUNT")} - ${
                            discounts.find((d) => d.id === selectedDiscountId)
                              ?.name
                          }`}
                          message={getPhrase(
                            "ARE_YOU_SURE_YOU_WANT_TO_ARCHIVE_THIS_DISCOUNT"
                          )}
                          onClose={handleCloseConfirmationDialog}
                          onConfirm={performArchive}
                          open={isDeleteDialogOpen}
                        />
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="body2">
                    {getPhrase("TOTAL_DISCOUNTS")}: {totalCount}
                  </Typography>
                </TableCell>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={totalCount}
                  rowsPerPage={perPage}
                  page={page - 1}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) =>
                    setPerPage(parseInt(event.target.value, 10))
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
