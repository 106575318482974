/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Cities = {
    Santos: 'Santos',
    Guaruj: 'Guarujá',
    SoSebastio: 'São Sebastião',
    Bertioga: 'Bertioga',
    Cubato: 'Cubatão',
    SoVicente: 'São Vicente',
    PraiaGrande: 'Praia Grande',
    Mongagu: 'Mongaguá',
    Itanham: 'Itanhaém',
    Perube: 'Peruíbe',
    SeteBarras: 'Sete Barras',
    RivieraDeSoLoureno: 'Riviera de São Lourenço',
    Juquehy: 'Juquehy',
    BarraDoUna: 'Barra do Una'
} as const;
export type Cities = typeof Cities[keyof typeof Cities];


export function CitiesFromJSON(json: any): Cities {
    return CitiesFromJSONTyped(json, false);
}

export function CitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cities {
    return json as Cities;
}

export function CitiesToJSON(value?: Cities | null): any {
    return value as any;
}

