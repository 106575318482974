/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipmentCategory } from './EquipmentCategory';
import {
    EquipmentCategoryFromJSON,
    EquipmentCategoryFromJSONTyped,
    EquipmentCategoryToJSON,
} from './EquipmentCategory';

/**
 * 
 * @export
 * @interface EquipmentUpdateInput
 */
export interface EquipmentUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof EquipmentUpdateInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentUpdateInput
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof EquipmentUpdateInput
     */
    pricePerUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentUpdateInput
     */
    referenceCode?: string;
    /**
     * 
     * @type {EquipmentCategory}
     * @memberof EquipmentUpdateInput
     */
    category?: EquipmentCategory;
}

/**
 * Check if a given object implements the EquipmentUpdateInput interface.
 */
export function instanceOfEquipmentUpdateInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EquipmentUpdateInputFromJSON(json: any): EquipmentUpdateInput {
    return EquipmentUpdateInputFromJSONTyped(json, false);
}

export function EquipmentUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'pricePerUnit': !exists(json, 'pricePerUnit') ? undefined : json['pricePerUnit'],
        'referenceCode': !exists(json, 'referenceCode') ? undefined : json['referenceCode'],
        'category': !exists(json, 'category') ? undefined : EquipmentCategoryFromJSON(json['category']),
    };
}

export function EquipmentUpdateInputToJSON(value?: EquipmentUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'pricePerUnit': value.pricePerUnit,
        'referenceCode': value.referenceCode,
        'category': EquipmentCategoryToJSON(value.category),
    };
}

