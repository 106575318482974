/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Cities } from './Cities';
import {
    CitiesFromJSON,
    CitiesFromJSONTyped,
    CitiesToJSON,
} from './Cities';

/**
 * 
 * @export
 * @interface AddressOutput
 */
export interface AddressOutput {
    /**
     * 
     * @type {string}
     * @memberof AddressOutput
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOutput
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOutput
     */
    neighborhood: string;
    /**
     * 
     * @type {Cities}
     * @memberof AddressOutput
     */
    city: Cities;
    /**
     * 
     * @type {string}
     * @memberof AddressOutput
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOutput
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressOutput
     */
    additionalInfo?: string;
}

/**
 * Check if a given object implements the AddressOutput interface.
 */
export function instanceOfAddressOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "neighborhood" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "postalCode" in value;

    return isInstance;
}

export function AddressOutputFromJSON(json: any): AddressOutput {
    return AddressOutputFromJSONTyped(json, false);
}

export function AddressOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'street': json['street'],
        'neighborhood': json['neighborhood'],
        'city': CitiesFromJSON(json['city']),
        'state': json['state'],
        'postalCode': json['postalCode'],
        'additionalInfo': !exists(json, 'additionalInfo') ? undefined : json['additionalInfo'],
    };
}

export function AddressOutputToJSON(value?: AddressOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'street': value.street,
        'neighborhood': value.neighborhood,
        'city': CitiesToJSON(value.city),
        'state': value.state,
        'postalCode': value.postalCode,
        'additionalInfo': value.additionalInfo,
    };
}

