/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipmentCategory } from './EquipmentCategory';
import {
    EquipmentCategoryFromJSON,
    EquipmentCategoryFromJSONTyped,
    EquipmentCategoryToJSON,
} from './EquipmentCategory';

/**
 * 
 * @export
 * @interface EquipmentCreateInput
 */
export interface EquipmentCreateInput {
    /**
     * 
     * @type {string}
     * @memberof EquipmentCreateInput
     */
    name: string;
    /**
     * 
     * @type {EquipmentCategory}
     * @memberof EquipmentCreateInput
     */
    category: EquipmentCategory;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCreateInput
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof EquipmentCreateInput
     */
    pricePerUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentCreateInput
     */
    referenceCode?: string;
}

/**
 * Check if a given object implements the EquipmentCreateInput interface.
 */
export function instanceOfEquipmentCreateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "category" in value;

    return isInstance;
}

export function EquipmentCreateInputFromJSON(json: any): EquipmentCreateInput {
    return EquipmentCreateInputFromJSONTyped(json, false);
}

export function EquipmentCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'category': EquipmentCategoryFromJSON(json['category']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'pricePerUnit': !exists(json, 'pricePerUnit') ? undefined : json['pricePerUnit'],
        'referenceCode': !exists(json, 'referenceCode') ? undefined : json['referenceCode'],
    };
}

export function EquipmentCreateInputToJSON(value?: EquipmentCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'category': EquipmentCategoryToJSON(value.category),
        'description': value.description,
        'pricePerUnit': value.pricePerUnit,
        'referenceCode': value.referenceCode,
    };
}

