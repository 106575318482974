import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  IconButton,
  TableFooter,
  TablePagination,
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  TableSortLabel,
} from "@mui/material";
import { Chip } from "@mui/joy";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { orderService } from "../../../services/OrderApi";
import {
  Cities,
  OrderOutput,
  OrderStatus,
  OrderTags,
  OrderType,
} from "../../../generated-api";
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from "../../../constants/api";
import { usePhrases } from "../../../context/PhrasesContext";
import { useSnackbar } from "../../../context/SnackbarContext";
import { AuthContext } from "../../../context/AuthContext";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { OrderFilterDrawer } from "../../layout/OrderFilterDrawer";
import { visuallyHidden } from "@mui/utils";
import { formatDateTime } from "../../../utils/formatDateTime";

export interface OrderListFilters {
  customerName: {
    active: boolean;
    value: string;
  };
  scheduledDate: {
    active: boolean;
    from: Dayjs;
    to: Dayjs;
  };
  returnDate: {
    active: boolean;
    from: Dayjs;
    to: Dayjs;
  };
  status: {
    active: boolean;
    value: OrderStatus[];
  };
  type: {
    active: boolean;
    value: OrderType[];
  };
  tags: {
    active: boolean;
    value: OrderTags[];
  };
  neighborhood: {
    active: boolean;
    value: string;
  };
  cities: {
    active: boolean;
    value: Cities[];
  };
  key: {
    active: boolean;
    value: string;
  };
}

export const OrderListPage: React.FC = () => {
  dayjs.extend(utc);

  const [orders, setOrders] = useState<OrderOutput[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [filters, setFilters] = useState<OrderListFilters>({
    scheduledDate: {
      active: false,
      from: dayjs().startOf("day"),
      to: dayjs().startOf("day"),
    },
    status: {
      active: false,
      value: [OrderStatus.Pending],
    },
    type: {
      active: false,
      value: [OrderType.Normal],
    },
    tags: {
      active: false,
      value: [],
    },
    customerName: {
      active: false,
      value: "",
    },
    neighborhood: {
      active: false,
      value: "",
    },
    cities: {
      active: false,
      value: [Cities.Santos],
    },
    key: {
      active: false,
      value: "",
    },
    returnDate: {
      active: false,
      from: dayjs().startOf("day"),
      to: dayjs().startOf("day"),
    },
  });
  // Store sorting state, for now we only allow sorting by createdAt. sortBy and sortDirection are used to sort the data
  const [sortBy, setSortBy] = useState<
    "createdAt" | "scheduledDate" | "returnDate"
  >("createdAt");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);

  const { authState } = useContext(AuthContext);
  const selectedBranch = authState.user?.selectedBranch;

  const navigate = useNavigate();
  const { getPhrase } = usePhrases();
  const { showMessage } = useSnackbar();

  // Fetch orders data from the API
  const fetchOrders = useCallback(async () => {
    try {
      const data = await orderService.getManyOrders({
        page,
        perPage,
        branch: selectedBranch,
        customerName: filters.customerName.active
          ? filters.customerName.value
          : undefined,
        scheduledDateGte: filters.scheduledDate.active
          ? filters.scheduledDate.from.toDate()
          : undefined,
        scheduledDateLte: filters.scheduledDate.active
          ? filters.scheduledDate.to.toDate()
          : undefined,
        returnDateGte: filters.returnDate.active
          ? filters.returnDate.from.toDate()
          : undefined,
        returnDateLte: filters.returnDate.active
          ? filters.returnDate.to.toDate()
          : undefined,
        status: filters.status.active ? filters.status.value : undefined,
        type: filters.type.active ? filters.type.value : undefined,
        tags: filters.tags.active ? filters.tags.value : undefined,
        deliveryAddressNeighborhood: filters.neighborhood.active
          ? filters.neighborhood.value
          : undefined,
        deliveryAddressCities: filters.cities.active
          ? filters.cities.value
          : undefined,
        key: filters.key.active ? filters.key.value : undefined,
        // String to sort by, format "createadAt" for asc or "-createdAt" for desc
        sort: `${sortDirection === "asc" ? "" : "-"}${sortBy}`,
      });
      setOrders(data.data);
      setTotalCount(data.totalCount);
      setPage(data.page);
      setPerPage(data.perPage);
      setLoadingOrders(false);
    } catch (error) {
      showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
      console.error("Error fetching data:", error);
    }
  }, [
    page,
    perPage,
    getPhrase,
    showMessage,
    selectedBranch,
    filters,
    sortBy,
    sortDirection,
  ]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClick = (
    orderId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedOrderId(orderId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewOrder = () => {
    setAnchorEl(null);
    if (selectedOrderId) {
      navigate(`/orders/${selectedOrderId}`);
    }
  };

  const handleGoToPrintPage = () => {
    navigate(`/orders/${selectedOrderId}/print`);
    handleClose();
  };

  const mapFilterLabels = (filter: keyof OrderListFilters) => {
    switch (filter) {
      case "customerName":
        return getPhrase("CUSTOMER_NAME");
      case "scheduledDate":
        return getPhrase("SCHEDULED_DATE");
      case "status":
        return getPhrase("ORDER_STATUS");
      case "type":
        return getPhrase("ORDER.LIST_PAGE.TYPE");
      case "tags":
        return getPhrase("ORDER_TAGS");
      case "neighborhood":
        return getPhrase("ADDRESS_NEIGHBORHOOD");
      case "cities":
        return getPhrase("ADDRESS_CITY");
      case "key":
        return getPhrase("ORDER_KEY");
      case "returnDate":
        return getPhrase("RETURN_DATE");
      default:
        return filter;
    }
  };

  // Render loading spinner while data is being fetched
  if (loadingOrders) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box display="flex" alignItems="center" mx={2} mt={2}>
        <Box mr={5}>
          <Typography variant="h5">
            {getPhrase("ORDER.LIST_PAGE.TITLE")}
          </Typography>
        </Box>
        <Box>
          <OrderFilterDrawer filters={filters} setFilters={setFilters} />

          {Object.keys(filters).map((filter) => {
            if (filters[filter as keyof OrderListFilters].active) {
              return (
                <Chip
                  key={filter}
                  color="primary"
                  variant="outlined"
                  size="md"
                  style={{ marginLeft: ".5rem" }}
                >
                  {mapFilterLabels(filter as keyof OrderListFilters)}
                </Chip>
              );
            }
            return null;
          })}
        </Box>
        <Box ml="auto">
          <Button
            size="small"
            disabled
            variant="contained"
            onClick={() => navigate("/orders/create")}
            startIcon={<AddCircleOutlineIcon />}
            sx={{ whiteSpace: "nowrap", px: 5 }} // Prevent text from wrapping
          >
            {getPhrase("ORDER.LIST_PAGE.CREATE_ORDER")}
          </Button>
        </Box>
      </Box>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table
            size="small"
            stickyHeader
            aria-label="sticky table"
            className="tableSmallFontSize13 superDenseTable"
          >
            <TableHead>
              <TableRow>
                <TableCell width={"100px"}>{getPhrase("ORDER_KEY")}</TableCell>
                <TableCell width={"150px"}>
                  {getPhrase("CUSTOMER_NAME")}
                </TableCell>
                <TableCell width={"150px"}>{getPhrase("PHONE")}</TableCell>

                {/* Scheduled Date */}
                <TableCell sortDirection={"desc"} width={"150px"}>
                  <TableSortLabel
                    active={sortBy === "scheduledDate"}
                    direction={sortDirection}
                    onClick={() => {
                      if (sortBy !== "scheduledDate") {
                        setSortBy("scheduledDate");
                        setSortDirection("asc");
                      } else {
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }
                    }}
                  >
                    {getPhrase("SCHEDULED_DATE")}
                    <Box component="span" sx={visuallyHidden}>
                      {sortDirection === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  </TableSortLabel>
                </TableCell>

                {/* Return Date */}
                <TableCell sortDirection={"desc"} width={"150px"}>
                  <TableSortLabel
                    active={sortBy === "returnDate"}
                    direction={sortDirection}
                    onClick={() => {
                      if (sortBy !== "returnDate") {
                        setSortBy("returnDate");
                        setSortDirection("asc");
                      } else {
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }
                    }}
                  >
                    {getPhrase("RETURN_DATE")}
                    <Box component="span" sx={visuallyHidden}>
                      {sortDirection === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  </TableSortLabel>
                </TableCell>

                <TableCell width={"60px"}>{getPhrase("TIME_OF_DAY")}</TableCell>
                <TableCell width={"90px"}>
                  {getPhrase("ADDRESS_NEIGHBORHOOD")}
                </TableCell>
                <TableCell width={"100px"}>
                  {getPhrase("ADDRESS_CITY")}
                </TableCell>
                <TableCell width={"100px"}>
                  {getPhrase("TOTAL_AMOUNT")}
                </TableCell>
                <TableCell width={"60px"}>
                  {getPhrase("ORDER.LIST_PAGE.TYPE")}
                </TableCell>
                <TableCell width={"70px"}>{getPhrase("STATUS")}</TableCell>
                <TableCell sortDirection={"desc"} width={"150px"}>
                  <TableSortLabel
                    active={sortBy === "createdAt"}
                    direction={sortDirection}
                    onClick={() => {
                      if (sortBy !== "createdAt") {
                        setSortBy("createdAt");
                        setSortDirection("asc");
                      } else {
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }
                    }}
                  >
                    {getPhrase("CREATED_AT")}
                    <Box component="span" sx={visuallyHidden}>
                      {sortDirection === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  </TableSortLabel>
                  {/* {getPhrase("CREATED_AT")} */}
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {getPhrase("ACTIONS")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => {
                return (
                  <TableRow style={{ height: "33px" }} hover key={order.id}>
                    <TableCell>{order.key}</TableCell>
                    <TableCell>
                      {order.customer
                        ? `${order.customer.firstName} ${order.customer.lastName}`
                        : order.customerId}
                    </TableCell>
                    <TableCell>{order.customer?.phone}</TableCell>
                    <TableCell>{formatDateTime(order.scheduledDate)}</TableCell>
                    <TableCell>
                      {order.returnDate
                        ? formatDateTime(order.returnDate)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {getPhrase(`TIME_OF_DAY.${order.timeOfDay}`)}
                    </TableCell>
                    <TableCell>{order.deliveryAddress.neighborhood}</TableCell>
                    <TableCell>{order.deliveryAddress.city}</TableCell>
                    <TableCell>{`${getPhrase(
                      "CURRENCY.SYMBOL"
                    )} ${order.totalAmount.toFixed(2)}`}</TableCell>
                    <TableCell>
                      {getPhrase(`ORDER_TYPE.${order.type}` as any)}
                    </TableCell>
                    <TableCell>
                      {getPhrase(`ORDER_STATUS.${order.status}` as any)}
                    </TableCell>
                    <TableCell
                      title={dayjs(order.createdAt, { utc: true }).format(
                        "dddd, DD MMMM YYYY"
                      )}
                    >
                      {formatDateTime(order.createdAt)}
                    </TableCell>
                    <TableCell>
                      <Box display={"flex"} justifyContent={"end"}>
                        <IconButton
                          style={{
                            padding: 0,
                          }}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleClick(order.id, event)}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={handleViewOrder}>
                            {getPhrase("ORDER.LIST_PAGE.VIEW")}
                          </MenuItem>
                          <MenuItem onClick={handleGoToPrintPage}>
                            {getPhrase("PRINT_ORDER")}
                          </MenuItem>
                        </Menu>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="body2">
                    {getPhrase("ORDER.LIST_PAGE.TOTAL_ORDERS")}: {totalCount}
                  </Typography>
                </TableCell>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={totalCount}
                  rowsPerPage={perPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
