import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  TextField,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  Autocomplete,
  Divider,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  OrderOutput,
  ProductOutput,
  EquipmentOutput,
  CustomerOutput,
  OrderUpdateInput,
  OrderSource,
  TimeOfDay,
  OrderTags,
  Cities,
  OrderContactUpdateInput,
  CustomerContactOutput,
  DiscountOutput,
  DiscountStatus,
  DiscountType,
  ProductCategory,
} from "../../../generated-api";
import {
  orderService,
  orderUpdateValidationSchema,
} from "../../../services/OrderApi";
import { useSnackbar } from "../../../context/SnackbarContext";
import { usePhrases } from "../../../context/PhrasesContext";
import { productService } from "../../../services/ProductApi";
import { equipmentService } from "../../../services/EquipmentApi";
import { Chip } from "@mui/joy";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { removeFalsy } from "../../../utils/removeFalsy";
import { joiResolver } from "@hookform/resolvers/joi";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { OrderCustomerDetails } from "../../layout/OrderCustomerDetails";
import { customerService } from "../../../services/CustomerApi";
import { getContactUniqueKey } from "../../../utils/getContactUniqueKey";
import {
  calculateDiscounted,
  discountService,
} from "../../../services/DiscountApi";

export const OrderEditPage: React.FC = () => {
  dayjs.extend(require("dayjs/plugin/utc"));
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<OrderOutput | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const { getPhrase } = usePhrases();

  // State for products
  const [products, setProducts] = useState<ProductOutput[]>([]);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(true);

  // State for equipments
  const [equipments, setEquipments] = useState<EquipmentOutput[]>([]);
  const [loadingEquipments, setLoadingEquipments] = useState<boolean>(true);

  // State for customer
  const [customer, setCustomer] = useState<CustomerOutput | null>(null);
  const [loadingCustomer, setLoadingCustomer] = useState<boolean>(true);

  // State for discounts
  const [discounts, setDiscounts] = useState<DiscountOutput[]>([]);
  const [loadingDiscounts, setLoadingDiscounts] = useState<boolean>(true);

  // Available contacts to add to the order
  const [contacts, setContacts] = useState<
    (CustomerContactOutput & { uniqueKey: string })[]
  >([]);

  // List of selected uniqueKeys
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [selectedContactsHelperText, setSelectedContactsHelperText] =
    useState<string>("");

  // Order has NF or does not have NF
  const [hasNF, setHasNF] = useState<boolean>(true);

  // State for selected document type, this is used when submitting the form to determine which document to use
  const [selectedDocType, setSelectedDocType] = useState<"CPF" | "CNPJ">("CPF");

  // Address to use from customer record, this is used to determine if the address is the primary, secondary or other
  const [addressToUse, setAddressToUse] = useState<
    "PRIMARY" | "SECONDARY" | "OTHER"
  >("PRIMARY");

  // Fetch products
  const fetchProducts = async () => {
    const response = await productService.getProducts({ perPage: 1000 });
    setProducts(response.data);
    setLoadingProducts(false);
  };

  // Fetch equipments
  const fetchEquipments = async () => {
    const response = await equipmentService.getEquipments({ perPage: 1000 });
    setEquipments(response.data);
    setLoadingEquipments(false);
  };

  // Fetch order data
  const fetchOrderData = useCallback(
    async (orderId: string) => {
      try {
        const data = await orderService.getOneOrder({
          orderId,
          joinCustomer: true,
          joinEquipment: false,
          joinProduct: false,
        });

        if (data.customer) {
          setCustomer(data.customer);
          setLoadingCustomer(false);
        }
        // fallback to fetch customer data if it's not included in the order data
        else {
          const customerData = await customerService.getCustomer({
            customerId: data.customerId,
          });
          setCustomer(customerData);
          setLoadingCustomer(false);
        }

        setOrder(data);
      } catch (error) {
        console.error("Error:", error);
        showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
      } finally {
        setLoading(false);
      }
    },
    [showMessage, getPhrase]
  );

  // Fetch discounts
  const fetchDiscounts = async () => {
    const response = await discountService.getDiscounts({
      perPage: 1000,
      status: DiscountStatus.Active,
    });
    setDiscounts(response.data);
    setLoadingDiscounts(false);
  };

  // Fetch customer data, products, and equipment data
  useEffect(() => {
    // fetchCustomer();
    fetchProducts();
    fetchEquipments();
    fetchDiscounts();
  }, [orderId]);

  useEffect(() => {
    if (!orderId) return;

    fetchOrderData(orderId);
  }, [orderId, fetchOrderData]);

  // Form setup
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    setError,
  } = useForm<OrderUpdateInput>({
    resolver: joiResolver(orderUpdateValidationSchema),
  });

  // UseFieldArray for dynamic order items and equipments
  const {
    fields: orderItemsFields,
    append: appendOrderItem,
    remove: removeOrderItem,
  } = useFieldArray({
    control,
    name: "items",
  });
  const {
    fields: orderEquipmentFields,
    append: appendOrderEquipment,
    remove: removeEquipmentItem,
  } = useFieldArray({
    control,
    name: "orderEquipment",
  });
  const {
    fields: discountsFields,
    append: appendDiscount,
    remove: removeDiscount,
  } = useFieldArray({
    control,
    name: "discounts",
  });

  // Watch order items and equipments
  const watchedOrderSource = watch("source");
  const watchedDeliveryFee = watch("deliveryFee");
  const watchedScheduledDate = watch("scheduledDate");
  const watchedItems = watch("items") || [];
  const watchedOrderEquipment = watch("orderEquipment") || [];
  const watchedDiscounts = watch("discounts") || [];

  // Address watchers
  const watchedDeliveryAddress = watch("deliveryAddress");
  const watchedDeliveryAddressCity = watch("deliveryAddress.city");
  const watchedDeliveryAddressNeighborhood = watch(
    "deliveryAddress.neighborhood"
  );
  const watchedDeliveryAddressNumber = watch("deliveryAddress.number");
  const watchedDeliveryAddressPostalCode = watch("deliveryAddress.postalCode");
  const watchedDeliveryAddressStreet = watch("deliveryAddress.street");
  const watchedDeliveryAddressState = watch("deliveryAddress.state");

  // Modify onSubmit for update logic
  const onSubmit: SubmitHandler<OrderUpdateInput> = async (data) => {
    if (!orderId) return;

    // Get contacts based on selectedContacts preserving the order of selectedContacts
    const orderContacts: OrderContactUpdateInput[] = [];
    selectedContacts.forEach((uniqueKey) => {
      const contact = contacts.find((c) => c.uniqueKey === uniqueKey);
      if (!contact) {
        console.error("Contact not found for uniqueKey:", uniqueKey);
        return null;
      }
      orderContacts.push({
        firstName: contact.firstName,
        lastName: contact.lastName,
        phone: contact.phone,
      });
    });

    if (orderContacts.length === 0) {
      showMessage(getPhrase("PLEASE_SELECT_AT_LEAST_ONE_CONTACT"), "error");
      setSelectedContactsHelperText(
        getPhrase("PLEASE_SELECT_AT_LEAST_ONE_CONTACT")
      );
      return;
    }

    if (hasNF && data.nf === "") {
      showMessage(getPhrase("PLEASE_ENTER_NF_NUMBER"), "error");
      setError("nf", {
        type: "required",
        message: getPhrase("PLEASE_ENTER_NF_NUMBER"),
      });
      return;
    }

    console.log("Submitting updated data:", data);
    try {
      // Logic to update the order
      const cleanedData: OrderUpdateInput = removeFalsy(data);
      console.log("Cleaned data:", cleanedData);
      await orderService.updateOrder({
        orderId,
        orderUpdateInput: {
          ...cleanedData,
          siteOrderNumber:
            watchedOrderSource === OrderSource.Website
              ? cleanedData.siteOrderNumber
              : undefined,
          cpf: selectedDocType === "CPF" ? cleanedData.cpf : undefined,
          cnpj: selectedDocType === "CNPJ" ? cleanedData.cnpj : undefined,
          contacts: orderContacts,
          orderEquipment: cleanedData.orderEquipment
            ? cleanedData.orderEquipment.map((equipment) =>
                removeFalsy({
                  equipmentId: equipment.equipmentId,
                  quantity: equipment.quantity,
                  pricePerUnit: equipment.pricePerUnit,
                  referenceCode: equipment.referenceCode,
                })
              )
            : [],
        },
      });
      showMessage(getPhrase("ORDER_UPDATED_SUCCESSFULLY"), "success");
      navigate(`/orders/${orderId}`);
    } catch (error) {
      showMessage(getPhrase("ERROR.GENERIC.UPDATE_DATA"), "error");
      console.error("Unexpected error when updating order:", error);
    }
  };

  const handleSwitchAddressToUse = (
    addressType: "PRIMARY" | "SECONDARY" | "OTHER"
  ) => {
    if (addressType === "PRIMARY") {
      if (customer?.address) {
        setAddressToUse("PRIMARY");
        setValue("deliveryAddress", customer.address);
      }
    } else if (addressType === "SECONDARY") {
      if (customer?.address2) {
        setAddressToUse("SECONDARY");
        setValue("deliveryAddress", customer.address2);
      }
    } else {
      setAddressToUse("OTHER");
      // clean the address
      setValue("deliveryAddress", {
        additionalInfo: "",
        city: "" as any,
        neighborhood: "",
        number: "",
        postalCode: "",
        state: "",
        street: "",
      });
    }
  };

  const handleContactCheckboxChange = (uniqueKey: string) => {
    setSelectedContactsHelperText("");
    setSelectedContacts((prevSelectedContacts) =>
      prevSelectedContacts.includes(uniqueKey)
        ? prevSelectedContacts.filter((key) => key !== uniqueKey)
        : [...prevSelectedContacts, uniqueKey]
    );
  };

  const handleCancel = () => {
    navigate(`/orders/${orderId}`);
  };

  // Totals calculation
  const itemsTotal = watchedItems.reduce((acc, item) => {
    return acc + item.quantity * item.pricePerUnit;
  }, 0);
  const equipmentsTotal = watchedOrderEquipment.reduce((acc, equipment) => {
    return acc + equipment.quantity * equipment.pricePerUnit;
  }, 0);
  const totalAmount =
    itemsTotal + equipmentsTotal + (Number(watchedDeliveryFee) || 0);
  const totalDiscounts = watchedDiscounts.reduce((acc, discount) => {
    return acc + calculateDiscounted(discount, watchedItems);
  }, 0);
  const balance = totalAmount - totalDiscounts;

  // Prefill form fields from customer data
  useEffect(() => {
    if (customer) {
      // Build contacts
      const customerPrimaryContact: CustomerContactOutput = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        phone: customer.phone,
      };
      const possibleContacts = [
        {
          ...customerPrimaryContact,
          uniqueKey: getContactUniqueKey(customerPrimaryContact),
        },
      ];
      if (customer.phone2) {
        const customerSecondaryContact = {
          firstName: customer.firstName,
          lastName: customer.lastName,
          phone: customer.phone2,
        };
        possibleContacts.push({
          ...customerSecondaryContact,
          uniqueKey: getContactUniqueKey(customerSecondaryContact),
        });
      }
      customer?.contacts.forEach((c) => {
        possibleContacts.push({ ...c, uniqueKey: getContactUniqueKey(c) });
      });
      setContacts(possibleContacts);

      setValue("cpf", customer.cpf || "");
      setValue("cnpj", customer.cnpj || "");
      // setValue("deliveryAddress", customer.address || {});
    }
  }, [customer, setValue]);

  // Adjust useForm call to use initialOrder as defaultValues once it's loaded
  useEffect(() => {
    if (order) {
      const initialOrder: OrderUpdateInput = {
        cnpj: order.cnpj,
        cpf: order.cpf,
        tags: order.tags,
        nf: order.nf,
        siteOrderNumber: order.siteOrderNumber,
        source: order.source,
        deliveryAddress: order.deliveryAddress,
        items: order.items.map((item) => ({
          productId: item.productId,
          quantity: item.quantity,
          pricePerUnit: item.pricePerUnit,
          category: item.category,
        })),
        orderEquipment: order.orderEquipment.map((equipment) => ({
          equipmentId: equipment.equipmentId,
          quantity: equipment.quantity,
          pricePerUnit: equipment.pricePerUnit,
          referenceCode: equipment.referenceCode ?? "",
        })),
        timeOfDay: order.timeOfDay,
        notes: order.notes,
        deliveryFee: order.deliveryFee,
        payments: order.payments,
        // Have to handle this in a special way because of the date format
        scheduledDate: order.scheduledDate
          .toISOString()
          .split("T")[0] as unknown as Date,
        returnDate: order.returnDate
          ?.toISOString()
          .split("T")[0] as unknown as Date,
        contacts: order.contacts,
        discounts: order.discounts.map((d) => ({
          discountId: d.discountId,
          productCategory: d.productCategory,
          value: d.value,
          type: d.type as DiscountType,
          name: d.name,
        })),

        // Ignore these fields on purpose
        // status: order.status,
        // branch: order.branch,
        // customerId: order.customerId,
        // metadata: order.metadata,
      };

      // TODO: handle the complex scenario where the contact changed and the order has a reference to an olrder contact
      // potentianlly find fuzzy match for the contact based on phone number and name
      // Merge the order contacts with the customer contacts, removing duplicates
      // TODO: Verify if this is the correct behavior
      setContacts((prevContacts) => {
        const newContacts = [...prevContacts];

        order.contacts.forEach((orderContact) => {
          const orderContactUniqueKey = getContactUniqueKey(orderContact);
          if (!newContacts.find((c) => c.uniqueKey === orderContactUniqueKey)) {
            newContacts.push({
              ...orderContact,
              uniqueKey: orderContactUniqueKey,
            });
          }
        });

        return newContacts;
      });
      // Set selectedContacts
      setSelectedContacts(order.contacts.map((c) => getContactUniqueKey(c)));

      // Set hasNF
      setHasNF(!!order.nf);

      // Determing address to use by comparing the delivery address to the primary and secondary addresses from the customer
      // ignore differences in additionalInfo
      const orderCustomer = order.customer;
      if (
        orderCustomer?.address &&
        order.deliveryAddress.city === orderCustomer.address.city &&
        order.deliveryAddress.state === orderCustomer.address.state &&
        order.deliveryAddress.neighborhood ===
          orderCustomer.address.neighborhood &&
        order.deliveryAddress.number === orderCustomer.address.number &&
        order.deliveryAddress.postalCode === orderCustomer.address.postalCode &&
        order.deliveryAddress.street === orderCustomer.address.street
      ) {
        setAddressToUse("PRIMARY");
      } else if (
        orderCustomer?.address2 &&
        order.deliveryAddress.city === orderCustomer.address2.city &&
        order.deliveryAddress.state === orderCustomer.address2.state &&
        order.deliveryAddress.neighborhood ===
          orderCustomer.address2.neighborhood &&
        order.deliveryAddress.number === orderCustomer.address2.number &&
        order.deliveryAddress.postalCode ===
          orderCustomer.address2.postalCode &&
        order.deliveryAddress.street === orderCustomer.address2.street
      ) {
        setAddressToUse("SECONDARY");
      } else {
        setAddressToUse("OTHER");
      }

      reset(initialOrder); // Use reset to initialize form with fetched order data
    }
  }, [order, reset]);

  // Any change to the delivery address should set the address to use to "OTHER" that makes it different from the primary and secondary addresses
  useEffect(() => {
    // only set other if address is different from primary and secondary
    // We ignore differences in additionalInfo
    if (!order) return;

    const orderCustoemr = order.customer;
    if (
      (watchedDeliveryAddressCity !== orderCustoemr?.address?.city &&
        watchedDeliveryAddressCity !== orderCustoemr?.address2?.city) ||
      (watchedDeliveryAddressState !== orderCustoemr?.address?.state &&
        watchedDeliveryAddressState !== orderCustoemr?.address2?.state) ||
      (watchedDeliveryAddressNeighborhood !==
        orderCustoemr?.address?.neighborhood &&
        watchedDeliveryAddressNeighborhood !==
          orderCustoemr?.address2?.neighborhood) ||
      (watchedDeliveryAddressNumber !== orderCustoemr?.address?.number &&
        watchedDeliveryAddressNumber !== orderCustoemr?.address2?.number) ||
      (watchedDeliveryAddressPostalCode !==
        orderCustoemr?.address?.postalCode &&
        watchedDeliveryAddressPostalCode !==
          orderCustoemr?.address2?.postalCode) ||
      (watchedDeliveryAddressStreet !== orderCustoemr?.address?.street &&
        watchedDeliveryAddressStreet !== orderCustoemr?.address2?.street)
    ) {
      setAddressToUse("OTHER");
    }
  }, [
    watchedDeliveryAddressCity,
    watchedDeliveryAddressState,
    watchedDeliveryAddressNeighborhood,
    watchedDeliveryAddressNumber,
    watchedDeliveryAddressPostalCode,
    watchedDeliveryAddressStreet,
    watchedDeliveryAddress,
    order,
  ]);

  // Conditional loading indicator for fetching initial order data
  if (
    loading ||
    loadingProducts ||
    loadingEquipments ||
    loadingCustomer ||
    loadingDiscounts
  ) {
    return <CircularProgress />;
  }

  if (!order) {
    return <Typography variant="h6">{getPhrase("ORDER_NOT_FOUND")}</Typography>;
  }

  if (!customer) {
    return (
      <Typography variant="h6">{getPhrase("CUSTOMER_NOT_FOUND")}</Typography>
    );
  }

  return (
    <Container>
      <Box my={5}>
        <Typography component="h1" variant="h4">
          {getPhrase("ORDER.EDIT_PAGE.TITLE")}
          <Chip size="lg" color="success" style={{ margin: "0 .5rem" }}>
            {order.branch}
          </Chip>
        </Typography>
      </Box>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* Customer information title */}
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {getPhrase("CUSTOMER_INFORMATION")}
                </Typography>
              </Grid>

              <OrderCustomerDetails
                customer={customer}
                disableContactTable={true}
              />
            </Grid>

            {/* DIVIDER */}
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Document Type */}
            <Grid item xs={12} mt={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {getPhrase("CPF_OR_CNPJ")}
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="document-type"
                  name="documentType"
                  value={selectedDocType}
                  onChange={(e) =>
                    setSelectedDocType(e.target.value as "CPF" | "CNPJ")
                  }
                >
                  <FormControlLabel
                    value="CPF"
                    control={<Radio />}
                    label={getPhrase("CPF")}
                  />
                  <FormControlLabel
                    value="CNPJ"
                    control={<Radio />}
                    label={getPhrase("CNPJ")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* CPF Field */}
            {selectedDocType === "CPF" && (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="cpf"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      variant="outlined"
                      fullWidth
                      label={getPhrase("CPF")}
                      error={!!errors.cpf}
                      helperText={errors.cpf?.message}
                    />
                  )}
                />
              </Grid>
            )}

            {/* CNPJ Field */}
            {selectedDocType === "CNPJ" && (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="cnpj"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      {...field}
                      variant="outlined"
                      fullWidth
                      label={getPhrase("CNPJ")}
                      error={!!errors.cnpj}
                      helperText={errors.cnpj?.message}
                    />
                  )}
                />
              </Grid>
            )}

            {/* Order Tags */}
            <Grid item xs={12} sm={6}>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="order-tags-label">
                      {getPhrase("ORDER_TAGS")}
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="order-tags-label"
                      id="order-tags-select"
                      multiple
                      value={field.value || []}
                      onChange={(event) => field.onChange(event.target.value)}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label={getPhrase("ORDER_TAGS")}
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value}>{value}</Chip>
                          ))}
                        </Box>
                      )}
                    >
                      {Object.values(OrderTags).map((tag) => (
                        <MenuItem key={tag} value={tag}>
                          {tag}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            {/* Order Source */}
            <Grid item xs={4}>
              <Controller
                name="source"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    select
                    label={getPhrase("ORDER_SOURCE")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.source}
                    helperText={errors.source?.message}
                  >
                    {Object.values(OrderSource).map((source) => (
                      <MenuItem key={source} value={source}>
                        {getPhrase(`ORDER_SOURCE.${source}` as any)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            {/* Site Order Number */}
            {watchedOrderSource === OrderSource.Website && (
              <Grid item xs={4}>
                <Controller
                  name="siteOrderNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      variant="outlined"
                      fullWidth
                      label={getPhrase("SITE_ORDER_NUMBER")}
                      error={!!errors.siteOrderNumber}
                      helperText={errors.siteOrderNumber?.message}
                    />
                  )}
                />
              </Grid>
            )}

            {/* Order NF ID */}
            <Grid item container xs={12} mt={4}>
              <Grid item xs={12}>
                <FormControlLabel
                  label={getPhrase("ORDER_HAS_NF")}
                  control={
                    <Checkbox
                      checked={hasNF}
                      onChange={(e) => setHasNF(e.target.checked)}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="nf"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      variant="outlined"
                      label={getPhrase("NF_NUMBER")}
                      disabled={!hasNF}
                      error={!!errors.nf}
                      helperText={errors.nf?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* Contacts table */}
            <Grid item container xs={12} mt={4}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {getPhrase("ORDER_CONTACTS")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table
                    className="tableSmallFontSize13"
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell width={"100px"}>
                          {getPhrase("SELECTED")}
                        </TableCell>
                        <TableCell width={"200px"}>
                          {getPhrase("CONTACT_PRIORITY_ORDER")}
                        </TableCell>
                        <TableCell width={"200px"}>
                          {getPhrase("FIRST_NAME")}
                        </TableCell>
                        <TableCell>{getPhrase("LAST_NAME")}</TableCell>
                        <TableCell>{getPhrase("PHONE")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contacts.map((contact) => (
                        <TableRow key={contact.uniqueKey}>
                          <TableCell>
                            <Checkbox
                              style={{ padding: 0 }}
                              checked={selectedContacts.includes(
                                contact.uniqueKey
                              )}
                              onChange={() =>
                                handleContactCheckboxChange(contact.uniqueKey)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            {selectedContacts.indexOf(contact.uniqueKey) !== -1
                              ? selectedContacts.indexOf(contact.uniqueKey) + 1
                              : "-"}
                          </TableCell>
                          <TableCell>{contact.firstName}</TableCell>
                          <TableCell>{contact.lastName}</TableCell>
                          <TableCell>{contact.phone}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {/* Error messages for contacts table */}
            {selectedContactsHelperText !== "" && (
              <Grid item xs={12}>
                <Typography variant="body2" color="error">
                  {selectedContactsHelperText}
                </Typography>
              </Grid>
            )}

            {/* Delivery Address Fields */}
            {/* Address Fields */}
            <Grid
              container
              item
              rowSpacing={2}
              columnSpacing={2}
              my={5}
              xs={12}
            >
              <Grid item xs={12}>
                <Typography variant="h6">
                  {getPhrase("DELIVERY_ADDRESS")}
                </Typography>

                {/* Address to use */}
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {getPhrase("ADDRESS_TO_USE")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="address-to-use"
                      name="addressToUse"
                      value={addressToUse}
                      onChange={(e) =>
                        handleSwitchAddressToUse(
                          e.target.value as "PRIMARY" | "SECONDARY" | "OTHER"
                        )
                      }
                    >
                      <FormControlLabel
                        value="PRIMARY"
                        control={<Radio />}
                        label={getPhrase("PRIMARY")}
                      />
                      <FormControlLabel
                        value="SECONDARY"
                        control={<Radio />}
                        label={getPhrase("SECONDARY")}
                        disabled={!customer.address2}
                      />
                      <FormControlLabel
                        value="OTHER"
                        control={<Radio />}
                        label={getPhrase("OTHER")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Address: Number */}
              <Grid item xs={3}>
                <Controller
                  name="deliveryAddress.number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      variant="outlined"
                      fullWidth
                      label={getPhrase("ADDRESS_NUMBER")}
                      error={!!errors.deliveryAddress?.number}
                      helperText={errors.deliveryAddress?.number?.message}
                    />
                  )}
                />
              </Grid>

              {/* Address: Street */}
              <Grid item xs={9}>
                <Controller
                  name="deliveryAddress.street"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      variant="outlined"
                      fullWidth
                      label={getPhrase("ADDRESS_STREET")}
                      error={!!errors.deliveryAddress?.street}
                      helperText={errors.deliveryAddress?.street?.message}
                    />
                  )}
                />
              </Grid>

              {/* Address: neighborhood */}
              <Grid item xs={6}>
                <Controller
                  name="deliveryAddress.neighborhood"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      variant="outlined"
                      fullWidth
                      label={getPhrase("ADDRESS_NEIGHBORHOOD")}
                      error={!!errors.deliveryAddress?.neighborhood}
                      helperText={errors.deliveryAddress?.neighborhood?.message}
                    />
                  )}
                />
              </Grid>

              {/* Address: City */}
              <Grid item xs={6}>
                <Controller
                  name="deliveryAddress.city"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      select
                      label={getPhrase("ADDRESS_CITY")}
                      variant="outlined"
                      fullWidth
                      error={!!errors.deliveryAddress?.city}
                      helperText={errors.deliveryAddress?.city?.message}
                    >
                      {/* Map OrderType enum to menu items */}
                      {Object.values(Cities).map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              {/* Address: State */}
              <Grid item xs={6}>
                <Controller
                  name="deliveryAddress.state"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      variant="outlined"
                      fullWidth
                      label={getPhrase("ADDRESS_STATE")}
                      error={!!errors.deliveryAddress?.state}
                      helperText={errors.deliveryAddress?.state?.message}
                    />
                  )}
                />
              </Grid>

              {/* Address: Postal Code */}
              <Grid item xs={6}>
                <Controller
                  name="deliveryAddress.postalCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      variant="outlined"
                      fullWidth
                      label={getPhrase("ADDRESS_POSTAL_CODE")}
                      error={!!errors.deliveryAddress?.postalCode}
                      helperText={errors.deliveryAddress?.postalCode?.message}
                    />
                  )}
                />
              </Grid>

              {/* Address: Additional Info */}
              <Grid item xs={12}>
                <Controller
                  name="deliveryAddress.additionalInfo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={2}
                      label={getPhrase("ADDITIONAL_INFO")}
                      error={!!errors.deliveryAddress?.additionalInfo}
                      helperText={
                        errors.deliveryAddress?.additionalInfo?.message
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* Customer information title */}
            <Grid item xs={12}>
              <Typography variant="h6">{getPhrase("DATE_AND_FEE")}</Typography>
            </Grid>

            {/* Scheduled Date */}
            <Grid item xs={12} sm={2}>
              <Controller
                name="scheduledDate"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      {...field}
                      label={getPhrase("SCHEDULED_DATE")}
                      value={dayjs(field.value)}
                      format="DD/MM/YYYY"
                      onChange={(date) => field.onChange(date?.toDate())}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                    />
                    {!!errors.scheduledDate && (
                      <Typography component="p" color={"red"}>
                        {errors.scheduledDate?.message}
                      </Typography>
                    )}
                  </LocalizationProvider>
                )}
              />
            </Grid>

            {/* Return Date */}
            <Grid item xs={12} sm={2}>
              <Controller
                name="returnDate"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      {...field}
                      label={getPhrase("RETURN_DATE")}
                      value={dayjs(field.value)}
                      format="DD/MM/YYYY"
                      onChange={(date) => field.onChange(date?.toDate())}
                      minDate={dayjs(watchedScheduledDate)}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                    />
                    {!!errors.returnDate && (
                      <Typography component="p" color={"red"}>
                        {errors.returnDate?.message}
                      </Typography>
                    )}
                  </LocalizationProvider>
                )}
              />
            </Grid>

            {/* Time of Day */}
            <Grid item xs={12} sm={5}>
              <Controller
                name="timeOfDay"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    select
                    label={getPhrase("TIME_OF_DAY")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.timeOfDay}
                    helperText={errors.timeOfDay?.message}
                  >
                    {/* Map TimeOfDay enum to menu items */}
                    {Object.values(TimeOfDay).map((time) => (
                      <MenuItem key={time} value={time}>
                        {getPhrase(`TIME_OF_DAY.${time}` as any)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            {/* Delivery Fee */}
            <Grid item xs={12} sm={3}>
              <Controller
                name="deliveryFee"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    variant="outlined"
                    fullWidth
                    label={getPhrase("DELIVERY_FEE")}
                    type="number"
                    error={!!errors.deliveryFee}
                    helperText={errors.deliveryFee?.message}
                  />
                )}
              />
            </Grid>

            {/* Notes */}
            <Grid item xs={12}>
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    label={getPhrase("NOTES")}
                    error={!!errors.notes}
                    helperText={errors.notes?.message}
                  />
                )}
              />
            </Grid>

            {/* Dynamic Order Items */}
            <Grid
              mt={5}
              container
              item
              rowSpacing={2}
              columnSpacing={2}
              xs={12}
            >
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    {getPhrase("ORDER_ITEMS")}
                  </Typography>
                  {/* Button to Add Item */}
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      appendOrderItem({
                        productId: "",
                        quantity: 1,
                        pricePerUnit: 0,
                        category: ProductCategory.Other,
                      })
                    }
                  >
                    {getPhrase("ORDER_ADD_PRODUCT")}
                  </Button>
                </Box>
              </Grid>
              {orderItemsFields.map((item, index) => (
                <Grid
                  item
                  container
                  key={item.id}
                  columnSpacing={1}
                  rowSpacing={2}
                  alignItems="center"
                >
                  {/* Product Selection */}
                  <Grid item xs={4}>
                    <Controller
                      name={`items.${index}.productId`}
                      control={control}
                      render={({ field }) => {
                        const selectedProduct = products.find(
                          (p) => p.id === field.value
                        );

                        // Function to handle product change
                        const handleProductChange = (
                          product: ProductOutput
                        ) => {
                          field.onChange(product.id);
                          // Update price per unit
                          setValue(
                            `items.${index}.pricePerUnit`,
                            product.price || 0
                          );
                          // Update category
                          setValue(
                            `items.${index}.category`,
                            product.category || ProductCategory.Other
                          );
                        };

                        return (
                          <Autocomplete
                            disableClearable
                            size="small"
                            options={products}
                            getOptionLabel={(option) => option.name}
                            value={selectedProduct}
                            onChange={(_, value) => handleProductChange(value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={getPhrase("PRODUCT")}
                                variant="outlined"
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Grid>

                  {/* Quantity Input */}
                  <Grid item xs={1}>
                    <Controller
                      name={`items.${index}.quantity`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          type="number"
                          label={getPhrase("QUANTITY")}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  {/* Price Per Unit Input */}
                  <Grid item xs={2}>
                    <Controller
                      name={`items.${index}.pricePerUnit`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          type="number"
                          label={getPhrase("PRICE_PER_UNIT")}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  {/* Subtotal */}
                  <Grid item xs={2}>
                    <Typography variant="body1">
                      {getPhrase("SUBTOTAL")}: {getPhrase("CURRENCY.SYMBOL")}{" "}
                      {(
                        Number(watchedItems[index]?.quantity || 0) *
                        Number(watchedItems[index]?.pricePerUnit || 0)
                      ).toFixed(2)}
                    </Typography>
                  </Grid>

                  {/* Remove Button */}
                  <Grid display={"flex"} justifyContent={"end"} item xs={3}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={() => removeOrderItem(index)}
                    >
                      {getPhrase("ORDER_REMOVE_PRODUCT")}
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            {/* Dynamic Order Equipment */}
            <Grid
              mt={5}
              container
              item
              rowSpacing={2}
              columnSpacing={2}
              xs={12}
            >
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    {getPhrase("ORDER_EQUIPMENT")}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() =>
                      appendOrderEquipment({
                        equipmentId: "",
                        quantity: 1,
                        pricePerUnit: 0,
                        referenceCode: "",
                      })
                    }
                  >
                    {getPhrase("ORDER_ADD_EQUIPMENT")}
                  </Button>
                </Box>
              </Grid>
              {orderEquipmentFields.map((equipment, index) => (
                <Grid
                  item
                  container
                  key={equipment.id}
                  columnSpacing={1}
                  rowSpacing={2}
                  alignItems="center"
                  xs={12}
                >
                  {/* Equipment Selection */}
                  <Grid item xs={4}>
                    <Controller
                      name={`orderEquipment.${index}.equipmentId`}
                      control={control}
                      render={({ field }) => {
                        const selectedEquipment = equipments.find(
                          (e) => e.id === field.value
                        );

                        // Function to handle equipment change
                        const handleEquipmentChange = (
                          equipment: EquipmentOutput
                        ) => {
                          field.onChange(equipment.id);
                          // Update price per unit
                          setValue(
                            `orderEquipment.${index}.pricePerUnit`,
                            equipment.pricePerUnit || 0
                          );
                          // Update reference code
                          setValue(
                            `orderEquipment.${index}.referenceCode`,
                            equipment.referenceCode || ""
                          );
                        };

                        return (
                          <Autocomplete
                            disableClearable
                            options={equipments}
                            getOptionLabel={(option) => option.name}
                            value={selectedEquipment}
                            onChange={(_, value) =>
                              handleEquipmentChange(value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                label={getPhrase("ORDER_EQUIPMENT")}
                                variant="outlined"
                                error={
                                  !!errors.orderEquipment?.[index]?.equipmentId
                                }
                                helperText={
                                  errors.orderEquipment?.[index]?.equipmentId
                                    ?.message
                                }
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Grid>

                  {/* Quantity Input */}
                  <Grid item xs={1}>
                    <Controller
                      name={`orderEquipment.${index}.quantity`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          type="number"
                          label={getPhrase("QUANTITY")}
                          variant="outlined"
                          error={!!errors.orderEquipment?.[index]?.quantity}
                          helperText={
                            errors.orderEquipment?.[index]?.quantity?.message
                          }
                        />
                      )}
                    />
                  </Grid>

                  {/* Price Per Unit Input */}
                  <Grid item xs={2}>
                    <Controller
                      name={`orderEquipment.${index}.pricePerUnit`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          type="number"
                          label={getPhrase("PRICE_PER_UNIT")}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  {/* Reference Code Input */}
                  <Grid item xs={2}>
                    <Controller
                      name={`orderEquipment.${index}.referenceCode`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label={getPhrase("EQUIPMENT_REFERENCE_CODE")}
                          variant="outlined"
                          error={
                            !!errors.orderEquipment?.[index]?.referenceCode
                          }
                          helperText={
                            errors.orderEquipment?.[index]?.referenceCode
                              ?.message
                          }
                        />
                      )}
                    />
                  </Grid>

                  {/* Subtotal */}
                  <Grid item xs={2}>
                    <Typography variant="body1">
                      {getPhrase("SUBTOTAL")}: {getPhrase("CURRENCY.SYMBOL")}{" "}
                      {(
                        Number(watchedOrderEquipment[index]?.quantity || 0) *
                        Number(watchedOrderEquipment[index]?.pricePerUnit || 0)
                      ).toFixed(2)}
                    </Typography>
                  </Grid>

                  {/* Remove Button */}
                  <Grid item xs={1}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={() => removeEquipmentItem(index)}
                    >
                      {getPhrase("ORDER_REMOVE_EQUIPMENT")}
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            {/* Dynamic Order Discount */}
            <Grid
              mt={5}
              container
              item
              rowSpacing={2}
              columnSpacing={2}
              xs={12}
            >
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    {getPhrase("ORDER_DISCOUNTS")}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      if (discounts.length === 0) {
                        showMessage(
                          getPhrase("NO_DISCOUNTS_AVAILABLE"),
                          "error"
                        );
                        return;
                      }
                      appendDiscount({
                        discountId: discounts[0].id,
                        name: discounts[0].name,
                        type: discounts[0].type,
                        value: discounts[0].value,
                        productCategory: ProductCategory.Other,
                      });
                    }}
                  >
                    {getPhrase("ORDER_ADD_DISCOUNT")}
                  </Button>
                </Box>
              </Grid>
              {discountsFields.map((discount, index) => (
                <Grid
                  item
                  container
                  key={discount.id}
                  columnSpacing={1}
                  rowSpacing={2}
                  alignItems="center"
                  xs={12}
                >
                  {/* Discount Selection */}
                  <Grid item xs={4}>
                    <Controller
                      name={`discounts.${index}.discountId`}
                      control={control}
                      render={({ field }) => {
                        const selectedDiscount = discounts.find(
                          (d) => d.id === field.value
                        );

                        // Function to handle discount change
                        const handleDiscountChange = (
                          discount: DiscountOutput | null
                        ) => {
                          if (!discount) {
                            throw new Error("Discount not found");
                          }
                          field.onChange(discount.id);
                          // Update discount fields
                          setValue(`discounts.${index}.type`, discount.type);
                          setValue(`discounts.${index}.value`, discount.value);
                          setValue(`discounts.${index}.name`, discount.name);
                          setValue(
                            `discounts.${index}.productCategory`,
                            discount.productCategory
                          );
                        };

                        return (
                          <Autocomplete
                            disableClearable
                            options={discounts}
                            getOptionLabel={(option) => option.name}
                            value={selectedDiscount}
                            onChange={(_, value) => handleDiscountChange(value)}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={getPhrase("DISCOUNT_NAME")}
                                variant="outlined"
                                error={!!errors.discounts?.[index]?.discountId}
                                helperText={
                                  errors.discounts?.[index]?.discountId?.message
                                }
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    {getPhrase(`TYPE`)}:{" "}
                    {getPhrase(
                      `DISCOUNT_TYPE.${watchedDiscounts[index]?.type}`
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {watchedDiscounts[index]?.type === DiscountType.Percentage
                      ? `${watchedDiscounts[index]?.value}%`
                      : `${getPhrase("CURRENCY.SYMBOL")} ${watchedDiscounts[
                          index
                        ]?.value.toFixed(2)}`}
                  </Grid>
                  <Grid item xs={2}>
                    {getPhrase("ORDER_DISCOUNT_DISCOUNTED")}:{" "}
                    {getPhrase("CURRENCY.SYMBOL")}{" "}
                    {calculateDiscounted(
                      watchedDiscounts[index],
                      watchedItems
                    ).toFixed(2)}
                  </Grid>

                  {/* Remove Button */}
                  <Grid item xs={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      color="error"
                      sx={{ textWrap: "nowrap" }}
                      onClick={() => removeDiscount(index)}
                    >
                      {getPhrase("ORDER_REMOVE_DISCOUNT")}
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item container xs={12} mt={3} spacing={2}>
              <Grid item xs={12} sm={8}></Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  label={getPhrase("TOTAL_AMOUNT")}
                  type="number"
                  value={totalAmount.toFixed(2)}
                />
              </Grid>

              <Grid item xs={12} sm={8}></Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  label={getPhrase("TOTAL_DISCOUNTS")}
                  type="number"
                  value={totalDiscounts.toFixed(2)}
                />
              </Grid>

              <Grid item xs={12} sm={8}></Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  label={getPhrase("ORDER_REMAINING_BALANCE")}
                  type="number"
                  value={balance.toFixed(2)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Box display={"flex"} justifyContent={"center"} my={5}>
            <Button
              onClick={handleCancel}
              variant="outlined"
              color="primary"
              sx={{ width: "30%", mr: 5 }}
            >
              {getPhrase("CANCEL")}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ width: "30%" }}
            >
              {getPhrase("SAVE")}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};
