/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressCreateInput } from './AddressCreateInput';
import {
    AddressCreateInputFromJSON,
    AddressCreateInputFromJSONTyped,
    AddressCreateInputToJSON,
} from './AddressCreateInput';
import type { CustomerContactCreateInput } from './CustomerContactCreateInput';
import {
    CustomerContactCreateInputFromJSON,
    CustomerContactCreateInputFromJSONTyped,
    CustomerContactCreateInputToJSON,
} from './CustomerContactCreateInput';

/**
 * 
 * @export
 * @interface CustomerCreateInput
 */
export interface CustomerCreateInput {
    /**
     * 
     * @type {string}
     * @memberof CustomerCreateInput
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreateInput
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreateInput
     */
    cpf?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreateInput
     */
    cnpj?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreateInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreateInput
     */
    email2?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreateInput
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreateInput
     */
    phone2?: string;
    /**
     * 
     * @type {Array<CustomerContactCreateInput>}
     * @memberof CustomerCreateInput
     */
    contacts?: Array<CustomerContactCreateInput>;
    /**
     * 
     * @type {AddressCreateInput}
     * @memberof CustomerCreateInput
     */
    address: AddressCreateInput;
    /**
     * 
     * @type {AddressCreateInput}
     * @memberof CustomerCreateInput
     */
    address2?: AddressCreateInput;
    /**
     * 
     * @type {Date}
     * @memberof CustomerCreateInput
     */
    dob?: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomerCreateInput
     */
    notes?: string;
}

/**
 * Check if a given object implements the CustomerCreateInput interface.
 */
export function instanceOfCustomerCreateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

export function CustomerCreateInputFromJSON(json: any): CustomerCreateInput {
    return CustomerCreateInputFromJSONTyped(json, false);
}

export function CustomerCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'cpf': !exists(json, 'cpf') ? undefined : json['cpf'],
        'cnpj': !exists(json, 'cnpj') ? undefined : json['cnpj'],
        'email': json['email'],
        'email2': !exists(json, 'email2') ? undefined : json['email2'],
        'phone': json['phone'],
        'phone2': !exists(json, 'phone2') ? undefined : json['phone2'],
        'contacts': !exists(json, 'contacts') ? undefined : ((json['contacts'] as Array<any>).map(CustomerContactCreateInputFromJSON)),
        'address': AddressCreateInputFromJSON(json['address']),
        'address2': !exists(json, 'address2') ? undefined : AddressCreateInputFromJSON(json['address2']),
        'dob': !exists(json, 'dob') ? undefined : (new Date(json['dob'])),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function CustomerCreateInputToJSON(value?: CustomerCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'cpf': value.cpf,
        'cnpj': value.cnpj,
        'email': value.email,
        'email2': value.email2,
        'phone': value.phone,
        'phone2': value.phone2,
        'contacts': value.contacts === undefined ? undefined : ((value.contacts as Array<any>).map(CustomerContactCreateInputToJSON)),
        'address': AddressCreateInputToJSON(value.address),
        'address2': AddressCreateInputToJSON(value.address2),
        'dob': value.dob === undefined ? undefined : (value.dob.toISOString()),
        'notes': value.notes,
    };
}

