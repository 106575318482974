import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePhrases } from "../../../context/PhrasesContext";

export const AnalyticsListPage: React.FC = () => {
  const navigate = useNavigate();
  const { getPhrase } = usePhrases();

  return (
    <Container>
      <Box>
        <Typography variant="h4" component="h1" my={5}>
          {getPhrase("ANALYTICS_PAGE_TITLE")}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box>
            <Button
              color="primary"
              variant="outlined"
              onClick={() =>
                navigate("/analytics/summarized-order-data-by-day")
              }
            >
              {getPhrase("ANALYTICS_PAGE_SUMMARIZED_ORDER_DATA_BY_DAY")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
