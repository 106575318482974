import React, { useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Checkbox,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Action, Resources } from "../../../utils/authHelpers";
import { StoreBranch, UserCreateInput } from "../../../generated-api";
import { Chip } from "@mui/joy";
import {
  USER_CREATE_INPUT_SCHEMA,
  USER_UPDATE_INPUT_SCHEMA,
  userService,
} from "../../../services/UserApi";
import { usePhrases } from "../../../context/PhrasesContext";
import { removeFalsy } from "../../../utils/removeFalsy";
import { useSnackbar } from "../../../context/SnackbarContext";

export const UserCreateAndEditPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const isEditMode = Boolean(userId);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = useForm<UserCreateInput & { confirmPassword: string }>({
    resolver: joiResolver(
      isEditMode ? USER_UPDATE_INPUT_SCHEMA : USER_CREATE_INPUT_SCHEMA
    ),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      permissions: [],
      branches: [StoreBranch.Santos],
      defaultBranch: StoreBranch.Santos,
    },
  });

  const { getPhrase } = usePhrases();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  // Add watch to monitor changes in permissions
  const watchedPermissions = watch("permissions");

  useEffect(() => {
    if (isEditMode && userId) {
      // Fetch user data and populate form
      const fetchUserData = async () => {
        try {
          const userData = await userService.getOneUser({ userId });
          reset({
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            permissions: userData.permissions,
            branches: userData.branches,
            defaultBranch: userData.defaultBranch,
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
          showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
        }
      };
      fetchUserData();
    }
  }, [isEditMode, userId, reset, showMessage, getPhrase]);

  const onSubmit: SubmitHandler<UserCreateInput> = async (data) => {
    try {
      // Remove falsy values
      const cleanedData = removeFalsy({ ...data, confirmPassword: undefined });

      if (isEditMode && userId) {
        await userService.updateUser({ userId, userUpdateInput: cleanedData });
        navigate(`/users/${userId}`); // Redirect to users list page on success
        showMessage("User updated successfully", "success");
      } else {
        const createadUser = await userService.createUser({
          userCreateInput: cleanedData,
        });
        showMessage("User created successfully", "success");
        navigate(`/users/${createadUser.id}`); // Redirect to users list page on success
      }
    } catch (error) {
      if (isEditMode) {
        console.error("Error updating user:", error);
        showMessage("Error updating user", "error");
      } else {
        console.error("Error creating user:", error);
        showMessage("Error creating user", "error");
      }
    }
  };

  const handleCheckboxChange = (resource: string, action: string) => {
    const permission = `${resource}:${action}`;
    if (watchedPermissions.includes(permission)) {
      setValue(
        "permissions",
        watchedPermissions.filter((p) => p !== permission)
      );
    } else {
      setValue("permissions", [...watchedPermissions, permission]);
    }
  };

  return (
    <Container>
      <Box my={5}>
        <Typography component="h1" variant="h4">
          {isEditMode ? getPhrase("EDIT_USER") : getPhrase("CREATE_USER")}
        </Typography>
      </Box>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={4}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label={getPhrase("FIRST_NAME")}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                )}
              />
            </Grid>

            {/* Last Name */}
            <Grid item xs={12} sm={4}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label={getPhrase("LAST_NAME")}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                  />
                )}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={4}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label={getPhrase("EMAIL")}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Grid>

            {/* Password */}
            <Grid item xs={6}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label={getPhrase("PASSWORD")}
                    type="password"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              />
            </Grid>

            {/* Confirm Password */}
            <Grid item xs={6}>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label={getPhrase("CONFIRM_PASSWORD")}
                    type="password"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                  />
                )}
              />
            </Grid>

            {/* User Branches */}
            <Grid item xs={12} sm={6}>
              <Controller
                name="branches"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="user-branches-label">
                      {getPhrase("BRANCHES")}
                    </InputLabel>
                    <Select
                      labelId="user-branches-label"
                      id="user-branches-select"
                      multiple
                      value={field.value || []}
                      onChange={(event) => field.onChange(event.target.value)}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="User Branches"
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value}>{value}</Chip>
                          ))}
                        </Box>
                      )}
                    >
                      {Object.values(StoreBranch).map((branch) => (
                        <MenuItem key={branch} value={branch}>
                          {branch}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            {/* Default Branch */}
            <Grid item xs={12} sm={6}>
              <Controller
                name="defaultBranch"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label={getPhrase("DEFAULT_BRANCH")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.defaultBranch}
                    helperText={errors.defaultBranch?.message}
                  >
                    {/* Map TimeOfDay enum to menu items */}
                    {Object.values(StoreBranch).map((branch) => (
                      <MenuItem key={branch} value={branch}>
                        {branch}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            {/* Permissions Table */}
            <Grid item xs={12}>
              <Box mb={1} mt={3}>
                <Typography variant="h6">{getPhrase("PERMISSIONS")}</Typography>
              </Box>

              <TableContainer component={Paper}>
                <Table size="small" aria-label="permissions table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{getPhrase("RESOURCE_AND_ACTION")}</TableCell>
                      {Object.values(Action).map((action) => (
                        <TableCell key={action}>
                          {getPhrase(`ACTION.${action}` as any)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(Resources).map((resource) => (
                      <TableRow key={resource}>
                        <TableCell>
                          {getPhrase(`RESOURCE.${resource}` as any)}
                        </TableCell>
                        {Object.values(Action).map((action) => (
                          <TableCell key={action}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={watchedPermissions.includes(
                                    `${resource}:${action}`
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(resource, action)
                                  }
                                />
                              }
                              label=""
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Box display={"flex"} justifyContent={"center"}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2, width: "25%" }}
            >
              {isEditMode
                ? getPhrase("SAVE_CHANGES")
                : getPhrase("CREATE_USER")}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};
