/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents the input for a user login request.
 * @export
 * @interface LoginInput
 */
export interface LoginInput {
    /**
     * 
     * @type {string}
     * @memberof LoginInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginInput
     */
    password: string;
}

/**
 * Check if a given object implements the LoginInput interface.
 */
export function instanceOfLoginInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;

    return isInstance;
}

export function LoginInputFromJSON(json: any): LoginInput {
    return LoginInputFromJSONTyped(json, false);
}

export function LoginInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
    };
}

export function LoginInputToJSON(value?: LoginInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
    };
}

