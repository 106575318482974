import Joi from "joi";
import {
  Configuration,
  DiscountApi,
  DiscountType,
  OrderDiscountCreateInput,
  OrderDiscountUpdateInput,
  OrderItemOutput,
  OrderItemsCreateInput,
  OrderItemsUpdateInput,
  ProductCategory,
} from "../generated-api";
import { getAccessToken } from "../utils/getAccessToken";

class DiscountService extends DiscountApi {
  constructor() {
    const config = new Configuration({
      accessToken: getAccessToken,
      basePath: process.env.REACT_APP_API_URL,
    });

    super(config);
  }
}

/**
 * Calculate the discount object discounted field value
 */
export const calculateDiscounted = (
  discount: OrderDiscountCreateInput | OrderDiscountUpdateInput,
  items: OrderItemsCreateInput[] | OrderItemsUpdateInput[] | OrderItemOutput[]
): number => {
  const itemsFromSameCategory = items.filter(
    (item) => item.category === discount.productCategory
  );
  return calculateDiscountedValue(
    discount,
    calculateItemsTotal(itemsFromSameCategory)
  );
};

/*
 * Calculate the discounted value based on the discount type
 * This is just a helper to handle calculation of percetage and fixed value discounts
 */
export const calculateDiscountedValue = (
  discount: OrderDiscountCreateInput | OrderDiscountUpdateInput,
  itemsTotal: number
): number => {
  if (discount.type === DiscountType.Percentage) {
    return itemsTotal * (discount.value / 100);
  } else {
    // TODO: If the discount is a fixed value, we should check if the total amount is greater than the discount value?
    return discount.value;
  }
};

export const calculateItemsTotal = (
  items: OrderItemsCreateInput[] | OrderItemsUpdateInput[] | OrderItemOutput[]
): number => {
  return items.reduce(
    (total, item) => total + item.pricePerUnit * item.quantity,
    0
  );
};

export const discountService = new DiscountService();

export const DISCOUNT_UPDATE_INPUT_SCHEMA = Joi.object({
  name: Joi.string().trim().allow("").optional(),
  description: Joi.string().trim().allow("").optional(),
  type: Joi.string()
    .valid(...Object.values(DiscountType))
    .optional(),
  value: Joi.number()
    .min(0)
    .required()
    .when("type", {
      is: DiscountType.Percentage,
      then: Joi.number().max(100),
    }),
  productCategory: Joi.string()
    .valid(...Object.values(ProductCategory))
    .required(),
});

export const DISCOUNT_CREATE_INPUT_SCHEMA = Joi.object({
  name: Joi.string().trim().required(),
  description: Joi.string().trim().allow("").optional(),
  type: Joi.string()
    .valid(...Object.values(DiscountType))
    .required(),
  value: Joi.number()
    .min(0)
    .required()
    .when("type", {
      is: DiscountType.Percentage,
      then: Joi.number().max(100),
    }),
  productCategory: Joi.string()
    .valid(...Object.values(ProductCategory))
    .required(),
});
