/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SummarizedOrderDataByDayOutputDaysInner } from './SummarizedOrderDataByDayOutputDaysInner';
import {
    SummarizedOrderDataByDayOutputDaysInnerFromJSON,
    SummarizedOrderDataByDayOutputDaysInnerFromJSONTyped,
    SummarizedOrderDataByDayOutputDaysInnerToJSON,
} from './SummarizedOrderDataByDayOutputDaysInner';
import type { SummarizedOrderDataByDayOutputDaysInnerEquipmentsInner } from './SummarizedOrderDataByDayOutputDaysInnerEquipmentsInner';
import {
    SummarizedOrderDataByDayOutputDaysInnerEquipmentsInnerFromJSON,
    SummarizedOrderDataByDayOutputDaysInnerEquipmentsInnerFromJSONTyped,
    SummarizedOrderDataByDayOutputDaysInnerEquipmentsInnerToJSON,
} from './SummarizedOrderDataByDayOutputDaysInnerEquipmentsInner';
import type { SummarizedOrderDataByDayOutputDaysInnerPaymentsInner } from './SummarizedOrderDataByDayOutputDaysInnerPaymentsInner';
import {
    SummarizedOrderDataByDayOutputDaysInnerPaymentsInnerFromJSON,
    SummarizedOrderDataByDayOutputDaysInnerPaymentsInnerFromJSONTyped,
    SummarizedOrderDataByDayOutputDaysInnerPaymentsInnerToJSON,
} from './SummarizedOrderDataByDayOutputDaysInnerPaymentsInner';
import type { SummarizedOrderDataByDayOutputDaysInnerProductsInner } from './SummarizedOrderDataByDayOutputDaysInnerProductsInner';
import {
    SummarizedOrderDataByDayOutputDaysInnerProductsInnerFromJSON,
    SummarizedOrderDataByDayOutputDaysInnerProductsInnerFromJSONTyped,
    SummarizedOrderDataByDayOutputDaysInnerProductsInnerToJSON,
} from './SummarizedOrderDataByDayOutputDaysInnerProductsInner';

/**
 * 
 * @export
 * @interface SummarizedOrderDataByDayOutput
 */
export interface SummarizedOrderDataByDayOutput {
    /**
     * 
     * @type {Array<SummarizedOrderDataByDayOutputDaysInner>}
     * @memberof SummarizedOrderDataByDayOutput
     */
    days: Array<SummarizedOrderDataByDayOutputDaysInner>;
    /**
     * 
     * @type {number}
     * @memberof SummarizedOrderDataByDayOutput
     */
    totalOrdersCount: number;
    /**
     * 
     * @type {number}
     * @memberof SummarizedOrderDataByDayOutput
     */
    totalValue: number;
    /**
     * 
     * @type {Array<SummarizedOrderDataByDayOutputDaysInnerProductsInner>}
     * @memberof SummarizedOrderDataByDayOutput
     */
    totalProducts: Array<SummarizedOrderDataByDayOutputDaysInnerProductsInner>;
    /**
     * 
     * @type {Array<SummarizedOrderDataByDayOutputDaysInnerEquipmentsInner>}
     * @memberof SummarizedOrderDataByDayOutput
     */
    totalEquipments: Array<SummarizedOrderDataByDayOutputDaysInnerEquipmentsInner>;
    /**
     * 
     * @type {number}
     * @memberof SummarizedOrderDataByDayOutput
     */
    totalDeliveryFees: number;
    /**
     * 
     * @type {Array<SummarizedOrderDataByDayOutputDaysInnerPaymentsInner>}
     * @memberof SummarizedOrderDataByDayOutput
     */
    totalPayments: Array<SummarizedOrderDataByDayOutputDaysInnerPaymentsInner>;
}

/**
 * Check if a given object implements the SummarizedOrderDataByDayOutput interface.
 */
export function instanceOfSummarizedOrderDataByDayOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "days" in value;
    isInstance = isInstance && "totalOrdersCount" in value;
    isInstance = isInstance && "totalValue" in value;
    isInstance = isInstance && "totalProducts" in value;
    isInstance = isInstance && "totalEquipments" in value;
    isInstance = isInstance && "totalDeliveryFees" in value;
    isInstance = isInstance && "totalPayments" in value;

    return isInstance;
}

export function SummarizedOrderDataByDayOutputFromJSON(json: any): SummarizedOrderDataByDayOutput {
    return SummarizedOrderDataByDayOutputFromJSONTyped(json, false);
}

export function SummarizedOrderDataByDayOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummarizedOrderDataByDayOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'days': ((json['days'] as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerFromJSON)),
        'totalOrdersCount': json['totalOrdersCount'],
        'totalValue': json['totalValue'],
        'totalProducts': ((json['totalProducts'] as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerProductsInnerFromJSON)),
        'totalEquipments': ((json['totalEquipments'] as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerEquipmentsInnerFromJSON)),
        'totalDeliveryFees': json['totalDeliveryFees'],
        'totalPayments': ((json['totalPayments'] as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerPaymentsInnerFromJSON)),
    };
}

export function SummarizedOrderDataByDayOutputToJSON(value?: SummarizedOrderDataByDayOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'days': ((value.days as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerToJSON)),
        'totalOrdersCount': value.totalOrdersCount,
        'totalValue': value.totalValue,
        'totalProducts': ((value.totalProducts as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerProductsInnerToJSON)),
        'totalEquipments': ((value.totalEquipments as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerEquipmentsInnerToJSON)),
        'totalDeliveryFees': value.totalDeliveryFees,
        'totalPayments': ((value.totalPayments as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerPaymentsInnerToJSON)),
    };
}

