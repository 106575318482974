/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductCategory } from './ProductCategory';
import {
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';
import type { ProductStatus } from './ProductStatus';
import {
    ProductStatusFromJSON,
    ProductStatusFromJSONTyped,
    ProductStatusToJSON,
} from './ProductStatus';
import type { SizeUnit } from './SizeUnit';
import {
    SizeUnitFromJSON,
    SizeUnitFromJSONTyped,
    SizeUnitToJSON,
} from './SizeUnit';

/**
 * 
 * @export
 * @interface ProductOutput
 */
export interface ProductOutput {
    /**
     * 
     * @type {string}
     * @memberof ProductOutput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOutput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOutput
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ProductOutput
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof ProductOutput
     */
    size: number;
    /**
     * 
     * @type {SizeUnit}
     * @memberof ProductOutput
     */
    sizeUnit: SizeUnit;
    /**
     * 
     * @type {ProductCategory}
     * @memberof ProductOutput
     */
    category: ProductCategory;
    /**
     * 
     * @type {Date}
     * @memberof ProductOutput
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductOutput
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductOutput
     */
    archivedAt?: Date;
    /**
     * 
     * @type {ProductStatus}
     * @memberof ProductOutput
     */
    status: ProductStatus;
}

/**
 * Check if a given object implements the ProductOutput interface.
 */
export function instanceOfProductOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "sizeUnit" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function ProductOutputFromJSON(json: any): ProductOutput {
    return ProductOutputFromJSONTyped(json, false);
}

export function ProductOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'price': json['price'],
        'size': json['size'],
        'sizeUnit': SizeUnitFromJSON(json['sizeUnit']),
        'category': ProductCategoryFromJSON(json['category']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'archivedAt': !exists(json, 'archivedAt') ? undefined : (new Date(json['archivedAt'])),
        'status': ProductStatusFromJSON(json['status']),
    };
}

export function ProductOutputToJSON(value?: ProductOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'size': value.size,
        'sizeUnit': SizeUnitToJSON(value.sizeUnit),
        'category': ProductCategoryToJSON(value.category),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'archivedAt': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
        'status': ProductStatusToJSON(value.status),
    };
}

