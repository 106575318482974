/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductCategory } from './ProductCategory';
import {
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';

/**
 * 
 * @export
 * @interface OrderItemsUpdateInput
 */
export interface OrderItemsUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof OrderItemsUpdateInput
     */
    productId: string;
    /**
     * 
     * @type {ProductCategory}
     * @memberof OrderItemsUpdateInput
     */
    category: ProductCategory;
    /**
     * 
     * @type {number}
     * @memberof OrderItemsUpdateInput
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemsUpdateInput
     */
    pricePerUnit: number;
}

/**
 * Check if a given object implements the OrderItemsUpdateInput interface.
 */
export function instanceOfOrderItemsUpdateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "pricePerUnit" in value;

    return isInstance;
}

export function OrderItemsUpdateInputFromJSON(json: any): OrderItemsUpdateInput {
    return OrderItemsUpdateInputFromJSONTyped(json, false);
}

export function OrderItemsUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemsUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'category': ProductCategoryFromJSON(json['category']),
        'quantity': json['quantity'],
        'pricePerUnit': json['pricePerUnit'],
    };
}

export function OrderItemsUpdateInputToJSON(value?: OrderItemsUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'category': ProductCategoryToJSON(value.category),
        'quantity': value.quantity,
        'pricePerUnit': value.pricePerUnit,
    };
}

