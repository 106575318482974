import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { usePhrases } from "../../context/PhrasesContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { customerService } from "../../services/CustomerApi";
import { CustomerMediaCategory, OrderMediaCategory } from "../../generated-api";
import { orderService } from "../../services/OrderApi";

interface FileUploadDialogProps {
  open: boolean;
  onClose: () => void;
  onSucess: () => void;
  customerId?: string;
  orderId?: string;
}

const FileUploadDialog: React.FC<FileUploadDialogProps> = ({
  customerId,
  orderId,
  open,
  onClose,
  onSucess,
}) => {
  if (!customerId && !orderId) {
    throw new Error("customerId or orderId is required");
  }
  if (customerId && orderId) {
    throw new Error("Only one of customerId or orderId can be provided");
  }

  const getInitialCategory = () => {
    if (customerId) {
      return CustomerMediaCategory.Other;
    }
    if (orderId) {
      return OrderMediaCategory.Other;
    }
    throw new Error("customerId or orderId is required");
  };
  const [file, setFile] = useState<File | null>(null);
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState<
    CustomerMediaCategory | OrderMediaCategory
  >(getInitialCategory());
  const [isUploading, setIsUploading] = useState(false);

  const { getPhrase } = usePhrases();
  const { showMessage } = useSnackbar();

  const resizeImage = (
    file: Blob,
    maxWidth: number,
    maxHeight: number,
    callback: BlobCallback
  ) => {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const image = new Image();
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        // Check if the current width is larger than the max
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }

        // Check if current height is larger than max
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        // Resize the image
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx!.drawImage(image, 0, 0, width, height);

        canvas.toBlob(callback, file.type || "image/png", 0.95); // Adjust the quality as needed
      };
      image.src = readerEvent.target!.result!.toString();
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleAddFileToCustomer = async () => {
    if (!file) {
      showMessage(getPhrase("PLEASE_SELECT_A_FILE"), "error");
      return;
    }
    if (!displayName) {
      showMessage(getPhrase("PLEASE_ENTER_A_NAME"), "error");
      return;
    }
    setIsUploading(true);

    try {
      let fileToUpload = file;
      // Only resize if it's an image
      const maxXY = 1920;
      try {
        if (file.type.startsWith("image/")) {
          await new Promise((resolve) => {
            resizeImage(file, maxXY, maxXY, (blob) => {
              fileToUpload = new File([blob!], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(null);
            });
          });
        }
      } catch (error) {
        console.error(error);
        showMessage(getPhrase("ERROR.WHEN_TRYING_TO_RESIZE_IMAGE"), "error");
        // Fallback to original file
        fileToUpload = file;
      }

      if (customerId) {
        await customerService.addFileToCustomer({
          customerId,
          file: fileToUpload,
          displayName,
          description,
          customerMediaCategory: category,
        });
      } else if (orderId) {
        await orderService.addFileToOrder({
          orderId,
          file: fileToUpload,
          displayName,
          description,
          orderMediaCategory: category,
        });
      }
      showMessage(getPhrase("FILE_UPLOADED_SUCCESSFULLY"), "success");
    } catch (error) {
      console.error(error);
      showMessage(getPhrase("ERROR.GENERIC.UPLOAD_FILE"), "error");
    } finally {
      setIsUploading(false);
      onSucess();
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    setFile(null);
    setDisplayName("");
    setDescription("");
    onClose();
  };

  return (
    <Dialog maxWidth="sm" open={open} onClose={handleOnClose}>
      <DialogTitle>{getPhrase("UPLOAD_FILE")}</DialogTitle>
      <DialogContent>
        <Box display={"flex"} alignItems={"center"}>
          <input
            // accept image and pdf, docx
            accept="image/*,.pdf,.docx"
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <Button
              disabled={isUploading}
              startIcon={<CloudUploadIcon />}
              variant="contained"
              component="span"
            >
              {getPhrase("UPLOAD_FILE")}
            </Button>
          </label>
          {isUploading && <CircularProgress size={30} sx={{ ml: 2 }} />}
        </Box>
        <Box my={2}>
          <Typography variant="body2" color="textSecondary">
            {file?.name}
          </Typography>
        </Box>

        <TextField
          size="small"
          margin="dense"
          id="displayName"
          label={getPhrase("DOCUMENT_NAME")}
          type="text"
          fullWidth
          variant="outlined"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
        {customerId && (
          <TextField
            size="small"
            margin="dense"
            select
            label={getPhrase("CUSTOMER_MEDIA_CATEGORY")}
            variant="outlined"
            fullWidth
            value={category}
            onChange={(e) =>
              setCategory(e.target.value as CustomerMediaCategory)
            }
          >
            {Object.values(CustomerMediaCategory).map((category) => (
              <MenuItem key={category} value={category}>
                {getPhrase(`CUSTOMER_MEDIA_CATEGORY.${category}` as any)}
              </MenuItem>
            ))}
          </TextField>
        )}
        {orderId && (
          <TextField
            size="small"
            margin="dense"
            select
            label={getPhrase("ORDER_MEDIA_CATEGORY")}
            variant="outlined"
            fullWidth
            value={category}
            onChange={(e) => setCategory(e.target.value as OrderMediaCategory)}
          >
            {Object.values(OrderMediaCategory).map((category) => (
              <MenuItem key={category} value={category}>
                {getPhrase(`ORDER_MEDIA_CATEGORY.${category}` as any)}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          size="small"
          margin="dense"
          id="description"
          label={getPhrase("DESCRIPTION")}
          type="text"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleOnClose} disabled={isUploading}>
          {getPhrase("CANCEL")}
        </Button>
        <Button onClick={handleAddFileToCustomer} disabled={isUploading}>
          {getPhrase("UPLOAD_FILE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;
