import { CircularProgress } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import { StoreBranch } from "../generated-api";

interface AuthState {
  token: string | null;
  refreshToken: string | null;
  permissions: string[];
  isAuthenticated: boolean;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    branches: StoreBranch[];
    defaultBranch: StoreBranch;
    selectedBranch: StoreBranch;
  } | null;
  loading: boolean;
}

interface JwtPayload {
  branches: StoreBranch[];
  defaultBranch: StoreBranch;
  userId: string;
  firstName: string;
  lastName: string;
  permissions: string[];
  exp: number;
}

interface AuthContextType {
  authState: AuthState;
  login: (accessToken: string, refreshToken: string) => void;
  logout: () => void;
  setSelectedBranch: (branch: StoreBranch) => void;
}

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export let exportedAccessToken: string | null = null;

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authState, setAuthState] = useState<AuthState>({
    token: null,
    refreshToken: null,
    permissions: [],
    isAuthenticated: false,
    user: null,
    loading: true,
  });

  const setTokens = (accessToken: string, refreshToken: string) => {
    console.log("Logging in");

    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    const decoded = jwtDecode(accessToken) as JwtPayload;
    setAuthState((prevState) => {
      const selectedBranch = prevState.user?.selectedBranch;
      return {
        token: accessToken,
        refreshToken,
        permissions: decoded.permissions,
        isAuthenticated: true,
        user: {
          ...prevState.user,
          id: decoded.userId,
          firstName: decoded.firstName,
          lastName: decoded.lastName,
          branches: decoded.branches,
          defaultBranch: decoded.defaultBranch,
          // preserve selected branch if it exists, otherwise use default branch
          selectedBranch: selectedBranch || decoded.defaultBranch,
        },
        loading: false,
      };
    });
  };

  const logout = () => {
    console.log("Logging out");

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setAuthState({
      token: null,
      refreshToken: null,
      permissions: [],
      isAuthenticated: false,
      user: null,
      loading: false,
    });
  };

  const setSelectedBranch = (branch: StoreBranch) => {
    if (!authState.user) return;
    console.log("Setting selected branch to", branch);
    localStorage.setItem("selectedBranch", branch);
    setAuthState({
      ...authState,
      user: {
        ...authState.user,
        selectedBranch: branch,
      },
    });
  };

  const { refreshToken, token } = authState;

  const refreshAccessToken = useCallback(async () => {
    console.log("Refreshing access token");

    if (!refreshToken) {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/users/refresh-token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          body: JSON.stringify({ refreshToken }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const data = await response.json();
      setTokens(data.accessToken, data.refreshToken);
    } catch (error) {
      console.error("Token refresh error:", error);
      logout(); // Optionally logout user if refresh fails
    }
  }, [refreshToken, token]);

  useEffect(() => {
    exportedAccessToken = token; // Export the access token
  }, [token]);

  useEffect(() => {
    console.log("AuthContext mounted");

    const storedToken = localStorage.getItem("accessToken");
    const storedRefreshToken = localStorage.getItem("refreshToken");
    const selectedBranch = localStorage.getItem("selectedBranch") as StoreBranch;

    if (storedToken && storedRefreshToken) {
      try {
        const decoded = jwtDecode<JwtPayload>(storedToken);
        const currentTime = Date.now() / 1000; // Current time in seconds

        if (decoded.exp < currentTime) {
          // Token is expired, attempt to refresh
          refreshAccessToken();
        } else {
          // Token is valid
          setAuthState((prevState) => ({
            ...prevState,
            token: storedToken,
            refreshToken: storedRefreshToken,
            permissions: decoded.permissions,
            isAuthenticated: true,
            user: {
              id: decoded.userId,
              firstName: decoded.firstName,
              lastName: decoded.lastName,
              branches: decoded.branches,
              defaultBranch: decoded.defaultBranch,
              selectedBranch: selectedBranch || decoded.defaultBranch,
            },
            loading: false,
          }));
        }
      } catch (error) {
        // Token is invalid
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        setAuthState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } else {
      // No token or refresh token found
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }

    // Set up a timer to refresh the token every 5 minutes
    const tokenRefreshInterval = setInterval(() => {
      refreshAccessToken();
    }, 300000); // 300,000 milliseconds = 5 minutes

    return () => {
      clearInterval(tokenRefreshInterval);
    };
  }, [refreshAccessToken]);

  console.log("Auth state:", authState);

  if (authState.loading) {
    return <CircularProgress size={500} />;
  }

  return (
    <AuthContext.Provider
      value={{ authState, login: setTokens, logout, setSelectedBranch }}
    >
      {children}
    </AuthContext.Provider>
  );
};
