/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderSource = {
    Website: 'WEBSITE',
    Store: 'STORE',
    Phone: 'PHONE'
} as const;
export type OrderSource = typeof OrderSource[keyof typeof OrderSource];


export function OrderSourceFromJSON(json: any): OrderSource {
    return OrderSourceFromJSONTyped(json, false);
}

export function OrderSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderSource {
    return json as OrderSource;
}

export function OrderSourceToJSON(value?: OrderSource | null): any {
    return value as any;
}

