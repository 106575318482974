import React from "react";
import { CustomerCreateForm } from "../../layout/CustomerCreateForm";
import { Box, Container, Typography } from "@mui/material";
import { usePhrases } from "../../../context/PhrasesContext";

export const CustomerCreatePage = () => {
  const { getPhrase } = usePhrases();

  return (
    <Container>
      <Box my={5}>
        <Typography component="h1" variant="h4">
          {getPhrase("CUSTOMER.CREATE_PAGE.TITLE")}
        </Typography>
      </Box>
      <Box>
        <CustomerCreateForm />
      </Box>
    </Container>
  );
};
