import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

interface PrivateRouteProps {
  children: React.ReactNode;
  requiredPermissions: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  requiredPermissions,
}) => {
  const { authState } = useContext(AuthContext);
  const location = useLocation();
  console.log("authState", authState);

  let hasPermission = false;
  if (requiredPermissions.length === 0) {
    hasPermission = true;
  } else {
    hasPermission = requiredPermissions.every((perm) =>
      authState.permissions.includes(perm)
    );
  }

  if (authState.isAuthenticated && hasPermission) {
    return <>{children}</>;
  } else if (authState.isAuthenticated && !hasPermission) {
    return <Navigate to="/unauthorized" state={{ from: location }} />;
  } else {
    return <Navigate to="/login" state={{ from: location }} />;
  }
};

export default PrivateRoute;
