import Joi from "joi";
import { Configuration, StoreBranch, UserApi } from "../generated-api";
import { getAccessToken } from "../utils/getAccessToken";
import { emailRegex } from "../constants/validation";

class UserService extends UserApi {
  constructor() {
    const config = new Configuration({
      accessToken: getAccessToken,
      basePath: process.env.REACT_APP_API_URL,
    });

    super(config);
  }
}

export const userService = new UserService();

export const USER_CREATE_INPUT_SCHEMA = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string().pattern(emailRegex).required().messages({
    "string.pattern.base": `"email" must be a valid email address`,
  }),
  password: Joi.string().required(),
  permissions: Joi.array().items(Joi.string()).required(),
  branches: Joi.array()
    .items(Joi.string().valid(...Object.values(StoreBranch)))
    .unique()
    .required(),
  defaultBranch: Joi.string()
    .valid(...Object.values(StoreBranch))
    .required(),

  // Confirm password is required and must match the password field
  confirmPassword: Joi.string().required().valid(Joi.ref("password")).messages({
    "any.only": `"confirmPassword" must match "password"`,
  }),
});

export const USER_UPDATE_INPUT_SCHEMA = Joi.object({
  firstName: Joi.string().optional(),
  lastName: Joi.string().optional(),
  email: Joi.string().pattern(emailRegex).optional().messages({
    "string.pattern.base": `"email" must be a valid email address`,
  }),
  password: Joi.string().allow("").optional(),
  permissions: Joi.array().items(Joi.string()).optional(),
  branches: Joi.array()
    .items(Joi.string().valid(...Object.values(StoreBranch)))
    .unique()
    .optional(),
  defaultBranch: Joi.string()
    .valid(...Object.values(StoreBranch))
    .optional(),

  // Confirm password is only required if the password input is different than "" (empty strings) and must match the password field
  confirmPassword: Joi.string().when("password", {
    is: Joi.string().not("").required(),
    then: Joi.required().valid(Joi.ref("password")),
    otherwise: Joi.string().optional().allow(""),
  }),
});
