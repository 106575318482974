import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { MainRouter } from "./routes/MainRouter";
import { PhrasesProvider } from "./context/PhrasesContext";
import phrases from "./constants/phrases.json";
import { SnackbarProvider } from "./context/SnackbarContext";

export const App: React.FC = () => {
  return (
    <PhrasesProvider phrases={phrases}>
      <SnackbarProvider>
        <AuthProvider>
          <Router>
            <MainRouter />
          </Router>
        </AuthProvider>
      </SnackbarProvider>
    </PhrasesProvider>
  );
};
