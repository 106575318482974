/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentMethod = {
    Cash: 'CASH',
    CreditBalance: 'CREDIT_BALANCE',
    CreditCardAmex: 'CREDIT_CARD_AMEX',
    CreditCardElo: 'CREDIT_CARD_ELO',
    CreditCardHipercard: 'CREDIT_CARD_HIPERCARD',
    CreditCardMastercard: 'CREDIT_CARD_MASTERCARD',
    CreditCardVisa: 'CREDIT_CARD_VISA',
    DebitCardAmex: 'DEBIT_CARD_AMEX',
    DebitCardElo: 'DEBIT_CARD_ELO',
    DebitCardHipercard: 'DEBIT_CARD_HIPERCARD',
    DebitCardMastercard: 'DEBIT_CARD_MASTERCARD',
    DebitCardVisa: 'DEBIT_CARD_VISA',
    Pix: 'PIX',
    Voucher: 'VOUCHER',
    Coupon: 'COUPON',
    Choppback: 'CHOPPBACK'
} as const;
export type PaymentMethod = typeof PaymentMethod[keyof typeof PaymentMethod];


export function PaymentMethodFromJSON(json: any): PaymentMethod {
    return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethod {
    return json as PaymentMethod;
}

export function PaymentMethodToJSON(value?: PaymentMethod | null): any {
    return value as any;
}

