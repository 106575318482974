/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductOutput } from './ProductOutput';
import {
    ProductOutputFromJSON,
    ProductOutputFromJSONTyped,
    ProductOutputToJSON,
} from './ProductOutput';

/**
 * 
 * @export
 * @interface PagedOutputProductOutput
 */
export interface PagedOutputProductOutput {
    /**
     * 
     * @type {Array<ProductOutput>}
     * @memberof PagedOutputProductOutput
     */
    data: Array<ProductOutput>;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputProductOutput
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputProductOutput
     */
    perPage: number;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputProductOutput
     */
    totalCount: number;
}

/**
 * Check if a given object implements the PagedOutputProductOutput interface.
 */
export function instanceOfPagedOutputProductOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "perPage" in value;
    isInstance = isInstance && "totalCount" in value;

    return isInstance;
}

export function PagedOutputProductOutputFromJSON(json: any): PagedOutputProductOutput {
    return PagedOutputProductOutputFromJSONTyped(json, false);
}

export function PagedOutputProductOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedOutputProductOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ProductOutputFromJSON)),
        'page': json['page'],
        'perPage': json['perPage'],
        'totalCount': json['totalCount'],
    };
}

export function PagedOutputProductOutputToJSON(value?: PagedOutputProductOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(ProductOutputToJSON)),
        'page': value.page,
        'perPage': value.perPage,
        'totalCount': value.totalCount,
    };
}

