/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Cities } from './Cities';
import {
    CitiesFromJSON,
    CitiesFromJSONTyped,
    CitiesToJSON,
} from './Cities';

/**
 * 
 * @export
 * @interface AddressUpdateInput
 */
export interface AddressUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateInput
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateInput
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateInput
     */
    neighborhood?: string;
    /**
     * 
     * @type {Cities}
     * @memberof AddressUpdateInput
     */
    city?: Cities;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateInput
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateInput
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUpdateInput
     */
    additionalInfo?: string;
}

/**
 * Check if a given object implements the AddressUpdateInput interface.
 */
export function instanceOfAddressUpdateInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddressUpdateInputFromJSON(json: any): AddressUpdateInput {
    return AddressUpdateInputFromJSONTyped(json, false);
}

export function AddressUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'neighborhood': !exists(json, 'neighborhood') ? undefined : json['neighborhood'],
        'city': !exists(json, 'city') ? undefined : CitiesFromJSON(json['city']),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'additionalInfo': !exists(json, 'additionalInfo') ? undefined : json['additionalInfo'],
    };
}

export function AddressUpdateInputToJSON(value?: AddressUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'street': value.street,
        'neighborhood': value.neighborhood,
        'city': CitiesToJSON(value.city),
        'state': value.state,
        'postalCode': value.postalCode,
        'additionalInfo': value.additionalInfo,
    };
}

