/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreBranch } from './StoreBranch';
import {
    StoreBranchFromJSON,
    StoreBranchFromJSONTyped,
    StoreBranchToJSON,
} from './StoreBranch';

/**
 * 
 * @export
 * @interface UserCreateInput
 */
export interface UserCreateInput {
    /**
     * 
     * @type {string}
     * @memberof UserCreateInput
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateInput
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateInput
     */
    password: string;
    /**
     * 
     * @type {StoreBranch}
     * @memberof UserCreateInput
     */
    defaultBranch: StoreBranch;
    /**
     * 
     * @type {Array<StoreBranch>}
     * @memberof UserCreateInput
     */
    branches: Array<StoreBranch>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCreateInput
     */
    permissions: Array<string>;
}

/**
 * Check if a given object implements the UserCreateInput interface.
 */
export function instanceOfUserCreateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "defaultBranch" in value;
    isInstance = isInstance && "branches" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function UserCreateInputFromJSON(json: any): UserCreateInput {
    return UserCreateInputFromJSONTyped(json, false);
}

export function UserCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'password': json['password'],
        'defaultBranch': StoreBranchFromJSON(json['defaultBranch']),
        'branches': ((json['branches'] as Array<any>).map(StoreBranchFromJSON)),
        'permissions': json['permissions'],
    };
}

export function UserCreateInputToJSON(value?: UserCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'password': value.password,
        'defaultBranch': StoreBranchToJSON(value.defaultBranch),
        'branches': ((value.branches as Array<any>).map(StoreBranchToJSON)),
        'permissions': value.permissions,
    };
}

