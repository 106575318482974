import { AnalyticsApi, Configuration } from "../generated-api";
import { getAccessToken } from "../utils/getAccessToken";

class AnalyticsService extends AnalyticsApi {
  constructor() {
    const config = new Configuration({
      accessToken: getAccessToken,
      basePath: process.env.REACT_APP_API_URL,
    });

    super(config);
  }
}

export const analyticsService = new AnalyticsService();
