import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePhrases } from "../../context/PhrasesContext";
import {
  CancellationReason,
  TransitionOrderStatusCanceledRequest,
  TransitionOrderStatusDeliveredRequest,
  UserOutput,
} from "../../generated-api";
import { userService } from "../../services/UserApi";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export enum DialogTemplate {
  Generic = "Generic",
  OrderCancellation = "OrderCancellation",
  OrderDelivered = "OrderDelivered",
}

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string;
  errorMessage?: string;
  template?: DialogTemplate;
  confirmDisabled?: boolean;
  onConfirm: (
    res:
      | TransitionOrderStatusCanceledRequest
      | TransitionOrderStatusDeliveredRequest
      | undefined
  ) => void;
  onClose: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  message,
  onConfirm,
  onClose,
  template = DialogTemplate.Generic,
  errorMessage,
  confirmDisabled,
}) => {
  const { getPhrase } = usePhrases();
  const [orderCancellationData, setOrderCancellationData] =
    useState<TransitionOrderStatusCanceledRequest>({
      reason: CancellationReason.Other,
      reasonDetails: "",
    });
  const [orderDeliveredData, setOrderDeliveredData] =
    useState<TransitionOrderStatusDeliveredRequest>({
      deliveredBy: "",
      deliveredAt: dayjs().toDate(),
    });
  const [users, setUsers] = useState<UserOutput[]>([]);

  const fetchUsers = useCallback(async () => {
    // TODO: fix inifite loop here
    if (template === DialogTemplate.OrderDelivered && users.length === 0) {
      try {
        // TODO: filter by department
        const users = await userService.getManyUsers({
          perPage: 1000,
        });
        setUsers(users.data);
        setOrderDeliveredData({
          ...orderDeliveredData,
          deliveredBy: users.data[0].id,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [orderDeliveredData, template, users.length]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleClose = () => {
    onClose();
  };

  const selectedUser = useMemo(
    () => users.find((user) => user.id === orderDeliveredData.deliveredBy),
    [users, orderDeliveredData.deliveredBy]
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {message && (
            <Grid item xs={12}>
              <DialogContentText id="alert-dialog-description">
                {message}
              </DialogContentText>
            </Grid>
          )}

          {errorMessage && (
            <Box mt={3} mx={2}>
              <Alert variant="filled" severity="error">
                <Typography variant="body1">{errorMessage}</Typography>
              </Alert>
            </Box>
          )}

          {template === DialogTemplate.OrderCancellation && (
            <Grid item container xs={12} spacing={1} mt={2}>
              <Grid item xs={12}>
                <TextField
                  label={getPhrase("ORDER_CANCELLATION_REASON")}
                  size="small"
                  select
                  fullWidth
                  value={orderCancellationData.reason}
                  onChange={(e) =>
                    setOrderCancellationData({
                      ...orderCancellationData,
                      reason: e.target.value as CancellationReason,
                    })
                  }
                >
                  {Object.values(CancellationReason).map((reason) => (
                    <MenuItem key={reason} value={reason}>
                      {getPhrase(`ORDER_CANCELLATION_REASON.${reason}`)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  rows={4}
                  label={getPhrase("ORDER_CANCELLATION_REASON_DETAILS")}
                  value={orderCancellationData.reasonDetails}
                  onChange={(e) =>
                    setOrderCancellationData({
                      ...orderCancellationData,
                      reasonDetails: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          )}

          {template === DialogTemplate.OrderDelivered && (
            <Grid item container xs={12} spacing={1} mt={2}>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={getPhrase("ORDER_DELIVERED_AT")}
                    value={dayjs(orderDeliveredData.deliveredAt)}
                    onChange={(date) => {
                      if (!date) {
                        return;
                      }
                      setOrderDeliveredData({
                        ...orderDeliveredData,
                        deliveredAt: date.toDate(),
                      });
                    }}
                    // format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  options={users}
                  getOptionLabel={(option) =>
                    option.firstName + " " + option.lastName
                  }
                  value={selectedUser}
                  onChange={(_, value) => {
                    setOrderDeliveredData({
                      ...orderDeliveredData,
                      deliveredBy: value?.id ?? "",
                    });
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={getPhrase("USER")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions sx={{ mx: 2, mb: 2 }}>
        <Button
          variant="outlined"
          color="error"
          onClick={handleClose}
          autoFocus
        >
          {getPhrase("CANCEL")}
        </Button>
        <Button
          variant="contained"
          disabled={confirmDisabled}
          onClick={() => {
            if (template === DialogTemplate.OrderCancellation) {
              onConfirm(orderCancellationData);
            } else if (template === DialogTemplate.OrderDelivered) {
              onConfirm(orderDeliveredData);
            } else {
              onConfirm(undefined);
            }

            handleClose();
          }}
        >
          {getPhrase("CONFIRM")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
