import React, { useContext } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, TextField, Container, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo1024.png";
import { AuthContext } from "../../context/AuthContext";
import { usePhrases } from "../../context/PhrasesContext";

interface IFormInput {
  email: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();
  const { getPhrase } = usePhrases();

  const onSubmit: SubmitHandler<IFormInput> = async ({ email, password }) => {
    const { REACT_APP_API_URL } = process.env;

    try {
      const response = await fetch(`${REACT_APP_API_URL}/v1/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error(getPhrase("LOGIN.PAGE.LOGIN_ERROR"));
      }

      const data = await response.json();
      login(data.accessToken, data.refreshToken);
      navigate("/"); // Redirect to home page
    } catch (error) {
      console.error(getPhrase("LOGIN.PAGE.LOGIN_ERROR_CONSOLE"), error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box my={5}>
          <img
            src={logo}
            height={256}
            alt={getPhrase("LOGIN.PAGE.LOGO_ALT_TEXT")}
          />
        </Box>
        <Typography component="h1" variant="h5">
          {getPhrase("LOGIN.PAGE.TITLE")}
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label={getPhrase("LOGIN.PAGE.EMAIL_ADDRESS")}
            autoComplete="email"
            autoFocus
            {...register("email", {
              required: getPhrase("LOGIN.PAGE.EMAIL_REQUIRED"),
              pattern: {
                value: /^\S+@\S+$/i,
                message: getPhrase("LOGIN.PAGE.INVALID_EMAIL_FORMAT"),
              },
            })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
          />
          <TextField
            margin="normal"
            fullWidth
            label={getPhrase("LOGIN.PAGE.PASSWORD")}
            type="password"
            id="password"
            autoComplete="current-password"
            {...register("password", {
              required: getPhrase("LOGIN.PAGE.PASSWORD_REQUIRED"),
            })}
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ""}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {getPhrase("LOGIN.PAGE.SIGN_IN_BUTTON")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
