/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipmentOutput } from './EquipmentOutput';
import {
    EquipmentOutputFromJSON,
    EquipmentOutputFromJSONTyped,
    EquipmentOutputToJSON,
} from './EquipmentOutput';

/**
 * 
 * @export
 * @interface PagedOutputEquipmentOutput
 */
export interface PagedOutputEquipmentOutput {
    /**
     * 
     * @type {Array<EquipmentOutput>}
     * @memberof PagedOutputEquipmentOutput
     */
    data: Array<EquipmentOutput>;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputEquipmentOutput
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputEquipmentOutput
     */
    perPage: number;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputEquipmentOutput
     */
    totalCount: number;
}

/**
 * Check if a given object implements the PagedOutputEquipmentOutput interface.
 */
export function instanceOfPagedOutputEquipmentOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "perPage" in value;
    isInstance = isInstance && "totalCount" in value;

    return isInstance;
}

export function PagedOutputEquipmentOutputFromJSON(json: any): PagedOutputEquipmentOutput {
    return PagedOutputEquipmentOutputFromJSONTyped(json, false);
}

export function PagedOutputEquipmentOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedOutputEquipmentOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(EquipmentOutputFromJSON)),
        'page': json['page'],
        'perPage': json['perPage'],
        'totalCount': json['totalCount'],
    };
}

export function PagedOutputEquipmentOutputToJSON(value?: PagedOutputEquipmentOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(EquipmentOutputToJSON)),
        'page': value.page,
        'perPage': value.perPage,
        'totalCount': value.totalCount,
    };
}

