import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  TableFooter,
  TablePagination,
  Typography,
  Box,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDebounce } from "use-debounce";

import { usePhrases } from "../../../context/PhrasesContext";
import { customerService } from "../../../services/CustomerApi";
import { CustomerMediaCategory, CustomerOutput } from "../../../generated-api";
import { omitBy } from "lodash";
import { useSnackbar } from "../../../context/SnackbarContext";
import { Chip } from "@mui/joy";
import {
  CustomerFilterDrawer,
  CustomerFilterDrawerCols,
} from "../../layout/CustomerFilterDrawer";
import { formatAddress } from "../../../utils/formatAddress";
import { formatDateTime } from "../../../utils/formatDateTime";

export const CustomerListPage: React.FC = () => {
  const [customers, setCustomers] = useState<CustomerOutput[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { getPhrase } = usePhrases();
  const { showMessage } = useSnackbar();

  // Search
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 1000);

  // dropdown menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null
  );

  // Columns settings - toggle visibility of columns
  const colsDefaults = {
    contacts: true,
    address: false,
    address2: false,
    dob: false,
    email: false,
    email2: false,
    media: false,
    phone: true,
    phone2: false,
  };
  const [cols, setCols] = useState<CustomerFilterDrawerCols>(colsDefaults);

  const fetchData = useCallback(async () => {
    try {
      const response = await customerService.getCustomers(
        omitBy(
          {
            page,
            perPage,
            search: debouncedSearch,
          },
          // remove undefined and empty strings
          (value) =>
            value === undefined ||
            (typeof value === "string" && value.trim() === "")
        )
      );
      setCustomers(response.data);
      setTotalCount(response.totalCount);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch data", error);
      showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
    }
  }, [page, perPage, debouncedSearch, showMessage, getPhrase]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
  };

  const handleReset = () => {
    // Implement search logic here using the searchValue
    setSearch("");
    fetchData();
    // Make sure to reset page to 1
    setPage(1);
  };

  const handleClick = (
    customerId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedCustomerId(customerId); // Set the selected customer ID
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewCustomer = () => {
    setAnchorEl(null);
    if (selectedCustomerId) {
      navigate(`/customers/${selectedCustomerId}`);
    }
  };

  const handleEditCustomer = () => {
    setAnchorEl(null);
    if (selectedCustomerId) {
      navigate(`/customers/${selectedCustomerId}/edit`);
    }
  };

  const handleCreateOrderForCustomer = () => {
    setAnchorEl(null);
    if (selectedCustomerId) {
      navigate(`/customers/${selectedCustomerId}/orders/create`);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mx={2}
        mt={2}
      >
        <Box mr={5}>
          <Typography variant="h5">
            {getPhrase("CUSTOMER.LIST_PAGE.TITLE")}
          </Typography>
        </Box>
        <TextField
          size="small"
          value={search}
          onChange={handleSearchChange}
          placeholder={getPhrase("CUSTOMER.LIST_PAGE.SEARCH_PLACEHOLDER")}
          fullWidth
          sx={{ mr: 1 }}
        />
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleReset}
          sx={{ whiteSpace: "nowrap" }} // Prevent text from wrapping
        >
          {getPhrase("CUSTOMER.LIST_PAGE.RESET_BUTTON")}
        </Button>

        <CustomerFilterDrawer
          cols={cols}
          setCols={setCols}
          defaults={colsDefaults}
        />

        <Button
          size="small"
          variant="contained"
          onClick={() => navigate("/customers/create")}
          startIcon={<AddCircleOutlineIcon />} // Add an icon to the button
          sx={{ whiteSpace: "nowrap", px: 5 }} // Prevent text from wrapping
        >
          {getPhrase("CUSTOMER.LIST_PAGE.CREATE_CUSTOMER")}
        </Button>
      </Box>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table
            className="tableSmallFontSize13"
            size="small"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "100px" }}>
                  {getPhrase("FIRST_NAME")}
                </TableCell>
                <TableCell sx={{ width: "150px" }}>
                  {getPhrase("LAST_NAME")}
                </TableCell>
                {cols.contacts && (
                  <TableCell sx={{ width: "600px" }}>
                    {getPhrase("CUSTOMER_CONTACTS")}
                  </TableCell>
                )}
                {cols.dob && (
                  <TableCell sx={{ width: "160px" }}>
                    {getPhrase("DOB")}
                  </TableCell>
                )}
                <TableCell sx={{ width: "130px" }}>
                  {getPhrase("CPF")}
                </TableCell>
                <TableCell sx={{ width: "160px" }}>
                  {getPhrase("CNPJ")}
                </TableCell>
                {cols.phone && (
                  <TableCell sx={{ width: "150px" }}>
                    {getPhrase("PHONE")}
                  </TableCell>
                )}
                {cols.phone2 && (
                  <TableCell sx={{ width: "150px" }}>
                    {getPhrase("SECONDARY_PHONE")}
                  </TableCell>
                )}
                {cols.email && (
                  <TableCell sx={{ width: "150px" }}>
                    {getPhrase("EMAIL")}
                  </TableCell>
                )}
                {cols.email2 && (
                  <TableCell sx={{ width: "150px" }}>
                    {getPhrase("SECONDARY_EMAIL")}
                  </TableCell>
                )}
                {cols.address && (
                  <TableCell sx={{ width: "300px" }}>
                    {getPhrase("ADDRESS")}
                  </TableCell>
                )}
                {cols.address2 && (
                  <TableCell sx={{ width: "300px" }}>
                    {getPhrase("SECONDARY_ADDRESS")}
                  </TableCell>
                )}
                {cols.media && (
                  <TableCell sx={{ width: "200px" }}>
                    {getPhrase("CUSTOMER_DOCUMENTS")}
                  </TableCell>
                )}
                <TableCell sx={{ width: "70px" }}>
                  {getPhrase("STATUS")}
                </TableCell>
                <TableCell sx={{ width: "100px" }}>
                  {getPhrase("CREATED_AT")}
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {getPhrase("ACTIONS")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => {
                const hasAllDocuments =
                  customer.media.filter(
                    (d) =>
                      d.customerMediaCategory ===
                      CustomerMediaCategory.ProofOfAddress
                  ).length > 0 &&
                  customer.media.filter(
                    (d) =>
                      d.customerMediaCategory ===
                      CustomerMediaCategory.ProofOfId
                  ).length > 0;

                return (
                  <TableRow hover key={customer.id}>
                    <TableCell>{customer.firstName}</TableCell>
                    <TableCell>{customer.lastName}</TableCell>
                    {cols.contacts && (
                      <TableCell>
                        <Box display={"flex"} flexWrap={"wrap"}>
                          {customer.contacts.map((contact, i) => (
                            <Chip
                              sx={{ m: 0, py: 0, ml: 0.25, mb: 0.25 }}
                              size="sm"
                              key={i}
                            >
                              {contact.firstName} {contact.lastName}:{" "}
                              {contact.phone}
                            </Chip>
                          ))}
                        </Box>
                      </TableCell>
                    )}
                    {cols.dob && (
                      <TableCell>
                        {customer.dob
                          ? formatDateTime(customer.dob)
                          : getPhrase("NOT_AVAIABLE")}
                      </TableCell>
                    )}
                    <TableCell>{customer.cpf}</TableCell>
                    <TableCell>{customer.cnpj}</TableCell>
                    {cols.phone && <TableCell>{customer.phone}</TableCell>}
                    {cols.phone2 && <TableCell>{customer.phone2}</TableCell>}
                    {cols.email && <TableCell>{customer.email}</TableCell>}
                    {cols.email2 && <TableCell>{customer.email2}</TableCell>}
                    {cols.address && (
                      <TableCell>{formatAddress(customer.address)}</TableCell>
                    )}
                    {cols.address2 && (
                      <TableCell>
                        {customer.address2
                          ? formatAddress(customer.address2)
                          : getPhrase("NOT_AVAIABLE")}
                      </TableCell>
                    )}
                    {cols.media && (
                      <TableCell>
                        <Box
                          display={"flex"}
                          flexWrap={"nowrap"}
                          alignItems={"center"}
                        >
                          <Chip
                            color={hasAllDocuments ? "primary" : "danger"}
                            sx={{ m: 0, py: 0, mr: 1 }}
                            title={customer.media
                              .map((d) => d.displayName)
                              .join(", ")}
                          >
                            {customer.media.length}
                          </Chip>
                          <Box>
                            {hasAllDocuments
                              ? getPhrase("CUSTOMER_HAS_ALL_DOCUMENTS")
                              : getPhrase("CUSTOMER_MISSING_DOCUMENTS_SHORT")}
                          </Box>
                        </Box>
                      </TableCell>
                    )}
                    <TableCell>
                      {customer.status === "ACTIVE"
                        ? getPhrase("ACTIVE")
                        : getPhrase("INACTIVE")}
                    </TableCell>
                    <TableCell>
                      {new Date(customer.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <Box display={"flex"} justifyContent={"end"}>
                        <IconButton
                          style={{ padding: 0 }}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleClick(customer.id, event)} // Pass the customer ID to the click handler
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={handleCreateOrderForCustomer}>
                            {getPhrase("CREATE_ORDER_FOR_CUSTOMER")}
                          </MenuItem>
                          <MenuItem onClick={handleViewCustomer}>
                            {getPhrase("VIEW_CUSTOMER")}
                          </MenuItem>
                          <MenuItem onClick={handleEditCustomer}>
                            {getPhrase("EDIT_CUSTOMER")}
                          </MenuItem>
                        </Menu>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="body2">
                    {getPhrase("CUSTOMER.LIST_PAGE.TOTAL_CUSTOMERS")}:{" "}
                    {totalCount}
                  </Typography>
                </TableCell>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={totalCount}
                  rowsPerPage={perPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
