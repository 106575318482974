/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StoreBranch = {
    PraiaGrande: 'PRAIA_GRANDE',
    Santos: 'SANTOS'
} as const;
export type StoreBranch = typeof StoreBranch[keyof typeof StoreBranch];


export function StoreBranchFromJSON(json: any): StoreBranch {
    return StoreBranchFromJSONTyped(json, false);
}

export function StoreBranchFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreBranch {
    return json as StoreBranch;
}

export function StoreBranchToJSON(value?: StoreBranch | null): any {
    return value as any;
}

