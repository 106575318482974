/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductCategory } from './ProductCategory';
import {
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';

/**
 * 
 * @export
 * @interface OrderDiscountOutput
 */
export interface OrderDiscountOutput {
    /**
     * 
     * @type {string}
     * @memberof OrderDiscountOutput
     */
    discountId: string;
    /**
     * 
     * @type {ProductCategory}
     * @memberof OrderDiscountOutput
     */
    productCategory: ProductCategory;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscountOutput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscountOutput
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscountOutput
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscountOutput
     */
    discounted: number;
}

/**
 * Check if a given object implements the OrderDiscountOutput interface.
 */
export function instanceOfOrderDiscountOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "discountId" in value;
    isInstance = isInstance && "productCategory" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "discounted" in value;

    return isInstance;
}

export function OrderDiscountOutputFromJSON(json: any): OrderDiscountOutput {
    return OrderDiscountOutputFromJSONTyped(json, false);
}

export function OrderDiscountOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDiscountOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountId': json['discountId'],
        'productCategory': ProductCategoryFromJSON(json['productCategory']),
        'name': json['name'],
        'type': json['type'],
        'value': json['value'],
        'discounted': json['discounted'],
    };
}

export function OrderDiscountOutputToJSON(value?: OrderDiscountOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discountId': value.discountId,
        'productCategory': ProductCategoryToJSON(value.productCategory),
        'name': value.name,
        'type': value.type,
        'value': value.value,
        'discounted': value.discounted,
    };
}

