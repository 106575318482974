/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SizeUnit = {
    Liter: 'LITER',
    Milliliter: 'MILLILITER'
} as const;
export type SizeUnit = typeof SizeUnit[keyof typeof SizeUnit];


export function SizeUnitFromJSON(json: any): SizeUnit {
    return SizeUnitFromJSONTyped(json, false);
}

export function SizeUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): SizeUnit {
    return json as SizeUnit;
}

export function SizeUnitToJSON(value?: SizeUnit | null): any {
    return value as any;
}

