/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderOutput } from './OrderOutput';
import {
    OrderOutputFromJSON,
    OrderOutputFromJSONTyped,
    OrderOutputToJSON,
} from './OrderOutput';

/**
 * 
 * @export
 * @interface PagedOutputOrderOutput
 */
export interface PagedOutputOrderOutput {
    /**
     * 
     * @type {Array<OrderOutput>}
     * @memberof PagedOutputOrderOutput
     */
    data: Array<OrderOutput>;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputOrderOutput
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputOrderOutput
     */
    perPage: number;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputOrderOutput
     */
    totalCount: number;
}

/**
 * Check if a given object implements the PagedOutputOrderOutput interface.
 */
export function instanceOfPagedOutputOrderOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "perPage" in value;
    isInstance = isInstance && "totalCount" in value;

    return isInstance;
}

export function PagedOutputOrderOutputFromJSON(json: any): PagedOutputOrderOutput {
    return PagedOutputOrderOutputFromJSONTyped(json, false);
}

export function PagedOutputOrderOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedOutputOrderOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(OrderOutputFromJSON)),
        'page': json['page'],
        'perPage': json['perPage'],
        'totalCount': json['totalCount'],
    };
}

export function PagedOutputOrderOutputToJSON(value?: PagedOutputOrderOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(OrderOutputToJSON)),
        'page': value.page,
        'perPage': value.perPage,
        'totalCount': value.totalCount,
    };
}

