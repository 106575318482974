import React, { useState } from "react";
import {
  Drawer,
  Button,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  OutlinedInput,
  Grid,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { OrderListFilters } from "../views/Order/OrderListPage";
import utc from "dayjs/plugin/utc";
import { usePhrases } from "../../context/PhrasesContext";
import { Cities, OrderStatus, OrderTags, OrderType } from "../../generated-api";
import { Chip } from "@mui/joy";
import { DrawerHeader } from "./Sidebar";

export const OrderFilterDrawer: React.FC<{
  filters: OrderListFilters;
  setFilters: React.Dispatch<React.SetStateAction<OrderListFilters>>;
}> = ({ filters, setFilters }) => {
  dayjs.extend(utc);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tempFilters, setTempFilters] = useState<OrderListFilters>(filters);
  const [filterErrors, setFilterErrors] = useState<{
    [key in keyof OrderListFilters]: string | null;
  }>({
    customerName: null,
    scheduledDate: null,
    status: null,
    type: null,
    tags: null,
    cities: null,
    neighborhood: null,
    key: null,
    returnDate: null,
  });
  const { getPhrase } = usePhrases();

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const handleFilterChange = (
    filterKey: keyof OrderListFilters,
    value: any
  ) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: {
        ...prevFilters[filterKey],
        ...value,
      },
    }));
    resetFilterErrorForField(filterKey);
  };

  const toggleFilterActive = (
    filterKey: keyof OrderListFilters,
    active: boolean
  ) => {
    handleFilterChange(filterKey, { ...tempFilters[filterKey], active });
    resetFilterErrorForField(filterKey);
  };

  const resetFilterErrorForField = (field: keyof OrderListFilters) => {
    setFilterErrors((prevErrors) => ({
      ...prevErrors,
      [field]: null,
    }));
  };

  const resetAllErrors = () => {
    setFilterErrors({
      customerName: null,
      scheduledDate: null,
      status: null,
      type: null,
      tags: null,
      cities: null,
      neighborhood: null,
      key: null,
      returnDate: null,
    });
  };

  const resetTempFilters = () => {
    setTempFilters({
      customerName: { active: false, value: "" },
      scheduledDate: {
        active: false,
        from: dayjs().startOf("day"),
        to: dayjs().startOf("day"),
      },
      status: { active: false, value: [] },
      type: { active: false, value: [] },
      tags: { active: false, value: [] },
      cities: { active: false, value: [] },
      neighborhood: { active: false, value: "" },
      key: { active: false, value: "" },
      returnDate: {
        active: false,
        from: dayjs().startOf("day"),
        to: dayjs().startOf("day"),
      },
    });
  };

  const handleResetAllFilters = () => {
    resetTempFilters();
    resetAllErrors();
  };

  const validateFilters = () => {
    const { scheduledDate } = tempFilters;
    let valid = true;

    // Validate scheduled date
    if (scheduledDate.active && scheduledDate.from > scheduledDate.to) {
      setFilterErrors((prevErrors) => ({
        ...prevErrors,
        scheduledDate: getPhrase("FROM_DATE_MUST_BE_BEFORE_TO_DATE"),
      }));
      valid = false;
    }
    // If scheduled date is active, both from and to dates must be selected
    if (scheduledDate.active && (!scheduledDate.from || !scheduledDate.to)) {
      setFilterErrors((prevErrors) => ({
        ...prevErrors,
        scheduledDate: getPhrase("PLEASE_SELECT_BOTH_FROM_AND_TO_DATES"),
      }));
      valid = false;
    }
    // CustomerName needs to be at least 3 characters
    if (
      tempFilters.customerName.active &&
      tempFilters.customerName.value.length < 3
    ) {
      setFilterErrors((prevErrors) => ({
        ...prevErrors,
        customerName: getPhrase("CUSTOMER_NAME_MUST_BE_AT_LEAST_3_CHARACTERS"),
      }));
      valid = false;
    }
    // Status needs to have at least one value selected
    if (tempFilters.status.active && tempFilters.status.value.length === 0) {
      setFilterErrors((prevErrors) => ({
        ...prevErrors,
        status: getPhrase("PLEASE_SELECT_AT_LEAST_ONE_STATUS"),
      }));
      valid = false;
    }
    // Type needs to have at least one value selected
    if (tempFilters.type.active && tempFilters.type.value.length === 0) {
      setFilterErrors((prevErrors) => ({
        ...prevErrors,
        type: getPhrase("PLEASE_SELECT_AT_LEAST_ONE_TYPE"),
      }));
      valid = false;
    }
    // Tags needs to have at least one value selected
    if (tempFilters.tags.active && tempFilters.tags.value.length === 0) {
      setFilterErrors((prevErrors) => ({
        ...prevErrors,
        tags: getPhrase("PLEASE_SELECT_AT_LEAST_ONE_TAG"),
      }));
      valid = false;
    }
    // City needs to have at least one value selected
    if (tempFilters.cities.active && tempFilters.cities.value.length === 0) {
      setFilterErrors((prevErrors) => ({
        ...prevErrors,
        cities: getPhrase("PLEASE_SELECT_AT_LEAST_ONE_CITY"),
      }));
      valid = false;
    }
    // Neighborhood needs to be at least 3 characters
    if (
      tempFilters.neighborhood.active &&
      tempFilters.neighborhood.value.length < 3
    ) {
      setFilterErrors((prevErrors) => ({
        ...prevErrors,
        neighborhood: getPhrase("NEIGHBORHOOD_MUST_BE_AT_LEAST_3_CHARACTERS"),
      }));
      valid = false;
    }
    // Key needs to follow the pattern <number 2 digits>-<number>
    if (tempFilters.key.active && !tempFilters.key.value.match(/^\d{2}-\d+$/)) {
      setFilterErrors((prevErrors) => ({
        ...prevErrors,
        key: getPhrase("ORDER_ID_NOT_VALID_FORMAT"),
      }));
      valid = false;
    }
    // If return date is active, both from and to dates must be selected
    if (
      tempFilters.returnDate.active &&
      (!tempFilters.returnDate.from || !tempFilters.returnDate.to)
    ) {
      setFilterErrors((prevErrors) => ({
        ...prevErrors,
        returnDate: getPhrase("PLEASE_SELECT_BOTH_FROM_AND_TO_DATES"),
      }));
      valid = false;
    }

    if (valid) {
      resetAllErrors();
    }

    return valid;
  };

  const applyFilters = () => {
    if (!validateFilters()) {
      return;
    }
    setFilters(tempFilters);
    setDrawerOpen(false);
  };

  return (
    <>
      <Button size="small" variant="contained" onClick={toggleDrawer(true)}>
        {getPhrase("VIEW_FILTERS")}
      </Button>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <DrawerHeader />
        <Box sx={{ width: 750, padding: 2 }}>
          <Typography mt={2} variant="h5" textAlign={"center"} sx={{ mb: 2 }}>
            {getPhrase("FILTERS")}
          </Typography>
          <FormGroup>
            <Grid container spacing={2}>
              {/* Customer Name Filter */}
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="medium"
                          checked={tempFilters.customerName.active}
                          onChange={(e) =>
                            toggleFilterActive("customerName", e.target.checked)
                          }
                        />
                      }
                      label={getPhrase("CUSTOMER_NAME")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={getPhrase("CUSTOMER_NAME")}
                    value={tempFilters.customerName.value}
                    onChange={(e) =>
                      handleFilterChange("customerName", {
                        active: tempFilters.customerName.active,
                        value: e.target.value,
                      })
                    }
                    disabled={!tempFilters.customerName.active}
                    fullWidth
                    error={!!filterErrors.customerName}
                    helperText={filterErrors.customerName}
                    size="small"
                  />
                </Grid>
              </Grid>

              {/* Scheduled Date Filter */}
              <Grid item container xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tempFilters.scheduledDate.active}
                          onChange={(e) =>
                            toggleFilterActive(
                              "scheduledDate",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label={getPhrase("SCHEDULED_DATE")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <DatePicker
                          label="From"
                          value={dayjs(tempFilters.scheduledDate.from)}
                          onChange={(newValue) =>
                            handleFilterChange("scheduledDate", {
                              active: tempFilters.scheduledDate.active,
                              from: newValue,
                            })
                          }
                          format="DD/MM/YYYY"
                          disabled={!tempFilters.scheduledDate.active}
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                        />
                        <Typography variant="h6">-</Typography>
                        <DatePicker
                          label="To"
                          value={dayjs(tempFilters.scheduledDate.to)}
                          onChange={(newValue) =>
                            handleFilterChange("scheduledDate", {
                              active: tempFilters.scheduledDate.active,
                              to: newValue,
                            })
                          }
                          minDate={dayjs(tempFilters.scheduledDate.from)}
                          format="DD/MM/YYYY"
                          disabled={!tempFilters.scheduledDate.active}
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                        />
                      </Box>
                      <FormHelperText error={!!filterErrors.scheduledDate}>
                        {filterErrors.scheduledDate &&
                          filterErrors.scheduledDate}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </LocalizationProvider>
              </Grid>

              {/* Return Date Filter */}
              <Grid item container xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tempFilters.returnDate.active}
                          onChange={(e) =>
                            toggleFilterActive("returnDate", e.target.checked)
                          }
                        />
                      }
                      label={getPhrase("RETURN_DATE")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <DatePicker
                          label="From"
                          value={dayjs(tempFilters.returnDate.from)}
                          onChange={(newValue) =>
                            handleFilterChange("returnDate", {
                              active: tempFilters.returnDate.active,
                              from: newValue,
                            })
                          }
                          format="DD/MM/YYYY"
                          disabled={!tempFilters.returnDate.active}
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                        />
                        <Typography variant="h6">-</Typography>
                        <DatePicker
                          label="To"
                          value={dayjs(tempFilters.returnDate.to)}
                          onChange={(newValue) =>
                            handleFilterChange("returnDate", {
                              active: tempFilters.returnDate.active,
                              to: newValue,
                            })
                          }
                          minDate={dayjs(tempFilters.returnDate.from)}
                          format="DD/MM/YYYY"
                          disabled={!tempFilters.returnDate.active}
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                        />
                      </Box>
                      <FormHelperText error={!!filterErrors.returnDate}>
                        {filterErrors.returnDate && filterErrors.returnDate}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </LocalizationProvider>
              </Grid>

              {/* City Filter */}
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tempFilters.cities.active}
                        onChange={(e) =>
                          toggleFilterActive("cities", e.target.checked)
                        }
                      />
                    }
                    label={getPhrase("ADDRESS_CITY")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      multiple
                      value={tempFilters.cities.value}
                      onChange={(event) =>
                        handleFilterChange("cities", {
                          value: event.target.value,
                        })
                      }
                      input={<OutlinedInput id="select-multiple-cities" />}
                      disabled={!tempFilters.cities.active}
                      error={!!filterErrors.cities}
                      size="small"
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value}>{value}</Chip>
                          ))}
                        </Box>
                      )}
                    >
                      {Object.values(Cities).map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={!!filterErrors.cities}>
                      {filterErrors.cities && filterErrors.cities}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Neighborhood Filter */}
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tempFilters.neighborhood.active}
                        onChange={(e) =>
                          toggleFilterActive("neighborhood", e.target.checked)
                        }
                      />
                    }
                    label={getPhrase("ADDRESS_NEIGHBORHOOD")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={getPhrase("ADDRESS_NEIGHBORHOOD")}
                    value={tempFilters.neighborhood.value}
                    onChange={(e) =>
                      handleFilterChange("neighborhood", {
                        active: tempFilters.neighborhood.active,
                        value: e.target.value,
                      })
                    }
                    disabled={!tempFilters.neighborhood.active}
                    fullWidth
                    error={!!filterErrors.neighborhood}
                    helperText={filterErrors.neighborhood}
                    size="small"
                  />
                </Grid>
              </Grid>

              {/* Key Filter */}
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tempFilters.key.active}
                        onChange={(e) =>
                          toggleFilterActive("key", e.target.checked)
                        }
                      />
                    }
                    label={getPhrase("ORDER_KEY")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={getPhrase("ORDER_KEY")}
                    value={tempFilters.key.value}
                    onChange={(e) =>
                      handleFilterChange("key", {
                        active: tempFilters.key.active,
                        value: e.target.value,
                      })
                    }
                    disabled={!tempFilters.key.active}
                    fullWidth
                    error={!!filterErrors.key}
                    helperText={filterErrors.key}
                    size="small"
                  />
                </Grid>
              </Grid>

              {/* Status Filter */}
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tempFilters.status.active}
                        onChange={(e) =>
                          toggleFilterActive("status", e.target.checked)
                        }
                      />
                    }
                    label={getPhrase("ORDER_STATUS")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      multiple
                      value={tempFilters.status.value}
                      onChange={(event) =>
                        handleFilterChange("status", {
                          value: event.target.value,
                        })
                      }
                      input={<OutlinedInput id="select-multiple-status" />}
                      disabled={!tempFilters.status.active}
                      error={!!filterErrors.status}
                      size="small"
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value}>
                              {getPhrase(`ORDER_STATUS.${value}` as any)}
                            </Chip>
                          ))}
                        </Box>
                      )}
                    >
                      {Object.values(OrderStatus).map((status) => (
                        <MenuItem key={status} value={status}>
                          {getPhrase(`ORDER_STATUS.${status}` as any)}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={!!filterErrors.status}>
                      {filterErrors.status && filterErrors.status}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Type Filter */}
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tempFilters.type.active}
                        onChange={(e) =>
                          toggleFilterActive("type", e.target.checked)
                        }
                      />
                    }
                    label={getPhrase("ORDER_TYPE")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      multiple
                      value={tempFilters.type.value}
                      onChange={(event) =>
                        handleFilterChange("type", {
                          value: event.target.value,
                        })
                      }
                      input={<OutlinedInput id="select-multiple-status" />}
                      disabled={!tempFilters.type.active}
                      error={!!filterErrors.type}
                      size="small"
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value}>
                               {getPhrase(`ORDER_TYPE.${value}` as any)}
                            </Chip>
                          ))}
                        </Box>
                      )}
                    >
                      {Object.values(OrderType).map((type) => (
                        <MenuItem key={type} value={type}>
                          {getPhrase(`ORDER_TYPE.${type}` as any)}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={!!filterErrors.type}>
                      {filterErrors.type && filterErrors.type}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Tags Filter */}
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tempFilters.tags.active}
                        onChange={(e) =>
                          toggleFilterActive("tags", e.target.checked)
                        }
                      />
                    }
                    label={getPhrase("ORDER_TAGS")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      multiple
                      value={tempFilters.tags.value}
                      onChange={(event) =>
                        handleFilterChange("tags", {
                          value: event.target.value,
                        })
                      }
                      input={<OutlinedInput id="select-multiple-tags" />}
                      disabled={!tempFilters.tags.active}
                      error={!!filterErrors.tags}
                      size="small"
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value}>{value}</Chip>
                          ))}
                        </Box>
                      )}
                    >
                      {Object.values(OrderTags).map((tag) => (
                        <MenuItem key={tag} value={tag}>
                          {tag}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={!!filterErrors.tags}>
                      {filterErrors.tags && filterErrors.tags}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </FormGroup>

          <Box display={"flex"} mt={5} justifyContent={"space-around"}>
            <Box width={"25%"}>
              <Button fullWidth variant="contained" onClick={applyFilters}>
                {getPhrase("APPLY_FILTERS")}
              </Button>
            </Box>
            <Box width={"25%"}>
              <Button
                fullWidth
                color="error"
                variant="outlined"
                onClick={handleResetAllFilters}
              >
                {getPhrase("RESET_FILTERS")}
              </Button>
            </Box>
            <Box width={"40%"}>
              <Button
                fullWidth
                // color="error"
                variant="outlined"
                onClick={toggleDrawer(false)}
              >
                {getPhrase("CLOSE_AND_NOT_APPLY")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
