import React from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  MenuItem,
} from "@mui/material";
import { Cities, CustomerCreateInput } from "../../generated-api";
import { removeFalsy } from "../../utils/removeFalsy";
import { usePhrases } from "../../context/PhrasesContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { useNavigate } from "react-router-dom";
import {
  addressValidationSchema,
  customerCreateValidationSchema,
  customerService,
} from "../../services/CustomerApi";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const CustomerCreateForm: React.FC = () => {
  const { showMessage } = useSnackbar(); // Use the showMessage function
  const navigate = useNavigate(); // Use the navigate function
  const { getPhrase } = usePhrases(); // Use the getPhrase function

  const defaultValues: CustomerCreateInput = {
    firstName: "",
    lastName: "",
    cpf: undefined,
    cnpj: undefined,
    email: "",
    email2: undefined,
    phone: "",
    phone2: undefined,
    address: {
      neighborhood: "",
      number: "",
      street: "",
      city: Cities.Santos,
      state: "",
      postalCode: "",
      additionalInfo: "",
    },
    contacts: [{ firstName: "", lastName: "", phone: "" }],
    address2: {
      neighborhood: "",
      number: "",
      street: "",
      city: "" as any,
      state: "",
      postalCode: "",
      additionalInfo: "",
    }, // Set as undefined
    notes: "",
    dob: dayjs("1990-01-01").toDate(), // Set default date of birth
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<CustomerCreateInput>({
    resolver: joiResolver(customerCreateValidationSchema),
    defaultValues,
  });

  const {
    fields,
    append: appendCustomerContact,
    remove: removeCustomerContact,
  } = useFieldArray({
    control,
    name: "contacts",
  });

  const onSubmit: SubmitHandler<CustomerCreateInput> = async (data) => {
    console.log("Submitting form data:", data);
    try {
      // Remove empty strings, nulls, and empty objects
      const cleanedData: CustomerCreateInput = removeFalsy(data);

      // Determine if any address2 field is filled
      const isAddress2Filled =
        Object.values(data.address2 ?? {}).filter((v) => !!v).length > 0;
      if (!isAddress2Filled) {
        delete cleanedData.address2;
      } else {
        const { error } = addressValidationSchema.validate(
          cleanedData.address2,
          {
            abortEarly: false,
          }
        );
        // add errors to the form
        if (error) {
          // Map Joi errors to react-hook-form errors and set them
          error.details.forEach((detail) => {
            setError(`address2.${detail.path[0]}` as unknown as any, {
              type: "manual",
              message: detail.message,
            });
          });
          // Stop the submission if there are errors
          return;
        }
      }

      console.log("Creating customer:\n", JSON.stringify(cleanedData, null, 2));
      const createdCustomer = await customerService.createCustomer({
        customerCreateInput: cleanedData,
      });
      console.log("Customer created:", createdCustomer);

      showMessage(getPhrase("CUSTOMER.CREATE_PAGE.SUCCESS"), "success");
      navigate(`/customers/${createdCustomer.id}`); // Redirect to customer page
    } catch (error) {
      console.error("Unexpected error when creating customer:", error);
      showMessage(getPhrase("CUSTOMER.CREATE_PAGE.UNEXPECTED_ERROR"), "error");
    }
  };

  console.log("errors:", errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {/* First Name */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("FIRST_NAME")}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
            )}
          />
        </Grid>

        {/* Last Name */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("LAST_NAME")}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
            )}
          />
        </Grid>

        {/* Date of Birth */}
        <Grid item xs={12} sm={2}>
          <Controller
            name="dob"
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={getPhrase("DOB")}
                  value={dayjs(field.value)}
                  format="DD/MM/YYYY"
                  onChange={(date) => field.onChange(date?.toDate())}
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
                {!!errors.dob && (
                  <Typography component="p" color={"red"}>
                    {errors.dob?.message}
                  </Typography>
                )}
              </LocalizationProvider>
            )}
          />
        </Grid>

        {/* CPF */}
        <Grid item xs={12} sm={5}>
          <Controller
            name="cpf"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("CPF")}
                error={!!errors.cpf}
                helperText={errors.cpf?.message}
              />
            )}
          />
        </Grid>

        {/* CNPJ */}
        <Grid item xs={12} sm={5}>
          <Controller
            name="cnpj"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("CNPJ")}
                error={!!errors.cnpj}
                helperText={errors.cnpj?.message}
              />
            )}
          />
        </Grid>

        {/* Email */}
        <Grid item xs={6}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("EMAIL")}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
        </Grid>

        {/* Email2 */}
        <Grid item xs={6}>
          <Controller
            name="email2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("SECONDARY_EMAIL")}
                error={!!errors.email2}
                helperText={errors.email2?.message}
              />
            )}
          />
        </Grid>

        {/* Phone */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("PHONE")}
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            )}
          />
        </Grid>

        {/* Phone2 */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="phone2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("SECONDARY_PHONE")}
                error={!!errors.phone2}
                helperText={errors.phone2?.message}
              />
            )}
          />
        </Grid>

        {/* Notes */}
        <Grid item xs={12}>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                label={getPhrase("NOTES")}
                error={!!errors.notes}
                helperText={errors.notes?.message}
              />
            )}
          />
        </Grid>

        {/* Address Fields */}
        <Grid item xs={12}>
          <Typography variant="h6">{getPhrase("PRIMARY_ADDRESS")}</Typography>
        </Grid>

        {/* Address: Number */}
        <Grid item xs={3}>
          <Controller
            name="address.number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_NUMBER")}
                error={!!errors.address?.number}
                helperText={errors.address?.number?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Street */}
        <Grid item xs={9}>
          <Controller
            name="address.street"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_STREET")}
                error={!!errors.address?.street}
                helperText={errors.address?.street?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Neighborhood */}
        <Grid item xs={6}>
          <Controller
            name="address.neighborhood"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_NEIGHBORHOOD")}
                error={!!errors.address?.neighborhood}
                helperText={errors.address?.neighborhood?.message}
              />
            )}
          />
        </Grid>

        {/* Address: City */}
        <Grid item xs={6}>
          <Controller
            name="address.city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                select
                label={getPhrase("ADDRESS_CITY")}
                variant="outlined"
                fullWidth
                error={!!errors.address?.city}
                helperText={errors.address?.city?.message}
              >
                {/* Map OrderType enum to menu items */}
                {Object.values(Cities).map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        {/* Address: State */}
        <Grid item xs={6}>
          <Controller
            name="address.state"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_STATE")}
                error={!!errors.address?.state}
                helperText={errors.address?.state?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Postal Code */}
        <Grid item xs={6}>
          <Controller
            name="address.postalCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_POSTAL_CODE")}
                error={!!errors.address?.postalCode}
                helperText={errors.address?.postalCode?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Additional Info */}
        <Grid item xs={12}>
          <Controller
            name="address.additionalInfo"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                label={getPhrase("ADDITIONAL_INFO")}
                error={!!errors.address?.additionalInfo}
                helperText={errors.address?.additionalInfo?.message}
              />
            )}
          />
        </Grid>

        {/* Secondary Address Fields */}
        <Grid item xs={12}>
          <Typography variant="h6">{getPhrase("SECONDARY_ADDRESS")}</Typography>
        </Grid>

        {/* Address: Number */}
        <Grid item xs={3}>
          <Controller
            name="address2.number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_NUMBER")}
                error={!!errors.address2?.number}
                helperText={errors.address2?.number?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Street */}
        <Grid item xs={9}>
          <Controller
            name="address2.street"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_STREET")}
                error={!!errors.address2?.street}
                helperText={errors.address2?.street?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Neighborhood */}
        <Grid item xs={6}>
          <Controller
            name="address2.neighborhood"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_NEIGHBORHOOD")}
                error={!!errors.address2?.neighborhood}
                helperText={errors.address2?.neighborhood?.message}
              />
            )}
          />
        </Grid>

        {/* Address: City */}
        <Grid item xs={6}>
          <Controller
            name="address2.city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                select
                label={getPhrase("ADDRESS_CITY")}
                variant="outlined"
                fullWidth
                error={!!errors.address2?.city}
                helperText={errors.address2?.city?.message}
              >
                <MenuItem value={""}>{getPhrase("SELECT_CITY")}</MenuItem>
                {/* Map OrderType enum to menu items */}
                {Object.values(Cities).map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        {/* Address: State */}
        <Grid item xs={6}>
          <Controller
            name="address2.state"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_STATE")}
                error={!!errors.address2?.state}
                helperText={errors.address2?.state?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Postal Code */}
        <Grid item xs={6}>
          <Controller
            name="address2.postalCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_POSTAL_CODE")}
                error={!!errors.address2?.postalCode}
                helperText={errors.address2?.postalCode?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Additional Info */}
        <Grid item xs={12}>
          <Controller
            name="address2.additionalInfo"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                label={getPhrase("ADDITIONAL_INFO")}
                error={!!errors.address2?.additionalInfo}
                helperText={errors.address2?.additionalInfo?.message}
              />
            )}
          />
        </Grid>

        <Grid container item rowSpacing={2} columnSpacing={2} xs={12}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">
                {getPhrase("ADDITIONAL_CONTACTS")}
              </Typography>
              {/* Button to Add Item */}
              <Button
                size="small"
                variant="contained"
                onClick={() =>
                  appendCustomerContact({
                    firstName: "",
                    lastName: "",
                    phone: "",
                  })
                }
              >
                {getPhrase("ADD_CONTACT")}
              </Button>
            </Box>
          </Grid>
          {fields.map((field, index) => (
            <Grid
              item
              container
              columnSpacing={1}
              rowSpacing={2}
              alignItems="center"
              key={field.id}
            >
              <Grid item xs={3}>
                <Controller
                  name={`contacts.${index}.firstName`}
                  control={control}
                  defaultValue={field.firstName}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label={getPhrase("FIRST_NAME")}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={`contacts.${index}.lastName`}
                  control={control}
                  defaultValue={field.lastName}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label={getPhrase("LAST_NAME")}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name={`contacts.${index}.phone`}
                  control={control}
                  defaultValue={field.phone}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label={getPhrase("PHONE")}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid display={"flex"} justifyContent={"end"} item xs={2}>
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={() => removeCustomerContact(index)}
                >
                  {getPhrase("REMOVE_CONTACT")}
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Box mt={5} display={"flex"} justifyContent={"flex-end"}>
        <Box mr={2} width={"25%"}>
          <Button
            size="small"
            type="submit"
            fullWidth
            variant="outlined"
            color="error"
          >
            {getPhrase("CANCEL")}
          </Button>
        </Box>
        <Box width={"25%"}>
          <Button
            size="small"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            {getPhrase("CUSTOMER.CREATE_PAGE.SUBMIT_BUTTON")}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
