/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipmentCategory } from './EquipmentCategory';
import {
    EquipmentCategoryFromJSON,
    EquipmentCategoryFromJSONTyped,
    EquipmentCategoryToJSON,
} from './EquipmentCategory';
import type { EquipmentStatus } from './EquipmentStatus';
import {
    EquipmentStatusFromJSON,
    EquipmentStatusFromJSONTyped,
    EquipmentStatusToJSON,
} from './EquipmentStatus';

/**
 * 
 * @export
 * @interface EquipmentOutput
 */
export interface EquipmentOutput {
    /**
     * 
     * @type {string}
     * @memberof EquipmentOutput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentOutput
     */
    name: string;
    /**
     * 
     * @type {EquipmentCategory}
     * @memberof EquipmentOutput
     */
    category: EquipmentCategory;
    /**
     * 
     * @type {EquipmentStatus}
     * @memberof EquipmentOutput
     */
    status: EquipmentStatus;
    /**
     * 
     * @type {string}
     * @memberof EquipmentOutput
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof EquipmentOutput
     */
    pricePerUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentOutput
     */
    referenceCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof EquipmentOutput
     */
    archivedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EquipmentOutput
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EquipmentOutput
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the EquipmentOutput interface.
 */
export function instanceOfEquipmentOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function EquipmentOutputFromJSON(json: any): EquipmentOutput {
    return EquipmentOutputFromJSONTyped(json, false);
}

export function EquipmentOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'category': EquipmentCategoryFromJSON(json['category']),
        'status': EquipmentStatusFromJSON(json['status']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'pricePerUnit': !exists(json, 'pricePerUnit') ? undefined : json['pricePerUnit'],
        'referenceCode': !exists(json, 'referenceCode') ? undefined : json['referenceCode'],
        'archivedAt': !exists(json, 'archivedAt') ? undefined : (new Date(json['archivedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function EquipmentOutputToJSON(value?: EquipmentOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'category': EquipmentCategoryToJSON(value.category),
        'status': EquipmentStatusToJSON(value.status),
        'description': value.description,
        'pricePerUnit': value.pricePerUnit,
        'referenceCode': value.referenceCode,
        'archivedAt': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

