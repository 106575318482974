/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancellationReason } from './CancellationReason';
import {
    CancellationReasonFromJSON,
    CancellationReasonFromJSONTyped,
    CancellationReasonToJSON,
} from './CancellationReason';

/**
 * 
 * @export
 * @interface TransitionOrderStatusCanceledRequest
 */
export interface TransitionOrderStatusCanceledRequest {
    /**
     * 
     * @type {string}
     * @memberof TransitionOrderStatusCanceledRequest
     */
    reasonDetails: string;
    /**
     * 
     * @type {CancellationReason}
     * @memberof TransitionOrderStatusCanceledRequest
     */
    reason: CancellationReason;
}

/**
 * Check if a given object implements the TransitionOrderStatusCanceledRequest interface.
 */
export function instanceOfTransitionOrderStatusCanceledRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reasonDetails" in value;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function TransitionOrderStatusCanceledRequestFromJSON(json: any): TransitionOrderStatusCanceledRequest {
    return TransitionOrderStatusCanceledRequestFromJSONTyped(json, false);
}

export function TransitionOrderStatusCanceledRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransitionOrderStatusCanceledRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reasonDetails': json['reasonDetails'],
        'reason': CancellationReasonFromJSON(json['reason']),
    };
}

export function TransitionOrderStatusCanceledRequestToJSON(value?: TransitionOrderStatusCanceledRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reasonDetails': value.reasonDetails,
        'reason': CancellationReasonToJSON(value.reason),
    };
}

