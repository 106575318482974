import {
  CustomerContactCreateInput,
  CustomerContactOutput,
  CustomerContactUpdateInput,
} from "../generated-api";

export const getContactUniqueKey = (
  c:
    | CustomerContactCreateInput
    | CustomerContactUpdateInput
    | CustomerContactOutput
) => `${c.firstName}_${c.lastName}_${c.phone}`.replace(/\s+/g, "");
