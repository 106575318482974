/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductCategory = {
    Chopp: 'CHOPP',
    Beer: 'BEER',
    Liquor: 'LIQUOR',
    SoftDrink: 'SOFT_DRINK',
    Other: 'OTHER'
} as const;
export type ProductCategory = typeof ProductCategory[keyof typeof ProductCategory];


export function ProductCategoryFromJSON(json: any): ProductCategory {
    return ProductCategoryFromJSONTyped(json, false);
}

export function ProductCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCategory {
    return json as ProductCategory;
}

export function ProductCategoryToJSON(value?: ProductCategory | null): any {
    return value as any;
}

