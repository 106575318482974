/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderMediaCategory = {
    Receipt: 'RECEIPT',
    ProofOfPayment: 'PROOF_OF_PAYMENT',
    ProofOfDelivery: 'PROOF_OF_DELIVERY',
    Other: 'OTHER'
} as const;
export type OrderMediaCategory = typeof OrderMediaCategory[keyof typeof OrderMediaCategory];


export function OrderMediaCategoryFromJSON(json: any): OrderMediaCategory {
    return OrderMediaCategoryFromJSONTyped(json, false);
}

export function OrderMediaCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderMediaCategory {
    return json as OrderMediaCategory;
}

export function OrderMediaCategoryToJSON(value?: OrderMediaCategory | null): any {
    return value as any;
}

