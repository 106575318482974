/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountType } from './DiscountType';
import {
    DiscountTypeFromJSON,
    DiscountTypeFromJSONTyped,
    DiscountTypeToJSON,
} from './DiscountType';
import type { ProductCategory } from './ProductCategory';
import {
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';

/**
 * 
 * @export
 * @interface DiscountCreateInput
 */
export interface DiscountCreateInput {
    /**
     * 
     * @type {string}
     * @memberof DiscountCreateInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountCreateInput
     */
    description?: string;
    /**
     * 
     * @type {DiscountType}
     * @memberof DiscountCreateInput
     */
    type: DiscountType;
    /**
     * 
     * @type {number}
     * @memberof DiscountCreateInput
     */
    value: number;
    /**
     * 
     * @type {ProductCategory}
     * @memberof DiscountCreateInput
     */
    productCategory: ProductCategory;
}

/**
 * Check if a given object implements the DiscountCreateInput interface.
 */
export function instanceOfDiscountCreateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "productCategory" in value;

    return isInstance;
}

export function DiscountCreateInputFromJSON(json: any): DiscountCreateInput {
    return DiscountCreateInputFromJSONTyped(json, false);
}

export function DiscountCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': DiscountTypeFromJSON(json['type']),
        'value': json['value'],
        'productCategory': ProductCategoryFromJSON(json['productCategory']),
    };
}

export function DiscountCreateInputToJSON(value?: DiscountCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'type': DiscountTypeToJSON(value.type),
        'value': value.value,
        'productCategory': ProductCategoryToJSON(value.productCategory),
    };
}

