import { Box, Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { analyticsService } from "../../../services/AnalyticsApi";
import { AuthContext } from "../../../context/AuthContext";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { usePhrases } from "../../../context/PhrasesContext";

export const OrderSummarizedDataByDay: React.FC = () => {
  dayjs.extend(require("dayjs/plugin/utc"));
  const [data, setData] = useState<any>(null);
  const { authState } = useContext(AuthContext);
  const [filters, setFilter] = useState({
    from: dayjs().toDate(),
    to: dayjs().toDate(),
  });
  const { getPhrase } = usePhrases();

  const branch = authState.user?.selectedBranch;

  useEffect(() => {
    if (filters.from && filters.to) {
      analyticsService
        .getSummarizedOrdersDataByDay({
          scheduledDateGte: filters.from,
          scheduledDateLte: filters.to,
          branch,
        })
        .then((data) => {
          setData(data);
        });
    }
  }, [branch, filters]);

  return (
    <Container>
      <Box my={5}>
        <Typography variant="h4">
          {getPhrase("ANALYTICS_PAGE_SUMMARIZED_ORDER_DATA_BY_DAY")}
        </Typography>
      </Box>
      <Box display={"flex"} alignItems={"center"} justifyContent={"start"}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From"
            value={dayjs(filters.from)}
            onChange={(newValue) =>
              setFilter({ ...filters, from: dayjs(newValue).toDate() })
            }
            format="DD/MM/YYYY"
          />
          <Box mx={5}>
            <Typography variant="h6">-</Typography>
          </Box>
          <DatePicker
            label="To"
            value={dayjs(filters.to)}
            onChange={(newValue) =>
              setFilter({ ...filters, to: dayjs(newValue).toDate() })
            }
            minDate={dayjs(filters.from)}
            format="DD/MM/YYYY"
          />
        </LocalizationProvider>
      </Box>
      <Box>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Box>
    </Container>
  );
};
