import Joi from "joi";
import {
  Configuration,
  ProductApi,
  ProductCategory,
  SizeUnit,
} from "../generated-api";
import { getAccessToken } from "../utils/getAccessToken";

class ProductService extends ProductApi {
  constructor() {
    const config = new Configuration({
      accessToken: getAccessToken,
      basePath: process.env.REACT_APP_API_URL,
    });

    super(config);
  }
}

export const productService = new ProductService();

export const PRODUCT_UPDATE_INPUT_SCHEMA = Joi.object({
  name: Joi.string().trim().optional(),
  description: Joi.string().trim().optional(),
  price: Joi.number().min(0).optional(),
  size: Joi.number().min(0).optional(),
  sizeUnit: Joi.string()
    .valid(...Object.values(SizeUnit))
    .optional(),
  category: Joi.string()
    .valid(...Object.values(ProductCategory))
    .optional(),
});

export const PRODUCT_CREATE_INPUT_SCHEMA = Joi.object({
  name: Joi.string().trim().required(),
  description: Joi.string().trim().required(),
  price: Joi.number().min(0).required(),
  size: Joi.number().min(0).required(),
  sizeUnit: Joi.string()
    .valid(...Object.values(SizeUnit))
    .required(),
  category: Joi.string()
    .valid(...Object.values(ProductCategory))
    .required(),
});
