/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressOutput } from './AddressOutput';
import {
    AddressOutputFromJSON,
    AddressOutputFromJSONTyped,
    AddressOutputToJSON,
} from './AddressOutput';
import type { CustomerOutput } from './CustomerOutput';
import {
    CustomerOutputFromJSON,
    CustomerOutputFromJSONTyped,
    CustomerOutputToJSON,
} from './CustomerOutput';
import type { OrderContactOutput } from './OrderContactOutput';
import {
    OrderContactOutputFromJSON,
    OrderContactOutputFromJSONTyped,
    OrderContactOutputToJSON,
} from './OrderContactOutput';
import type { OrderDiscountOutput } from './OrderDiscountOutput';
import {
    OrderDiscountOutputFromJSON,
    OrderDiscountOutputFromJSONTyped,
    OrderDiscountOutputToJSON,
} from './OrderDiscountOutput';
import type { OrderEquipmentOutput } from './OrderEquipmentOutput';
import {
    OrderEquipmentOutputFromJSON,
    OrderEquipmentOutputFromJSONTyped,
    OrderEquipmentOutputToJSON,
} from './OrderEquipmentOutput';
import type { OrderItemOutput } from './OrderItemOutput';
import {
    OrderItemOutputFromJSON,
    OrderItemOutputFromJSONTyped,
    OrderItemOutputToJSON,
} from './OrderItemOutput';
import type { OrderMediaOutput } from './OrderMediaOutput';
import {
    OrderMediaOutputFromJSON,
    OrderMediaOutputFromJSONTyped,
    OrderMediaOutputToJSON,
} from './OrderMediaOutput';
import type { OrderMetadataOutput } from './OrderMetadataOutput';
import {
    OrderMetadataOutputFromJSON,
    OrderMetadataOutputFromJSONTyped,
    OrderMetadataOutputToJSON,
} from './OrderMetadataOutput';
import type { OrderPaymentOutput } from './OrderPaymentOutput';
import {
    OrderPaymentOutputFromJSON,
    OrderPaymentOutputFromJSONTyped,
    OrderPaymentOutputToJSON,
} from './OrderPaymentOutput';
import type { OrderSource } from './OrderSource';
import {
    OrderSourceFromJSON,
    OrderSourceFromJSONTyped,
    OrderSourceToJSON,
} from './OrderSource';
import type { OrderStatus } from './OrderStatus';
import {
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';
import type { OrderTags } from './OrderTags';
import {
    OrderTagsFromJSON,
    OrderTagsFromJSONTyped,
    OrderTagsToJSON,
} from './OrderTags';
import type { StoreBranch } from './StoreBranch';
import {
    StoreBranchFromJSON,
    StoreBranchFromJSONTyped,
    StoreBranchToJSON,
} from './StoreBranch';
import type { TimeOfDay } from './TimeOfDay';
import {
    TimeOfDayFromJSON,
    TimeOfDayFromJSONTyped,
    TimeOfDayToJSON,
} from './TimeOfDay';

/**
 * 
 * @export
 * @interface OrderOutput
 */
export interface OrderOutput {
    /**
     * 
     * @type {string}
     * @memberof OrderOutput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOutput
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOutput
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOutput
     */
    cpf?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOutput
     */
    cnpj?: string;
    /**
     * 
     * @type {AddressOutput}
     * @memberof OrderOutput
     */
    deliveryAddress: AddressOutput;
    /**
     * 
     * @type {string}
     * @memberof OrderOutput
     */
    nf?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOutput
     */
    siteOrderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderOutput
     */
    type: string;
    /**
     * 
     * @type {StoreBranch}
     * @memberof OrderOutput
     */
    branch: StoreBranch;
    /**
     * 
     * @type {OrderSource}
     * @memberof OrderOutput
     */
    source: OrderSource;
    /**
     * 
     * @type {Array<OrderTags>}
     * @memberof OrderOutput
     */
    tags: Array<OrderTags>;
    /**
     * 
     * @type {Date}
     * @memberof OrderOutput
     */
    scheduledDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderOutput
     */
    returnDate?: Date;
    /**
     * 
     * @type {TimeOfDay}
     * @memberof OrderOutput
     */
    timeOfDay: TimeOfDay;
    /**
     * 
     * @type {string}
     * @memberof OrderOutput
     */
    notes?: string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderOutput
     */
    status: OrderStatus;
    /**
     * 
     * @type {Array<OrderContactOutput>}
     * @memberof OrderOutput
     */
    contacts: Array<OrderContactOutput>;
    /**
     * 
     * @type {OrderMetadataOutput}
     * @memberof OrderOutput
     */
    metadata: OrderMetadataOutput;
    /**
     * 
     * @type {Array<OrderMediaOutput>}
     * @memberof OrderOutput
     */
    media: Array<OrderMediaOutput>;
    /**
     * 
     * @type {Array<OrderItemOutput>}
     * @memberof OrderOutput
     */
    items: Array<OrderItemOutput>;
    /**
     * 
     * @type {number}
     * @memberof OrderOutput
     */
    itemsTotal: number;
    /**
     * 
     * @type {Array<OrderEquipmentOutput>}
     * @memberof OrderOutput
     */
    orderEquipment: Array<OrderEquipmentOutput>;
    /**
     * 
     * @type {number}
     * @memberof OrderOutput
     */
    orderEquipmentTotal: number;
    /**
     * 
     * @type {Array<OrderPaymentOutput>}
     * @memberof OrderOutput
     */
    payments: Array<OrderPaymentOutput>;
    /**
     * 
     * @type {number}
     * @memberof OrderOutput
     */
    paymentTotal: number;
    /**
     * 
     * @type {Array<OrderDiscountOutput>}
     * @memberof OrderOutput
     */
    discounts: Array<OrderDiscountOutput>;
    /**
     * 
     * @type {number}
     * @memberof OrderOutput
     */
    discountTotal: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOutput
     */
    deliveryFee: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOutput
     */
    totalAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderOutput
     */
    balance: number;
    /**
     * 
     * @type {Date}
     * @memberof OrderOutput
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderOutput
     */
    updatedAt: Date;
    /**
     * 
     * @type {CustomerOutput}
     * @memberof OrderOutput
     */
    customer?: CustomerOutput;
}

/**
 * Check if a given object implements the OrderOutput interface.
 */
export function instanceOfOrderOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "deliveryAddress" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "branch" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "scheduledDate" in value;
    isInstance = isInstance && "timeOfDay" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "contacts" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "media" in value;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "itemsTotal" in value;
    isInstance = isInstance && "orderEquipment" in value;
    isInstance = isInstance && "orderEquipmentTotal" in value;
    isInstance = isInstance && "payments" in value;
    isInstance = isInstance && "paymentTotal" in value;
    isInstance = isInstance && "discounts" in value;
    isInstance = isInstance && "discountTotal" in value;
    isInstance = isInstance && "deliveryFee" in value;
    isInstance = isInstance && "totalAmount" in value;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function OrderOutputFromJSON(json: any): OrderOutput {
    return OrderOutputFromJSONTyped(json, false);
}

export function OrderOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'key': json['key'],
        'customerId': json['customerId'],
        'cpf': !exists(json, 'cpf') ? undefined : json['cpf'],
        'cnpj': !exists(json, 'cnpj') ? undefined : json['cnpj'],
        'deliveryAddress': AddressOutputFromJSON(json['deliveryAddress']),
        'nf': !exists(json, 'nf') ? undefined : json['nf'],
        'siteOrderNumber': !exists(json, 'siteOrderNumber') ? undefined : json['siteOrderNumber'],
        'type': json['type'],
        'branch': StoreBranchFromJSON(json['branch']),
        'source': OrderSourceFromJSON(json['source']),
        'tags': ((json['tags'] as Array<any>).map(OrderTagsFromJSON)),
        'scheduledDate': (new Date(json['scheduledDate'])),
        'returnDate': !exists(json, 'returnDate') ? undefined : (new Date(json['returnDate'])),
        'timeOfDay': TimeOfDayFromJSON(json['timeOfDay']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'status': OrderStatusFromJSON(json['status']),
        'contacts': ((json['contacts'] as Array<any>).map(OrderContactOutputFromJSON)),
        'metadata': OrderMetadataOutputFromJSON(json['metadata']),
        'media': ((json['media'] as Array<any>).map(OrderMediaOutputFromJSON)),
        'items': ((json['items'] as Array<any>).map(OrderItemOutputFromJSON)),
        'itemsTotal': json['itemsTotal'],
        'orderEquipment': ((json['orderEquipment'] as Array<any>).map(OrderEquipmentOutputFromJSON)),
        'orderEquipmentTotal': json['orderEquipmentTotal'],
        'payments': ((json['payments'] as Array<any>).map(OrderPaymentOutputFromJSON)),
        'paymentTotal': json['paymentTotal'],
        'discounts': ((json['discounts'] as Array<any>).map(OrderDiscountOutputFromJSON)),
        'discountTotal': json['discountTotal'],
        'deliveryFee': json['deliveryFee'],
        'totalAmount': json['totalAmount'],
        'balance': json['balance'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'customer': !exists(json, 'customer') ? undefined : CustomerOutputFromJSON(json['customer']),
    };
}

export function OrderOutputToJSON(value?: OrderOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'customerId': value.customerId,
        'cpf': value.cpf,
        'cnpj': value.cnpj,
        'deliveryAddress': AddressOutputToJSON(value.deliveryAddress),
        'nf': value.nf,
        'siteOrderNumber': value.siteOrderNumber,
        'type': value.type,
        'branch': StoreBranchToJSON(value.branch),
        'source': OrderSourceToJSON(value.source),
        'tags': ((value.tags as Array<any>).map(OrderTagsToJSON)),
        'scheduledDate': (value.scheduledDate.toISOString()),
        'returnDate': value.returnDate === undefined ? undefined : (value.returnDate.toISOString()),
        'timeOfDay': TimeOfDayToJSON(value.timeOfDay),
        'notes': value.notes,
        'status': OrderStatusToJSON(value.status),
        'contacts': ((value.contacts as Array<any>).map(OrderContactOutputToJSON)),
        'metadata': OrderMetadataOutputToJSON(value.metadata),
        'media': ((value.media as Array<any>).map(OrderMediaOutputToJSON)),
        'items': ((value.items as Array<any>).map(OrderItemOutputToJSON)),
        'itemsTotal': value.itemsTotal,
        'orderEquipment': ((value.orderEquipment as Array<any>).map(OrderEquipmentOutputToJSON)),
        'orderEquipmentTotal': value.orderEquipmentTotal,
        'payments': ((value.payments as Array<any>).map(OrderPaymentOutputToJSON)),
        'paymentTotal': value.paymentTotal,
        'discounts': ((value.discounts as Array<any>).map(OrderDiscountOutputToJSON)),
        'discountTotal': value.discountTotal,
        'deliveryFee': value.deliveryFee,
        'totalAmount': value.totalAmount,
        'balance': value.balance,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'customer': CustomerOutputToJSON(value.customer),
    };
}

