import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { NumberOfOrdersAnalyticsCard } from "../layout/Analytics/NumberOfOrdersAnalytics";
import { RevenueOrdersAnalyticsCard } from "../layout/Analytics/RevenueOrdersAnalytics";
import { usePhrases } from "../../context/PhrasesContext";
import { UpcomingOrders } from "../layout/DashboardModules/UpcomingOrders";

export const DashboardPage: React.FC = () => {
  const { getPhrase } = usePhrases();

  const analyticsData = [
    {
      date: new Date(2023, 0, 1),
      numberOfOrders: getRandomNumber(1, 30),
      revenue: getRandomNumber(500, 10000),
    },
    {
      date: new Date(2023, 0, 2),
      numberOfOrders: getRandomNumber(1, 30),
      revenue: getRandomNumber(500, 10000),
    },
    {
      date: new Date(2023, 0, 3),
      numberOfOrders: getRandomNumber(1, 30),
      revenue: getRandomNumber(500, 10000),
    },
    {
      date: new Date(2023, 0, 4),
      numberOfOrders: getRandomNumber(1, 30),
      revenue: getRandomNumber(500, 10000),
    },
    {
      date: new Date(2023, 0, 5),
      numberOfOrders: getRandomNumber(1, 30),
      revenue: getRandomNumber(500, 10000),
    },
    {
      date: new Date(2023, 0, 6),
      numberOfOrders: getRandomNumber(1, 30),
      revenue: getRandomNumber(500, 10000),
    },
    {
      date: new Date(2023, 0, 7),
      numberOfOrders: getRandomNumber(1, 30),
      revenue: getRandomNumber(500, 10000),
    },
  ];

  function getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return (
    <Box m={5}>
      <Typography variant="h4" component="h1" my={5}>
        {getPhrase("DASHBOARD_PAGE.TITLE")}
      </Typography>

      <Grid container spacing={4}>
        <UpcomingOrders />

        <Grid item lg={6} sm={12}>
          <RevenueOrdersAnalyticsCard data={analyticsData} />
        </Grid>
        <Grid item lg={6} sm={12}>
          <NumberOfOrdersAnalyticsCard data={analyticsData} />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <NumberOfOrdersAnalyticsCard data={analyticsData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <RevenueOrdersAnalyticsCard data={analyticsData} />
        </Grid> */}
      </Grid>
    </Box>
  );
};
