/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CustomerMediaCategory = {
    ProofOfId: 'PROOF_OF_ID',
    ProofOfAddress: 'PROOF_OF_ADDRESS',
    Other: 'OTHER'
} as const;
export type CustomerMediaCategory = typeof CustomerMediaCategory[keyof typeof CustomerMediaCategory];


export function CustomerMediaCategoryFromJSON(json: any): CustomerMediaCategory {
    return CustomerMediaCategoryFromJSONTyped(json, false);
}

export function CustomerMediaCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerMediaCategory {
    return json as CustomerMediaCategory;
}

export function CustomerMediaCategoryToJSON(value?: CustomerMediaCategory | null): any {
    return value as any;
}

