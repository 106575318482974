import Joi from "joi";
import {
  Configuration,
  EquipmentApi,
  EquipmentCategory,
} from "../generated-api";
import { getAccessToken } from "../utils/getAccessToken";

class EquipmentService extends EquipmentApi {
  constructor() {
    const config = new Configuration({
      accessToken: getAccessToken,
      basePath: process.env.REACT_APP_API_URL,
    });

    super(config);
  }
}

export const equipmentService = new EquipmentService();

export const EQUIPMENT_CREATE_INPUT_SCHEMA = Joi.object({
  name: Joi.string().trim().required(),
  description: Joi.string().trim().allow("").optional(),
  pricePerUnit: Joi.number().optional(),
  referenceCode: Joi.string().trim().allow("").optional(),
  category: Joi.string()
    .trim()
    .valid(...Object.values(EquipmentCategory))
    .required(),
});

export const EQUIPMENT_UPDATE_INPUT_SCHEMA = Joi.object({
  name: Joi.string().trim().optional(),
  description: Joi.string().trim().allow("").optional(),
  pricePerUnit: Joi.number().optional(),
  referenceCode: Joi.string().trim().allow("").optional(),
  category: Joi.string()
    .trim()
    .valid(...Object.values(EquipmentCategory))
    .optional(),
});
