/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountOutput } from './DiscountOutput';
import {
    DiscountOutputFromJSON,
    DiscountOutputFromJSONTyped,
    DiscountOutputToJSON,
} from './DiscountOutput';

/**
 * 
 * @export
 * @interface PagedOutputDiscountOutput
 */
export interface PagedOutputDiscountOutput {
    /**
     * 
     * @type {Array<DiscountOutput>}
     * @memberof PagedOutputDiscountOutput
     */
    data: Array<DiscountOutput>;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputDiscountOutput
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputDiscountOutput
     */
    perPage: number;
    /**
     * 
     * @type {number}
     * @memberof PagedOutputDiscountOutput
     */
    totalCount: number;
}

/**
 * Check if a given object implements the PagedOutputDiscountOutput interface.
 */
export function instanceOfPagedOutputDiscountOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "perPage" in value;
    isInstance = isInstance && "totalCount" in value;

    return isInstance;
}

export function PagedOutputDiscountOutputFromJSON(json: any): PagedOutputDiscountOutput {
    return PagedOutputDiscountOutputFromJSONTyped(json, false);
}

export function PagedOutputDiscountOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedOutputDiscountOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(DiscountOutputFromJSON)),
        'page': json['page'],
        'perPage': json['perPage'],
        'totalCount': json['totalCount'],
    };
}

export function PagedOutputDiscountOutputToJSON(value?: PagedOutputDiscountOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(DiscountOutputToJSON)),
        'page': value.page,
        'perPage': value.perPage,
        'totalCount': value.totalCount,
    };
}

