/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipmentCategory } from './EquipmentCategory';
import {
    EquipmentCategoryFromJSON,
    EquipmentCategoryFromJSONTyped,
    EquipmentCategoryToJSON,
} from './EquipmentCategory';

/**
 * 
 * @export
 * @interface OrderEquipmentEnrichedOutput
 */
export interface OrderEquipmentEnrichedOutput {
    /**
     * 
     * @type {string}
     * @memberof OrderEquipmentEnrichedOutput
     */
    equipmentId: string;
    /**
     * 
     * @type {number}
     * @memberof OrderEquipmentEnrichedOutput
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof OrderEquipmentEnrichedOutput
     */
    pricePerUnit: number;
    /**
     * 
     * @type {string}
     * @memberof OrderEquipmentEnrichedOutput
     */
    referenceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEquipmentEnrichedOutput
     */
    name?: string;
    /**
     * 
     * @type {EquipmentCategory}
     * @memberof OrderEquipmentEnrichedOutput
     */
    category?: EquipmentCategory;
}

/**
 * Check if a given object implements the OrderEquipmentEnrichedOutput interface.
 */
export function instanceOfOrderEquipmentEnrichedOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "equipmentId" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "pricePerUnit" in value;

    return isInstance;
}

export function OrderEquipmentEnrichedOutputFromJSON(json: any): OrderEquipmentEnrichedOutput {
    return OrderEquipmentEnrichedOutputFromJSONTyped(json, false);
}

export function OrderEquipmentEnrichedOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderEquipmentEnrichedOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equipmentId': json['equipmentId'],
        'quantity': json['quantity'],
        'pricePerUnit': json['pricePerUnit'],
        'referenceCode': !exists(json, 'referenceCode') ? undefined : json['referenceCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'category': !exists(json, 'category') ? undefined : EquipmentCategoryFromJSON(json['category']),
    };
}

export function OrderEquipmentEnrichedOutputToJSON(value?: OrderEquipmentEnrichedOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equipmentId': value.equipmentId,
        'quantity': value.quantity,
        'pricePerUnit': value.pricePerUnit,
        'referenceCode': value.referenceCode,
        'name': value.name,
        'category': EquipmentCategoryToJSON(value.category),
    };
}

