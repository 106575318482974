/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductCategory } from './ProductCategory';
import {
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';
import type { SizeUnit } from './SizeUnit';
import {
    SizeUnitFromJSON,
    SizeUnitFromJSONTyped,
    SizeUnitToJSON,
} from './SizeUnit';

/**
 * 
 * @export
 * @interface ProductUpdateInput
 */
export interface ProductUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateInput
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateInput
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateInput
     */
    size?: number;
    /**
     * 
     * @type {SizeUnit}
     * @memberof ProductUpdateInput
     */
    sizeUnit?: SizeUnit;
    /**
     * 
     * @type {ProductCategory}
     * @memberof ProductUpdateInput
     */
    category?: ProductCategory;
}

/**
 * Check if a given object implements the ProductUpdateInput interface.
 */
export function instanceOfProductUpdateInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductUpdateInputFromJSON(json: any): ProductUpdateInput {
    return ProductUpdateInputFromJSONTyped(json, false);
}

export function ProductUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'sizeUnit': !exists(json, 'sizeUnit') ? undefined : SizeUnitFromJSON(json['sizeUnit']),
        'category': !exists(json, 'category') ? undefined : ProductCategoryFromJSON(json['category']),
    };
}

export function ProductUpdateInputToJSON(value?: ProductUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'size': value.size,
        'sizeUnit': SizeUnitToJSON(value.sizeUnit),
        'category': ProductCategoryToJSON(value.category),
    };
}

