/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreBranch } from './StoreBranch';
import {
    StoreBranchFromJSON,
    StoreBranchFromJSONTyped,
    StoreBranchToJSON,
} from './StoreBranch';
import type { UserStatus } from './UserStatus';
import {
    UserStatusFromJSON,
    UserStatusFromJSONTyped,
    UserStatusToJSON,
} from './UserStatus';

/**
 * 
 * @export
 * @interface UserUpdateInput
 */
export interface UserUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateInput
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateInput
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateInput
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateInput
     */
    password?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateInput
     */
    permissions?: Array<string>;
    /**
     * 
     * @type {Array<StoreBranch>}
     * @memberof UserUpdateInput
     */
    branches?: Array<StoreBranch>;
    /**
     * 
     * @type {StoreBranch}
     * @memberof UserUpdateInput
     */
    defaultBranch?: StoreBranch;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserUpdateInput
     */
    status?: UserStatus;
}

/**
 * Check if a given object implements the UserUpdateInput interface.
 */
export function instanceOfUserUpdateInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserUpdateInputFromJSON(json: any): UserUpdateInput {
    return UserUpdateInputFromJSONTyped(json, false);
}

export function UserUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
        'branches': !exists(json, 'branches') ? undefined : ((json['branches'] as Array<any>).map(StoreBranchFromJSON)),
        'defaultBranch': !exists(json, 'defaultBranch') ? undefined : StoreBranchFromJSON(json['defaultBranch']),
        'status': !exists(json, 'status') ? undefined : UserStatusFromJSON(json['status']),
    };
}

export function UserUpdateInputToJSON(value?: UserUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'password': value.password,
        'permissions': value.permissions,
        'branches': value.branches === undefined ? undefined : ((value.branches as Array<any>).map(StoreBranchToJSON)),
        'defaultBranch': StoreBranchToJSON(value.defaultBranch),
        'status': UserStatusToJSON(value.status),
    };
}

