import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  TableFooter,
  TablePagination,
  Box,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { usePhrases } from "../../../context/PhrasesContext";
import { useSnackbar } from "../../../context/SnackbarContext";
import { userService } from "../../../services/UserApi";
import { UserOutput } from "../../../generated-api";

export const UserListPage: React.FC = () => {
  const [users, setUsers] = useState<UserOutput[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { getPhrase } = usePhrases();
  const { showMessage } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const data = await userService.getManyUsers({
        page,
        perPage,
      });
      setUsers(data.data);
      setTotalCount(data.totalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
      showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
    } finally {
      setLoading(false);
    }
  }, [page, perPage, showMessage, getPhrase]);

  useEffect(() => {
    fetchData();
  }, [page, perPage, fetchData]);

  const handleClick = (
    userId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedUserId(userId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewUser = () => {
    if (selectedUserId) {
      navigate(`/users/${selectedUserId}`);
    }
  };

  const handleEditUser = () => {
    if (selectedUserId) {
      navigate(`/users/${selectedUserId}/edit`);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2, p: 2 }}
      >
        <Box mr={5}>
          <Typography variant="h5">{getPhrase("USER_LIST")}</Typography>
        </Box>
        <Button
          size="small"
          variant="contained"
          onClick={() => navigate("/users/create")}
          startIcon={<AddCircleOutlineIcon />}
          sx={{ whiteSpace: "nowrap" }}
        >
          {getPhrase(`CREATE_USER`)}
        </Button>
      </Box>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table size="small" stickyHeader aria-label="users table">
            <TableHead>
              <TableRow>
                <TableCell>{getPhrase(`FIRST_NAME`)}</TableCell>
                <TableCell>{getPhrase(`LAST_NAME`)}</TableCell>
                <TableCell>{getPhrase(`EMAIL`)}</TableCell>
                <TableCell>{getPhrase(`STATUS`)}</TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {getPhrase(`ACTIONS`)}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {users.map((user) => (
                <TableRow hover key={user.id}>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.status === "ACTIVE"
                      ? getPhrase("ACTIVE")
                      : getPhrase("INACTIVE")}
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} justifyContent={"end"}>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-controls="user-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleClick(user.id, event)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                      <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleViewUser}>
                          {getPhrase(`VIEW`)}
                        </MenuItem>
                        <MenuItem onClick={handleEditUser}>
                          {getPhrase(`EDIT`)}
                        </MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="body2">
                    {getPhrase(`TOTAL_USERS`)}: {totalCount}
                  </Typography>
                </TableCell>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={totalCount}
                  rowsPerPage={perPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
