import React, { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import {
  ProductCategory,
  ProductCreateInput,
  ProductUpdateInput,
  SizeUnit,
} from "../../../generated-api";
import {
  PRODUCT_CREATE_INPUT_SCHEMA,
  PRODUCT_UPDATE_INPUT_SCHEMA,
  productService,
} from "../../../services/ProductApi";
import { usePhrases } from "../../../context/PhrasesContext";
import { useSnackbar } from "../../../context/SnackbarContext";
import { removeFalsy } from "../../../utils/removeFalsy";

export const ProductCreateAndEditPage: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const isEditMode = Boolean(productId);
  const [loading, setLoading] = useState(!!isEditMode);

  const navigate = useNavigate();
  const { getPhrase } = usePhrases();
  const { showMessage } = useSnackbar();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ProductCreateInput & ProductUpdateInput>({
    resolver: joiResolver(
      isEditMode ? PRODUCT_UPDATE_INPUT_SCHEMA : PRODUCT_CREATE_INPUT_SCHEMA
    ),
    defaultValues: {
      name: "",
      description: "",
      price: 0,
      size: 0,
      sizeUnit: SizeUnit.Liter,
      category: ProductCategory.Other,
    },
  });

  useEffect(() => {
    if (isEditMode && productId) {
      // Fetch product data and populate the form
      const fetchProductData = async () => {
        try {
          const productData = await productService.getProduct({ productId });
          console.log("Product data:", productData);
          reset({
            name: productData.name,
            description: productData.description,
            price: productData.price,
            size: productData.size,
            sizeUnit: productData.sizeUnit,
            category: productData.category,
          });
          setLoading(false);
        } catch (error) {
          console.error("Error fetching product data:", error);
          showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
        }
      };
      fetchProductData();
    }
  }, [isEditMode, productId, reset, showMessage, getPhrase]);

  const onSubmit: SubmitHandler<
    ProductCreateInput & ProductUpdateInput
  > = async (productData) => {
    const data = removeFalsy(productData);
    try {
      if (isEditMode && productId) {
        await productService.updateProduct({
          productId,
          productUpdateInput: data,
        });
        showMessage(getPhrase("PRODUCT_UPDATED_SUCCESSFULLY"), "success");
        navigate(`/products`);
      } else {
        await productService.createProduct({
          productCreateInput: data,
        });
        showMessage(getPhrase("PRODUCT_CREATED_SUCCESSFULLY"), "success");
        navigate(`/products`);
      }
    } catch (error) {
      console.error("Error saving product:", error);
      showMessage(getPhrase("ERROR.GENERIC.SAVE_DATA"), "error");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Box my={5}>
        <Typography component="h1" variant="h4">
          {isEditMode ? getPhrase("EDIT_PRODUCT") : getPhrase("CREATE_PRODUCT")}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={getPhrase("PRODUCT_NAME")}
                  variant="outlined"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={getPhrase("PRODUCT_DESCRIPTION")}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={getPhrase("PRODUCT_PRICE")}
                  type="number"
                  variant="outlined"
                  fullWidth
                  error={!!errors.price}
                  helperText={errors.price?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="size"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={getPhrase("PRODUCT_SIZE")}
                  type="number"
                  variant="outlined"
                  fullWidth
                  error={!!errors.size}
                  helperText={errors.size?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="sizeUnit"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>{getPhrase("PRODUCT_SIZE_UNIT")}</InputLabel>
                  <Select
                    {...field}
                    label={getPhrase("PRODUCT_SIZE_UNIT")}
                    error={!!errors.sizeUnit}
                  >
                    {Object.values(SizeUnit).map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {getPhrase(`PRODUCT_SIZE_UNIT.${unit}`)}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="caption" color="error">
                    {errors.sizeUnit?.message}
                  </Typography>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>{getPhrase("PRODUCT_CATEGORY")}</InputLabel>
                  <Select
                    {...field}
                    label={getPhrase("PRODUCT_CATEGORY")}
                    error={!!errors.category}
                  >
                    {Object.values(ProductCategory).map((category) => (
                      <MenuItem key={category} value={category}>
                        {getPhrase(`PRODUCT_CATEGORY.${category}`)}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="caption" color="error">
                    {errors.category?.message}
                  </Typography>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {isEditMode
                ? getPhrase("SAVE_CHANGES")
                : getPhrase("CREATE_PRODUCT")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
