/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  StoreBranch,
  SummarizedOrderDataByDayOutput,
} from '../models/index';
import {
    StoreBranchFromJSON,
    StoreBranchToJSON,
    SummarizedOrderDataByDayOutputFromJSON,
    SummarizedOrderDataByDayOutputToJSON,
} from '../models/index';

export interface GetSummarizedOrdersDataByDayRequest {
    scheduledDateGte: Date;
    scheduledDateLte: Date;
    branch?: StoreBranch;
}

/**
 * 
 */
export class AnalyticsApi extends runtime.BaseAPI {

    /**
     * User login
     */
    async getSummarizedOrdersDataByDayRaw(requestParameters: GetSummarizedOrdersDataByDayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SummarizedOrderDataByDayOutput>> {
        if (requestParameters.scheduledDateGte === null || requestParameters.scheduledDateGte === undefined) {
            throw new runtime.RequiredError('scheduledDateGte','Required parameter requestParameters.scheduledDateGte was null or undefined when calling getSummarizedOrdersDataByDay.');
        }

        if (requestParameters.scheduledDateLte === null || requestParameters.scheduledDateLte === undefined) {
            throw new runtime.RequiredError('scheduledDateLte','Required parameter requestParameters.scheduledDateLte was null or undefined when calling getSummarizedOrdersDataByDay.');
        }

        const queryParameters: any = {};

        if (requestParameters.scheduledDateGte !== undefined) {
            queryParameters['scheduledDateGte'] = (requestParameters.scheduledDateGte as any).toISOString();
        }

        if (requestParameters.scheduledDateLte !== undefined) {
            queryParameters['scheduledDateLte'] = (requestParameters.scheduledDateLte as any).toISOString();
        }

        if (requestParameters.branch !== undefined) {
            queryParameters['branch'] = requestParameters.branch;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/analytics/getSummarizedOrdersDataByDay`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummarizedOrderDataByDayOutputFromJSON(jsonValue));
    }

    /**
     * User login
     */
    async getSummarizedOrdersDataByDay(requestParameters: GetSummarizedOrdersDataByDayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SummarizedOrderDataByDayOutput> {
        const response = await this.getSummarizedOrdersDataByDayRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
