/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressUpdateInput } from './AddressUpdateInput';
import {
    AddressUpdateInputFromJSON,
    AddressUpdateInputFromJSONTyped,
    AddressUpdateInputToJSON,
} from './AddressUpdateInput';
import type { CustomerContactUpdateInput } from './CustomerContactUpdateInput';
import {
    CustomerContactUpdateInputFromJSON,
    CustomerContactUpdateInputFromJSONTyped,
    CustomerContactUpdateInputToJSON,
} from './CustomerContactUpdateInput';

/**
 * 
 * @export
 * @interface CustomerUpdateInput
 */
export interface CustomerUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateInput
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateInput
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateInput
     */
    cpf?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateInput
     */
    cnpj?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateInput
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateInput
     */
    email2?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateInput
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateInput
     */
    phone2?: string;
    /**
     * 
     * @type {Array<CustomerContactUpdateInput>}
     * @memberof CustomerUpdateInput
     */
    contacts?: Array<CustomerContactUpdateInput>;
    /**
     * 
     * @type {AddressUpdateInput}
     * @memberof CustomerUpdateInput
     */
    address?: AddressUpdateInput;
    /**
     * 
     * @type {AddressUpdateInput}
     * @memberof CustomerUpdateInput
     */
    address2?: AddressUpdateInput | null;
    /**
     * 
     * @type {Date}
     * @memberof CustomerUpdateInput
     */
    dob?: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomerUpdateInput
     */
    notes?: string;
}

/**
 * Check if a given object implements the CustomerUpdateInput interface.
 */
export function instanceOfCustomerUpdateInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerUpdateInputFromJSON(json: any): CustomerUpdateInput {
    return CustomerUpdateInputFromJSONTyped(json, false);
}

export function CustomerUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'cpf': !exists(json, 'cpf') ? undefined : json['cpf'],
        'cnpj': !exists(json, 'cnpj') ? undefined : json['cnpj'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'email2': !exists(json, 'email2') ? undefined : json['email2'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'phone2': !exists(json, 'phone2') ? undefined : json['phone2'],
        'contacts': !exists(json, 'contacts') ? undefined : ((json['contacts'] as Array<any>).map(CustomerContactUpdateInputFromJSON)),
        'address': !exists(json, 'address') ? undefined : AddressUpdateInputFromJSON(json['address']),
        'address2': !exists(json, 'address2') ? undefined : AddressUpdateInputFromJSON(json['address2']),
        'dob': !exists(json, 'dob') ? undefined : (new Date(json['dob'])),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function CustomerUpdateInputToJSON(value?: CustomerUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'cpf': value.cpf,
        'cnpj': value.cnpj,
        'email': value.email,
        'email2': value.email2,
        'phone': value.phone,
        'phone2': value.phone2,
        'contacts': value.contacts === undefined ? undefined : ((value.contacts as Array<any>).map(CustomerContactUpdateInputToJSON)),
        'address': AddressUpdateInputToJSON(value.address),
        'address2': AddressUpdateInputToJSON(value.address2),
        'dob': value.dob === undefined ? undefined : (value.dob.toISOString()),
        'notes': value.notes,
    };
}

