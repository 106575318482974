/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressCreateInput } from './AddressCreateInput';
import {
    AddressCreateInputFromJSON,
    AddressCreateInputFromJSONTyped,
    AddressCreateInputToJSON,
} from './AddressCreateInput';
import type { OrderContactCreateInput } from './OrderContactCreateInput';
import {
    OrderContactCreateInputFromJSON,
    OrderContactCreateInputFromJSONTyped,
    OrderContactCreateInputToJSON,
} from './OrderContactCreateInput';
import type { OrderDiscountCreateInput } from './OrderDiscountCreateInput';
import {
    OrderDiscountCreateInputFromJSON,
    OrderDiscountCreateInputFromJSONTyped,
    OrderDiscountCreateInputToJSON,
} from './OrderDiscountCreateInput';
import type { OrderEquipmentCreateInput } from './OrderEquipmentCreateInput';
import {
    OrderEquipmentCreateInputFromJSON,
    OrderEquipmentCreateInputFromJSONTyped,
    OrderEquipmentCreateInputToJSON,
} from './OrderEquipmentCreateInput';
import type { OrderItemsCreateInput } from './OrderItemsCreateInput';
import {
    OrderItemsCreateInputFromJSON,
    OrderItemsCreateInputFromJSONTyped,
    OrderItemsCreateInputToJSON,
} from './OrderItemsCreateInput';
import type { OrderSource } from './OrderSource';
import {
    OrderSourceFromJSON,
    OrderSourceFromJSONTyped,
    OrderSourceToJSON,
} from './OrderSource';
import type { OrderTags } from './OrderTags';
import {
    OrderTagsFromJSON,
    OrderTagsFromJSONTyped,
    OrderTagsToJSON,
} from './OrderTags';
import type { OrderType } from './OrderType';
import {
    OrderTypeFromJSON,
    OrderTypeFromJSONTyped,
    OrderTypeToJSON,
} from './OrderType';
import type { StoreBranch } from './StoreBranch';
import {
    StoreBranchFromJSON,
    StoreBranchFromJSONTyped,
    StoreBranchToJSON,
} from './StoreBranch';
import type { TimeOfDay } from './TimeOfDay';
import {
    TimeOfDayFromJSON,
    TimeOfDayFromJSONTyped,
    TimeOfDayToJSON,
} from './TimeOfDay';

/**
 * 
 * @export
 * @interface OrderCreateInput
 */
export interface OrderCreateInput {
    /**
     * 
     * @type {string}
     * @memberof OrderCreateInput
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateInput
     */
    cpf?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateInput
     */
    cnpj?: string;
    /**
     * 
     * @type {AddressCreateInput}
     * @memberof OrderCreateInput
     */
    deliveryAddress: AddressCreateInput;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateInput
     */
    nf?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateInput
     */
    siteOrderNumber?: string;
    /**
     * 
     * @type {OrderType}
     * @memberof OrderCreateInput
     */
    type?: OrderType;
    /**
     * 
     * @type {StoreBranch}
     * @memberof OrderCreateInput
     */
    branch: StoreBranch;
    /**
     * 
     * @type {OrderSource}
     * @memberof OrderCreateInput
     */
    source: OrderSource;
    /**
     * 
     * @type {Array<OrderTags>}
     * @memberof OrderCreateInput
     */
    tags?: Array<OrderTags>;
    /**
     * 
     * @type {Array<OrderItemsCreateInput>}
     * @memberof OrderCreateInput
     */
    items?: Array<OrderItemsCreateInput>;
    /**
     * 
     * @type {Array<OrderEquipmentCreateInput>}
     * @memberof OrderCreateInput
     */
    orderEquipment?: Array<OrderEquipmentCreateInput>;
    /**
     * 
     * @type {Date}
     * @memberof OrderCreateInput
     */
    scheduledDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderCreateInput
     */
    returnDate?: Date;
    /**
     * 
     * @type {TimeOfDay}
     * @memberof OrderCreateInput
     */
    timeOfDay: TimeOfDay;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateInput
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateInput
     */
    deliveryFee: number;
    /**
     * 
     * @type {Array<OrderContactCreateInput>}
     * @memberof OrderCreateInput
     */
    contacts?: Array<OrderContactCreateInput>;
    /**
     * 
     * @type {Array<OrderDiscountCreateInput>}
     * @memberof OrderCreateInput
     */
    discounts?: Array<OrderDiscountCreateInput>;
}

/**
 * Check if a given object implements the OrderCreateInput interface.
 */
export function instanceOfOrderCreateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "deliveryAddress" in value;
    isInstance = isInstance && "branch" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "scheduledDate" in value;
    isInstance = isInstance && "timeOfDay" in value;
    isInstance = isInstance && "deliveryFee" in value;

    return isInstance;
}

export function OrderCreateInputFromJSON(json: any): OrderCreateInput {
    return OrderCreateInputFromJSONTyped(json, false);
}

export function OrderCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'cpf': !exists(json, 'cpf') ? undefined : json['cpf'],
        'cnpj': !exists(json, 'cnpj') ? undefined : json['cnpj'],
        'deliveryAddress': AddressCreateInputFromJSON(json['deliveryAddress']),
        'nf': !exists(json, 'nf') ? undefined : json['nf'],
        'siteOrderNumber': !exists(json, 'siteOrderNumber') ? undefined : json['siteOrderNumber'],
        'type': !exists(json, 'type') ? undefined : OrderTypeFromJSON(json['type']),
        'branch': StoreBranchFromJSON(json['branch']),
        'source': OrderSourceFromJSON(json['source']),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(OrderTagsFromJSON)),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(OrderItemsCreateInputFromJSON)),
        'orderEquipment': !exists(json, 'orderEquipment') ? undefined : ((json['orderEquipment'] as Array<any>).map(OrderEquipmentCreateInputFromJSON)),
        'scheduledDate': (new Date(json['scheduledDate'])),
        'returnDate': !exists(json, 'returnDate') ? undefined : (new Date(json['returnDate'])),
        'timeOfDay': TimeOfDayFromJSON(json['timeOfDay']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'deliveryFee': json['deliveryFee'],
        'contacts': !exists(json, 'contacts') ? undefined : ((json['contacts'] as Array<any>).map(OrderContactCreateInputFromJSON)),
        'discounts': !exists(json, 'discounts') ? undefined : ((json['discounts'] as Array<any>).map(OrderDiscountCreateInputFromJSON)),
    };
}

export function OrderCreateInputToJSON(value?: OrderCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'cpf': value.cpf,
        'cnpj': value.cnpj,
        'deliveryAddress': AddressCreateInputToJSON(value.deliveryAddress),
        'nf': value.nf,
        'siteOrderNumber': value.siteOrderNumber,
        'type': OrderTypeToJSON(value.type),
        'branch': StoreBranchToJSON(value.branch),
        'source': OrderSourceToJSON(value.source),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(OrderTagsToJSON)),
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(OrderItemsCreateInputToJSON)),
        'orderEquipment': value.orderEquipment === undefined ? undefined : ((value.orderEquipment as Array<any>).map(OrderEquipmentCreateInputToJSON)),
        'scheduledDate': (value.scheduledDate.toISOString()),
        'returnDate': value.returnDate === undefined ? undefined : (value.returnDate.toISOString()),
        'timeOfDay': TimeOfDayToJSON(value.timeOfDay),
        'notes': value.notes,
        'deliveryFee': value.deliveryFee,
        'contacts': value.contacts === undefined ? undefined : ((value.contacts as Array<any>).map(OrderContactCreateInputToJSON)),
        'discounts': value.discounts === undefined ? undefined : ((value.discounts as Array<any>).map(OrderDiscountCreateInputToJSON)),
    };
}

