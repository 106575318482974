import React, { useEffect, useState } from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import {
  AddressUpdateInput,
  Cities,
  CustomerUpdateInput,
} from "../../generated-api"; // Adjust if you have a specific type for edit
import { removeFalsy } from "../../utils/removeFalsy";
import { usePhrases } from "../../context/PhrasesContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  customerUpdateValidationSchema,
  customerService,
  addressValidationSchema,
} from "../../services/CustomerApi"; // Ensure this validation schema is appropriate for editing
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export const CustomerEditForm: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const [customer, setCustomer] = useState<CustomerUpdateInput | null>(null);
  const [loadingCustomer, setLoadingCustomer] = useState<boolean>(true);

  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const { getPhrase } = usePhrases();

  const {
    control,
    handleSubmit,
    reset, // used to reset form with fetched customer data
    formState: { errors },
    setError,
  } = useForm<CustomerUpdateInput>({
    resolver: joiResolver(customerUpdateValidationSchema),
  });

  const {
    fields,
    append: appendCustomerContact,
    remove: removeCustomerContact,
  } = useFieldArray({
    control,
    name: "contacts",
  });

  useEffect(() => {
    // Fetch the customer data and populate the form
    const fetchCustomerData = async () => {
      if (!customerId) {
        return;
      }
      try {
        const customerData = await customerService.getCustomer({ customerId });
        // We have to map the CustomerOutput to CustomerUpdateInput to exclude fields like id, archivedAt, etc
        const customerDataMapped = {
          address: customerData.address,
          address2: customerData.address2,
          contacts: customerData.contacts,
          cpf: customerData.cpf,
          cnpj: customerData.cnpj,
          email: customerData.email,
          email2: customerData.email2,
          firstName: customerData.firstName,
          lastName: customerData.lastName,
          notes: customerData.notes,
          phone: customerData.phone,
          phone2: customerData.phone2,
          dob: customerData.dob,
        };
        setCustomer(customerDataMapped);
        reset(customerDataMapped);
      } catch (error) {
        showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
        console.error("Error:", error);
      } finally {
        setLoadingCustomer(false);
      }
    };

    fetchCustomerData();
  }, [customerId, reset, showMessage, getPhrase]);

  const onSubmit: SubmitHandler<CustomerUpdateInput> = async (data) => {
    if (!customerId) {
      return;
    }
    console.log("Submitting edited data:", data);

    const cleanedData: any = removeFalsy(data);
    // Determine if any address2 field is filled
    const isAddress2Filled =
      Object.values(data.address2 ?? {}).filter((v) => !!v).length > 0;
    if (!isAddress2Filled) {
      delete cleanedData.address2;
    } else {
      const { error } = addressValidationSchema.validate(cleanedData.address2, {
        abortEarly: false,
      });
      // add errors to the form
      if (error) {
        // Map Joi errors to react-hook-form errors and set them
        error.details.forEach((detail) => {
          setError(`address2.${detail.path[0]}` as unknown as any, {
            type: "manual",
            message: detail.message,
          });
        });
        // Stop the submission if there are errors
        return;
      }
    }

    try {
      console.log("Updating customer:\n", JSON.stringify(cleanedData, null, 2));
      const updatedCustomer = await customerService.updateCustomer({
        customerId,
        customerUpdateInput: cleanedData,
      });

      console.log("Customer updated:", updatedCustomer);
      showMessage("Customer updated successfully", "success");
      navigate(`/customers/${customerId}`); // Redirect to customers details page
    } catch (error) {
      console.error("Unexpected error when updating customer:", error);
      showMessage("Unexpected error when updating customer", "error");
    }
  };

  const handleRemoveSecondaryAddress = async () => {
    if (!customerId) {
      return;
    }
    try {
      await customerService.updateCustomer({
        customerId,
        customerUpdateInput: {
          address2: null,
        },
      });
      const address2: AddressUpdateInput = {
        additionalInfo: "",
        city: "" as any,
        neighborhood: "",
        number: "",
        postalCode: "",
        state: "",
        street: "",
      };
      // Update the local state
      setCustomer((prev) => {
        if (prev) {
          return {
            ...prev,
            address2,
          };
        }
        return prev;
      });
      // Update form values
      reset((prev) => {
        if (prev) {
          return {
            ...prev,
            address2,
          };
        }
        return prev;
      });
    } catch (error) {
      console.error("Failed to remove secondary address", error);
      showMessage("Failed to remove secondary address", "error");
    }
  };

  if (loadingCustomer) {
    return <CircularProgress />;
  }

  if (!customer) {
    return <Typography variant="h6">Customer not found</Typography>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {/* First Name */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("CUSTOMER.CREATE_PAGE.FIRST_NAME")}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
            )}
          />
        </Grid>

        {/* Last Name */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("CUSTOMER.CREATE_PAGE.LAST_NAME")}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
            )}
          />
        </Grid>

        {/* Date of Birth */}
        <Grid item xs={12} sm={2}>
          <Controller
            name="dob"
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={getPhrase("DOB")}
                  value={field.value ? dayjs(field.value) : undefined}
                  format="DD/MM/YYYY"
                  onChange={(date) => field.onChange(date?.toDate())}
                />
                {!!errors.dob && (
                  <Typography component="p" color={"red"}>
                    {errors.dob?.message}
                  </Typography>
                )}
              </LocalizationProvider>
            )}
          />
        </Grid>

        {/* CPF */}
        <Grid item xs={12} sm={5}>
          <Controller
            name="cpf"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("CUSTOMER.CREATE_PAGE.CPF")}
                error={!!errors.cpf}
                helperText={errors.cpf?.message}
              />
            )}
          />
        </Grid>

        {/* CNPJ */}
        <Grid item xs={12} sm={5}>
          <Controller
            name="cnpj"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("CUSTOMER.CREATE_PAGE.CNPJ")}
                error={!!errors.cnpj}
                helperText={errors.cnpj?.message}
              />
            )}
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("CUSTOMER.CREATE_PAGE.EMAIL")}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
        </Grid>

        {/* Email2 */}
        <Grid item xs={12}>
          <Controller
            name="email2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("CUSTOMER.CREATE_PAGE.SECONDARY_EMAIL")}
                error={!!errors.email2}
                helperText={errors.email2?.message}
              />
            )}
          />
        </Grid>

        {/* Phone */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("CUSTOMER.CREATE_PAGE.PHONE")}
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            )}
          />
        </Grid>

        {/* Phone2 */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="phone2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("CUSTOMER.CREATE_PAGE.SECONDARY_PHONE")}
                error={!!errors.phone2}
                helperText={errors.phone2?.message}
              />
            )}
          />
        </Grid>

        {/* Notes */}
        <Grid item xs={12}>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                label={getPhrase("CUSTOMER.CREATE_PAGE.NOTES")}
                error={!!errors.notes}
                helperText={errors.notes?.message}
              />
            )}
          />
        </Grid>

        {/* Address Fields */}
        <Grid item xs={12}>
          <Typography variant="h6">
            {getPhrase("CUSTOMER.CREATE_PAGE.PRIMARY_ADDRESS")}
          </Typography>
        </Grid>

        {/* Address: Number */}
        <Grid item xs={3}>
          <Controller
            name="address.number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_NUMBER")}
                error={!!errors.address?.number}
                helperText={errors.address?.number?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Street */}
        <Grid item xs={9}>
          <Controller
            name="address.street"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_STREET")}
                error={!!errors.address?.street}
                helperText={errors.address?.street?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Neighborhood */}
        <Grid item xs={6}>
          <Controller
            name="address.neighborhood"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_NEIGHBORHOOD")}
                error={!!errors.address?.neighborhood}
                helperText={errors.address?.neighborhood?.message}
              />
            )}
          />
        </Grid>

        {/* Address: City */}
        <Grid item xs={6}>
          <Controller
            name="address.city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={getPhrase("ADDRESS_CITY")}
                variant="outlined"
                fullWidth
                error={!!errors.address?.city}
                helperText={errors.address?.city?.message}
              >
                {/* Map OrderType enum to menu items */}
                {Object.values(Cities).map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        {/* Address: State */}
        <Grid item xs={6}>
          <Controller
            name="address.state"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_STATE")}
                error={!!errors.address?.state}
                helperText={errors.address?.state?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Postal Code */}
        <Grid item xs={6}>
          <Controller
            name="address.postalCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_POSTAL_CODE")}
                error={!!errors.address?.postalCode}
                helperText={errors.address?.postalCode?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Additional Info */}
        <Grid item xs={12}>
          <Controller
            name="address.additionalInfo"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                label={getPhrase(
                  "CUSTOMER.CREATE_PAGE.ADDRESS.ADDITIONAL_INFO"
                )}
                error={!!errors.address?.additionalInfo}
                helperText={errors.address?.additionalInfo?.message}
              />
            )}
          />
        </Grid>

        {/* Secondary Address Fields */}
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              {getPhrase("CUSTOMER.CREATE_PAGE.SECONDARY_ADDRESS")}
            </Typography>
            {/* Button to Remove Address, only display if address 2 has values */}
            {customer.address2 &&
              Object.values(customer.address2).filter((v) => !!v) && (
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleRemoveSecondaryAddress}
                >
                  {getPhrase("CUSTOMER.EDIT_PAGE.REMOVE_SECONDARY_ADDRESS")}
                </Button>
              )}
          </Box>
        </Grid>

        {/* Address: Number */}
        <Grid item xs={3}>
          <Controller
            name="address2.number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_NUMBER")}
                error={!!errors.address2?.number}
                helperText={errors.address2?.number?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Street */}
        <Grid item xs={9}>
          <Controller
            name="address2.street"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_STREET")}
                error={!!errors.address2?.street}
                helperText={errors.address2?.street?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Neighborhood */}
        <Grid item xs={6}>
          <Controller
            name="address2.neighborhood"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_NEIGHBORHOOD")}
                error={!!errors.address2?.neighborhood}
                helperText={errors.address2?.neighborhood?.message}
              />
            )}
          />
        </Grid>

        {/* Address: City */}
        <Grid item xs={6}>
          <Controller
            name="address2.city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={getPhrase("ADDRESS_CITY")}
                variant="outlined"
                fullWidth
                error={!!errors.address2?.city}
                helperText={errors.address2?.city?.message}
              >
                <MenuItem value={""} sx={{ fontWeight: "bold" }}>
                  {getPhrase("SELECT_CITY")}
                </MenuItem>
                {/* Map OrderType enum to menu items */}
                {Object.values(Cities).map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        {/* Address: State */}
        <Grid item xs={6}>
          <Controller
            name="address2.state"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_STATE")}
                error={!!errors.address2?.state}
                helperText={errors.address2?.state?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Postal Code */}
        <Grid item xs={6}>
          <Controller
            name="address2.postalCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                label={getPhrase("ADDRESS_POSTAL_CODE")}
                error={!!errors.address2?.postalCode}
                helperText={errors.address2?.postalCode?.message}
              />
            )}
          />
        </Grid>

        {/* Address: Additional Info */}
        <Grid item xs={12}>
          <Controller
            name="address2.additionalInfo"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                label={getPhrase(
                  "CUSTOMER.CREATE_PAGE.ADDRESS.ADDITIONAL_INFO"
                )}
                error={!!errors.address2?.additionalInfo}
                helperText={errors.address2?.additionalInfo?.message}
              />
            )}
          />
        </Grid>

        <Grid container item rowSpacing={2} columnSpacing={2} xs={12}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">
                {getPhrase("CUSTOMER.CREATE_PAGE.ADDITIONAL_CONTACTS")}
              </Typography>
              {/* Button to Add Item */}
              <Button
                variant="contained"
                onClick={() =>
                  appendCustomerContact({
                    firstName: "",
                    lastName: "",
                    phone: "",
                  })
                }
              >
                {getPhrase("CUSTOMER.CREATE_PAGE.ADD_CONTACT")}
              </Button>
            </Box>
          </Grid>
          {fields.map((field, index) => (
            <Grid
              item
              container
              columnSpacing={1}
              rowSpacing={2}
              alignItems="center"
              key={field.id}
            >
              <Grid item xs={3}>
                <Controller
                  name={`contacts.${index}.firstName`}
                  control={control}
                  defaultValue={field.firstName}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={getPhrase("CUSTOMER.CREATE_PAGE.FIRST_NAME")}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={`contacts.${index}.lastName`}
                  control={control}
                  defaultValue={field.lastName}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={getPhrase("CUSTOMER.CREATE_PAGE.LAST_NAME")}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name={`contacts.${index}.phone`}
                  control={control}
                  defaultValue={field.phone}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={getPhrase("CUSTOMER.CREATE_PAGE.PHONE")}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid display={"flex"} justifyContent={"end"} item xs={2}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => removeCustomerContact(index)}
                >
                  {getPhrase("CUSTOMER.CREATE_PAGE.REMOVE_CONTACT")}
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mt: 3, mb: 2 }}
      >
        {getPhrase("CUSTOMER.EDIT_PAGE.SUBMIT_BUTTON")}
      </Button>
    </form>
  );
};
