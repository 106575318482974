/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressOutput } from './AddressOutput';
import {
    AddressOutputFromJSON,
    AddressOutputFromJSONTyped,
    AddressOutputToJSON,
} from './AddressOutput';
import type { CustomerContactOutput } from './CustomerContactOutput';
import {
    CustomerContactOutputFromJSON,
    CustomerContactOutputFromJSONTyped,
    CustomerContactOutputToJSON,
} from './CustomerContactOutput';
import type { CustomerMediaOutput } from './CustomerMediaOutput';
import {
    CustomerMediaOutputFromJSON,
    CustomerMediaOutputFromJSONTyped,
    CustomerMediaOutputToJSON,
} from './CustomerMediaOutput';
import type { CustomerStatus } from './CustomerStatus';
import {
    CustomerStatusFromJSON,
    CustomerStatusFromJSONTyped,
    CustomerStatusToJSON,
} from './CustomerStatus';

/**
 * 
 * @export
 * @interface CustomerOutput
 */
export interface CustomerOutput {
    /**
     * 
     * @type {string}
     * @memberof CustomerOutput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOutput
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOutput
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOutput
     */
    cpf?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOutput
     */
    cnpj?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOutput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOutput
     */
    email2?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOutput
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOutput
     */
    phone2?: string;
    /**
     * 
     * @type {Array<CustomerContactOutput>}
     * @memberof CustomerOutput
     */
    contacts: Array<CustomerContactOutput>;
    /**
     * 
     * @type {AddressOutput}
     * @memberof CustomerOutput
     */
    address: AddressOutput;
    /**
     * 
     * @type {AddressOutput}
     * @memberof CustomerOutput
     */
    address2?: AddressOutput;
    /**
     * 
     * @type {Date}
     * @memberof CustomerOutput
     */
    dob?: Date;
    /**
     * 
     * @type {Array<CustomerMediaOutput>}
     * @memberof CustomerOutput
     */
    media: Array<CustomerMediaOutput>;
    /**
     * 
     * @type {string}
     * @memberof CustomerOutput
     */
    notes?: string;
    /**
     * 
     * @type {CustomerStatus}
     * @memberof CustomerOutput
     */
    status: CustomerStatus;
    /**
     * 
     * @type {Date}
     * @memberof CustomerOutput
     */
    archivedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomerOutput
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomerOutput
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the CustomerOutput interface.
 */
export function instanceOfCustomerOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "contacts" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "media" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function CustomerOutputFromJSON(json: any): CustomerOutput {
    return CustomerOutputFromJSONTyped(json, false);
}

export function CustomerOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'cpf': !exists(json, 'cpf') ? undefined : json['cpf'],
        'cnpj': !exists(json, 'cnpj') ? undefined : json['cnpj'],
        'email': json['email'],
        'email2': !exists(json, 'email2') ? undefined : json['email2'],
        'phone': json['phone'],
        'phone2': !exists(json, 'phone2') ? undefined : json['phone2'],
        'contacts': ((json['contacts'] as Array<any>).map(CustomerContactOutputFromJSON)),
        'address': AddressOutputFromJSON(json['address']),
        'address2': !exists(json, 'address2') ? undefined : AddressOutputFromJSON(json['address2']),
        'dob': !exists(json, 'dob') ? undefined : (new Date(json['dob'])),
        'media': ((json['media'] as Array<any>).map(CustomerMediaOutputFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'status': CustomerStatusFromJSON(json['status']),
        'archivedAt': !exists(json, 'archivedAt') ? undefined : (new Date(json['archivedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function CustomerOutputToJSON(value?: CustomerOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'cpf': value.cpf,
        'cnpj': value.cnpj,
        'email': value.email,
        'email2': value.email2,
        'phone': value.phone,
        'phone2': value.phone2,
        'contacts': ((value.contacts as Array<any>).map(CustomerContactOutputToJSON)),
        'address': AddressOutputToJSON(value.address),
        'address2': AddressOutputToJSON(value.address2),
        'dob': value.dob === undefined ? undefined : (value.dob.toISOString()),
        'media': ((value.media as Array<any>).map(CustomerMediaOutputToJSON)),
        'notes': value.notes,
        'status': CustomerStatusToJSON(value.status),
        'archivedAt': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

