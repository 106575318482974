/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressUpdateInput } from './AddressUpdateInput';
import {
    AddressUpdateInputFromJSON,
    AddressUpdateInputFromJSONTyped,
    AddressUpdateInputToJSON,
} from './AddressUpdateInput';
import type { OrderContactUpdateInput } from './OrderContactUpdateInput';
import {
    OrderContactUpdateInputFromJSON,
    OrderContactUpdateInputFromJSONTyped,
    OrderContactUpdateInputToJSON,
} from './OrderContactUpdateInput';
import type { OrderDiscountUpdateInput } from './OrderDiscountUpdateInput';
import {
    OrderDiscountUpdateInputFromJSON,
    OrderDiscountUpdateInputFromJSONTyped,
    OrderDiscountUpdateInputToJSON,
} from './OrderDiscountUpdateInput';
import type { OrderEquipmentUpdateInput } from './OrderEquipmentUpdateInput';
import {
    OrderEquipmentUpdateInputFromJSON,
    OrderEquipmentUpdateInputFromJSONTyped,
    OrderEquipmentUpdateInputToJSON,
} from './OrderEquipmentUpdateInput';
import type { OrderItemsUpdateInput } from './OrderItemsUpdateInput';
import {
    OrderItemsUpdateInputFromJSON,
    OrderItemsUpdateInputFromJSONTyped,
    OrderItemsUpdateInputToJSON,
} from './OrderItemsUpdateInput';
import type { OrderPaymentUpdateInput } from './OrderPaymentUpdateInput';
import {
    OrderPaymentUpdateInputFromJSON,
    OrderPaymentUpdateInputFromJSONTyped,
    OrderPaymentUpdateInputToJSON,
} from './OrderPaymentUpdateInput';
import type { OrderSource } from './OrderSource';
import {
    OrderSourceFromJSON,
    OrderSourceFromJSONTyped,
    OrderSourceToJSON,
} from './OrderSource';
import type { OrderStatus } from './OrderStatus';
import {
    OrderStatusFromJSON,
    OrderStatusFromJSONTyped,
    OrderStatusToJSON,
} from './OrderStatus';
import type { OrderTags } from './OrderTags';
import {
    OrderTagsFromJSON,
    OrderTagsFromJSONTyped,
    OrderTagsToJSON,
} from './OrderTags';
import type { StoreBranch } from './StoreBranch';
import {
    StoreBranchFromJSON,
    StoreBranchFromJSONTyped,
    StoreBranchToJSON,
} from './StoreBranch';
import type { TimeOfDay } from './TimeOfDay';
import {
    TimeOfDayFromJSON,
    TimeOfDayFromJSONTyped,
    TimeOfDayToJSON,
} from './TimeOfDay';

/**
 * 
 * @export
 * @interface OrderUpdateInput
 */
export interface OrderUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateInput
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateInput
     */
    cpf?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateInput
     */
    cnpj?: string;
    /**
     * 
     * @type {AddressUpdateInput}
     * @memberof OrderUpdateInput
     */
    deliveryAddress?: AddressUpdateInput;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateInput
     */
    nf?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateInput
     */
    siteOrderNumber?: string;
    /**
     * 
     * @type {OrderSource}
     * @memberof OrderUpdateInput
     */
    source?: OrderSource;
    /**
     * 
     * @type {Array<OrderTags>}
     * @memberof OrderUpdateInput
     */
    tags?: Array<OrderTags>;
    /**
     * 
     * @type {Date}
     * @memberof OrderUpdateInput
     */
    scheduledDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderUpdateInput
     */
    returnDate?: Date;
    /**
     * 
     * @type {TimeOfDay}
     * @memberof OrderUpdateInput
     */
    timeOfDay?: TimeOfDay;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdateInput
     */
    notes?: string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderUpdateInput
     */
    status?: OrderStatus;
    /**
     * 
     * @type {number}
     * @memberof OrderUpdateInput
     */
    deliveryFee?: number;
    /**
     * 
     * @type {Array<OrderItemsUpdateInput>}
     * @memberof OrderUpdateInput
     */
    items?: Array<OrderItemsUpdateInput>;
    /**
     * 
     * @type {Array<OrderEquipmentUpdateInput>}
     * @memberof OrderUpdateInput
     */
    orderEquipment?: Array<OrderEquipmentUpdateInput>;
    /**
     * 
     * @type {Array<OrderPaymentUpdateInput>}
     * @memberof OrderUpdateInput
     */
    payments?: Array<OrderPaymentUpdateInput>;
    /**
     * 
     * @type {Array<OrderContactUpdateInput>}
     * @memberof OrderUpdateInput
     */
    contacts?: Array<OrderContactUpdateInput>;
    /**
     * 
     * @type {Array<OrderDiscountUpdateInput>}
     * @memberof OrderUpdateInput
     */
    discounts?: Array<OrderDiscountUpdateInput>;
    /**
     * 
     * @type {StoreBranch}
     * @memberof OrderUpdateInput
     */
    branch?: StoreBranch;
}

/**
 * Check if a given object implements the OrderUpdateInput interface.
 */
export function instanceOfOrderUpdateInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrderUpdateInputFromJSON(json: any): OrderUpdateInput {
    return OrderUpdateInputFromJSONTyped(json, false);
}

export function OrderUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'cpf': !exists(json, 'cpf') ? undefined : json['cpf'],
        'cnpj': !exists(json, 'cnpj') ? undefined : json['cnpj'],
        'deliveryAddress': !exists(json, 'deliveryAddress') ? undefined : AddressUpdateInputFromJSON(json['deliveryAddress']),
        'nf': !exists(json, 'nf') ? undefined : json['nf'],
        'siteOrderNumber': !exists(json, 'siteOrderNumber') ? undefined : json['siteOrderNumber'],
        'source': !exists(json, 'source') ? undefined : OrderSourceFromJSON(json['source']),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(OrderTagsFromJSON)),
        'scheduledDate': !exists(json, 'scheduledDate') ? undefined : (new Date(json['scheduledDate'])),
        'returnDate': !exists(json, 'returnDate') ? undefined : (new Date(json['returnDate'])),
        'timeOfDay': !exists(json, 'timeOfDay') ? undefined : TimeOfDayFromJSON(json['timeOfDay']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'status': !exists(json, 'status') ? undefined : OrderStatusFromJSON(json['status']),
        'deliveryFee': !exists(json, 'deliveryFee') ? undefined : json['deliveryFee'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(OrderItemsUpdateInputFromJSON)),
        'orderEquipment': !exists(json, 'orderEquipment') ? undefined : ((json['orderEquipment'] as Array<any>).map(OrderEquipmentUpdateInputFromJSON)),
        'payments': !exists(json, 'payments') ? undefined : ((json['payments'] as Array<any>).map(OrderPaymentUpdateInputFromJSON)),
        'contacts': !exists(json, 'contacts') ? undefined : ((json['contacts'] as Array<any>).map(OrderContactUpdateInputFromJSON)),
        'discounts': !exists(json, 'discounts') ? undefined : ((json['discounts'] as Array<any>).map(OrderDiscountUpdateInputFromJSON)),
        'branch': !exists(json, 'branch') ? undefined : StoreBranchFromJSON(json['branch']),
    };
}

export function OrderUpdateInputToJSON(value?: OrderUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'cpf': value.cpf,
        'cnpj': value.cnpj,
        'deliveryAddress': AddressUpdateInputToJSON(value.deliveryAddress),
        'nf': value.nf,
        'siteOrderNumber': value.siteOrderNumber,
        'source': OrderSourceToJSON(value.source),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(OrderTagsToJSON)),
        'scheduledDate': value.scheduledDate === undefined ? undefined : (value.scheduledDate.toISOString()),
        'returnDate': value.returnDate === undefined ? undefined : (value.returnDate.toISOString()),
        'timeOfDay': TimeOfDayToJSON(value.timeOfDay),
        'notes': value.notes,
        'status': OrderStatusToJSON(value.status),
        'deliveryFee': value.deliveryFee,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(OrderItemsUpdateInputToJSON)),
        'orderEquipment': value.orderEquipment === undefined ? undefined : ((value.orderEquipment as Array<any>).map(OrderEquipmentUpdateInputToJSON)),
        'payments': value.payments === undefined ? undefined : ((value.payments as Array<any>).map(OrderPaymentUpdateInputToJSON)),
        'contacts': value.contacts === undefined ? undefined : ((value.contacts as Array<any>).map(OrderContactUpdateInputToJSON)),
        'discounts': value.discounts === undefined ? undefined : ((value.discounts as Array<any>).map(OrderDiscountUpdateInputToJSON)),
        'branch': StoreBranchToJSON(value.branch),
    };
}

