import { Grid, Typography, Box } from "@mui/material";
import { CustomerOutput } from "../../generated-api";
import { usePhrases } from "../../context/PhrasesContext";
import { CustomerContactsTable } from "./CustomerContactsTable";
import { formatDateTime } from "../../utils/formatDateTime";
import { formatAddress } from "../../utils/formatAddress";

export interface OrderCustomerDetailsProps {
  customer: CustomerOutput;
  disableContactTable?: boolean;
}

export const OrderCustomerDetails: React.FC<OrderCustomerDetailsProps> = ({
  customer,
  disableContactTable = false,
}) => {
  const { getPhrase } = usePhrases();

  return (
    <Grid item container xs={12} spacing={0}>
      <Grid item xs={12}>
        <Typography variant="body1">
          <strong>{getPhrase("CUSTOMER_ID")}:</strong> {customer?.id}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>{getPhrase("FIRST_NAME")}:</strong> {customer?.firstName}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>{getPhrase("LAST_NAME")}:</strong> {customer?.lastName}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>{getPhrase("CPF")}:</strong> {customer?.cpf}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>{getPhrase("CNPJ")}:</strong> {customer?.cnpj}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>{getPhrase("EMAIL")}:</strong> {customer?.email}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>{getPhrase("SECONDARY_EMAIL")}:</strong> {customer?.email2}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>{getPhrase("PHONE")}:</strong> {customer?.phone}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>{getPhrase("SECONDARY_PHONE")}:</strong> {customer?.phone2}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>{getPhrase("DOB")}:</strong>{" "}
          {customer?.dob
            ? formatDateTime(customer.dob)
            : getPhrase("NOT_AVAIABLE")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <strong>{getPhrase("PRIMARY_ADDRESS")}:</strong>{" "}
          {formatAddress(customer?.address)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <strong>{getPhrase("SECONDARY_ADDRESS")}:</strong>{" "}
          {customer?.address2
            ? formatAddress(customer?.address2)
            : getPhrase("NOT_AVAIABLE")}
        </Typography>
      </Grid>

      {!disableContactTable && (
        <Grid item xs={12}>
          {/* Contacts Section */}
          <Box mt={1}>
            <Typography gutterBottom variant="h6">
              {getPhrase("CUSTOMER_CONTACTS")}
            </Typography>
            <CustomerContactsTable contacts={customer?.contacts ?? []} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
