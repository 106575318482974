/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomerCreateInput,
  CustomerOutput,
  CustomerStatus,
  CustomerUpdateInput,
  PagedOutputCustomerOutput,
} from '../models/index';
import {
    CustomerCreateInputFromJSON,
    CustomerCreateInputToJSON,
    CustomerOutputFromJSON,
    CustomerOutputToJSON,
    CustomerStatusFromJSON,
    CustomerStatusToJSON,
    CustomerUpdateInputFromJSON,
    CustomerUpdateInputToJSON,
    PagedOutputCustomerOutputFromJSON,
    PagedOutputCustomerOutputToJSON,
} from '../models/index';

export interface AddFileToCustomerRequest {
    customerId: string;
    file: Blob;
    displayName: string;
    customerMediaCategory: string;
    description?: string;
}

export interface CreateCustomerRequest {
    customerCreateInput: CustomerCreateInput;
}

export interface DeleteCustomerRequest {
    customerId: string;
}

export interface DeleteFileFromCustomerRequest {
    customerId: string;
    mediaId: string;
}

export interface GetCustomerRequest {
    customerId: string;
}

export interface GetCustomersRequest {
    page?: number;
    perPage?: number;
    cnpj?: string;
    cpf?: string;
    search?: string;
    status?: CustomerStatus;
}

export interface UpdateCustomerRequest {
    customerId: string;
    customerUpdateInput: CustomerUpdateInput;
}

/**
 * 
 */
export class CustomerApi extends runtime.BaseAPI {

    /**
     * Add a file to a customer
     */
    async addFileToCustomerRaw(requestParameters: AddFileToCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerOutput>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling addFileToCustomer.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addFileToCustomer.');
        }

        if (requestParameters.displayName === null || requestParameters.displayName === undefined) {
            throw new runtime.RequiredError('displayName','Required parameter requestParameters.displayName was null or undefined when calling addFileToCustomer.');
        }

        if (requestParameters.customerMediaCategory === null || requestParameters.customerMediaCategory === undefined) {
            throw new runtime.RequiredError('customerMediaCategory','Required parameter requestParameters.customerMediaCategory was null or undefined when calling addFileToCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.displayName !== undefined) {
            formParams.append('displayName', requestParameters.displayName as any);
        }

        if (requestParameters.customerMediaCategory !== undefined) {
            formParams.append('customerMediaCategory', requestParameters.customerMediaCategory as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        const response = await this.request({
            path: `/v1/customers/{customerId}/files`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerOutputFromJSON(jsonValue));
    }

    /**
     * Add a file to a customer
     */
    async addFileToCustomer(requestParameters: AddFileToCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerOutput> {
        const response = await this.addFileToCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a customer
     */
    async createCustomerRaw(requestParameters: CreateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerOutput>> {
        if (requestParameters.customerCreateInput === null || requestParameters.customerCreateInput === undefined) {
            throw new runtime.RequiredError('customerCreateInput','Required parameter requestParameters.customerCreateInput was null or undefined when calling createCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerCreateInputToJSON(requestParameters.customerCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerOutputFromJSON(jsonValue));
    }

    /**
     * Create a customer
     */
    async createCustomer(requestParameters: CreateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerOutput> {
        const response = await this.createCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Soft delete (set status to inactive) a customer
     */
    async deleteCustomerRaw(requestParameters: DeleteCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Soft delete (set status to inactive) a customer
     */
    async deleteCustomer(requestParameters: DeleteCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCustomerRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a file from a customer
     */
    async deleteFileFromCustomerRaw(requestParameters: DeleteFileFromCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerOutput>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteFileFromCustomer.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling deleteFileFromCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}/files/{mediaId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerOutputFromJSON(jsonValue));
    }

    /**
     * Delete a file from a customer
     */
    async deleteFileFromCustomer(requestParameters: DeleteFileFromCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerOutput> {
        const response = await this.deleteFileFromCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customer by id
     */
    async getCustomerRaw(requestParameters: GetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerOutput>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerOutputFromJSON(jsonValue));
    }

    /**
     * Get customer by id
     */
    async getCustomer(requestParameters: GetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerOutput> {
        const response = await this.getCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get multiple customers
     */
    async getCustomersRaw(requestParameters: GetCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedOutputCustomerOutput>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.cnpj !== undefined) {
            queryParameters['cnpj'] = requestParameters.cnpj;
        }

        if (requestParameters.cpf !== undefined) {
            queryParameters['cpf'] = requestParameters.cpf;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedOutputCustomerOutputFromJSON(jsonValue));
    }

    /**
     * Get multiple customers
     */
    async getCustomers(requestParameters: GetCustomersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedOutputCustomerOutput> {
        const response = await this.getCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update customer
     */
    async updateCustomerRaw(requestParameters: UpdateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerOutput>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateCustomer.');
        }

        if (requestParameters.customerUpdateInput === null || requestParameters.customerUpdateInput === undefined) {
            throw new runtime.RequiredError('customerUpdateInput','Required parameter requestParameters.customerUpdateInput was null or undefined when calling updateCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerUpdateInputToJSON(requestParameters.customerUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerOutputFromJSON(jsonValue));
    }

    /**
     * Update customer
     */
    async updateCustomer(requestParameters: UpdateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerOutput> {
        const response = await this.updateCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
