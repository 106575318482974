import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Container,
  Menu,
  MenuItem,
  Button,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import { formatAddress } from "../../../utils/formatAddress";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CustomerMediaOutput, CustomerOutput } from "../../../generated-api";
import { useSnackbar } from "../../../context/SnackbarContext";
import { customerService } from "../../../services/CustomerApi";
import { usePhrases } from "../../../context/PhrasesContext";
import { CustomerOdersTable } from "../../layout/CustomerOrdersTable";
import { formatDateTime } from "../../../utils/formatDateTime";
import { CustomerContactsTable } from "../../layout/CustomerContactsTable";
import { authFetch } from "../../../utils/authFetch";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import FileUploadDialog from "../../common/FileUploadDialog";

export const CustomerDetailsPage: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const [customer, setCustomer] = useState<CustomerOutput | null>(null);
  const [loadingCustomer, setLoadingCustomer] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [selectedDocumentForDeletion, setSelectedDocumentForDeletion] =
    useState<CustomerMediaOutput | null>(null);

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);

  const [isDowloadingOrDeleting, setIsDownloadingOrDeleting] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const { getPhrase } = usePhrases();

  useEffect(() => {
    const fetchCustomerData = async () => {
      if (!customerId) {
        return;
      }
      try {
        const customerData = await customerService.getCustomer({ customerId });
        setCustomer(customerData);
      } catch (error) {
        console.error("Error:", error);
        showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
      } finally {
        setLoadingCustomer(false);
      }
    };

    fetchCustomerData();
  }, [customerId, showMessage, getPhrase]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`/customers/${customerId}/edit`);
  };

  const handleCreateOrder = () => {
    navigate(`/customers/${customerId}/orders/create`);
  };

  const handleDownloadMedia = async (media: CustomerMediaOutput) => {
    setIsDownloadingOrDeleting(true);
    try {
      // const response = await mediaService.getMedia({ key });
      const response = await authFetch(
        `${process.env.REACT_APP_API_URL}/v1/media?key=${media.key}`
      );
      if (!response.ok) {
        console.error("Error downloading file:", response.statusText);
        showMessage(getPhrase("ERROR.GENERIC.DOWNLOAD_FILE"), "error");
        return;
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = media.originalname;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
      showMessage(getPhrase("ERROR.GENERIC.DOWNLOAD_FILE"), "error");
    } finally {
      setIsDownloadingOrDeleting(false);
      showMessage(getPhrase("FILE_DOWNLOADED_SUCCESSFULLY"), "success");
    }
  };

  const handleDeleteMedia = async (media: CustomerMediaOutput) => {
    setIsDownloadingOrDeleting(true);
    setIsDeleteDialogOpen(true);
    setSelectedDocumentForDeletion(media);
  };

  const handleCloseConfirmationDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedDocumentForDeletion(null);
    setIsDownloadingOrDeleting(false);
  };

  const performDeletion = async () => {
    if (!selectedDocumentForDeletion || !customer) return;

    setIsDownloadingOrDeleting(true);
    try {
      // Perform the deletion operation with the selected document
      await customerService.deleteFileFromCustomer({
        customerId: customer.id,
        mediaId: selectedDocumentForDeletion.id,
      });
      showMessage(getPhrase("FILE_DELETED_SUCCESSFULLY"), "success");
      // refetch the customer data
      const customerData = await customerService.getCustomer({
        customerId: customer.id,
      });
      setCustomer(customerData);
    } catch (error) {
      console.error("Error deleting file:", error);
      showMessage(getPhrase("ERROR.GENERIC.DELETE_FILE"), "error");
    } finally {
      setIsDeleteDialogOpen(false);
      setIsDownloadingOrDeleting(false);
      setSelectedDocumentForDeletion(null);
    }
  };

  if (loadingCustomer) {
    return <CircularProgress />;
  }

  if (!customer) {
    return (
      <Typography variant="h6">{getPhrase("CUSTOMER_NOT_FOUND")}</Typography>
    );
  }

  return (
    <Container>
      <Box
        my={5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" gutterBottom>
          {getPhrase("CUSTOMER.DETAILS_PAGE.TITLE")}
        </Typography>
        <Button
          variant="contained"
          endIcon={<ArrowDropDownIcon />}
          onClick={handleClick}
        >
          {getPhrase("ACTIONS")}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleCreateOrder}>
            {getPhrase("CREATE_ORDER_FOR_CUSTOMER")}
          </MenuItem>
          <MenuItem onClick={handleEdit}>{getPhrase("EDIT_CUSTOMER")}</MenuItem>
        </Menu>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("CUSTOMER_ID")}:</strong> {customer.id}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>{getPhrase("FIRST_NAME")}:</strong> {customer.firstName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>{getPhrase("LAST_NAME")}:</strong> {customer.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>{getPhrase("DOB")}:</strong>{" "}
            {customer.dob
              ? formatDateTime(customer.dob)
              : getPhrase("NOT_AVAIABLE")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>{getPhrase("CPF")}:</strong> {customer.cpf}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>{getPhrase("CNPJ")}:</strong> {customer.cnpj}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>{getPhrase("EMAIL")}:</strong> {customer.email}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>{getPhrase("SECONDARY_EMAIL")}:</strong> {customer.email2}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>{getPhrase("PHONE")}:</strong> {customer.phone}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>{getPhrase("SECONDARY_PHONE")}:</strong> {customer.phone2}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("ADDRESS")}:</strong>{" "}
            {formatAddress(customer.address)}
          </Typography>
        </Grid>
        {customer.address2 && (
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>{getPhrase("SECONDARY_ADDRESS")}:</strong>{" "}
              {formatAddress(customer.address2)}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("NOTES")}:</strong> {customer.notes}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("CUSTOMER_STATUS")}:</strong>{" "}
            {customer.status === "ACTIVE"
              ? getPhrase("ACTIVE")
              : getPhrase("INACTIVE")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("CREATED_AT")}:</strong>{" "}
            {new Date(customer.createdAt).toLocaleString()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("UPDATED_AT")}:</strong>{" "}
            {new Date(customer.updatedAt).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>

      {/* Media Table */}
      <Box mt={5}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignContent={"center"}
        >
          <Typography variant="h4" gutterBottom>
            {getPhrase("CUSTOMER_DOCUMENTS")}
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setIsUploadDialogOpen(true);
              }}
            >
              {getPhrase("UPLOAD_FILE")}
            </Button>
          </Box>
        </Box>
        {/* TODO: Make the fetchCustomer function reusable to avoid the duplication below */}
        <FileUploadDialog
          onSucess={async () => {
            if (!customerId) {
              return;
            }
            try {
              const customerData = await customerService.getCustomer({
                customerId,
              });
              setCustomer(customerData);
            } catch (error) {
              console.error("Error:", error);
              showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
            }
          }}
          customerId={customer.id}
          onClose={() => setIsUploadDialogOpen(false)}
          open={isUploadDialogOpen}
        />
        {/* List media associated to documents {displayName, description, uploadedAt, button do download } */}
        <Table className="tableSmallFontSize13" size="small">
          <TableHead>
            <TableCell>{getPhrase("DOCUMENT_NAME")}</TableCell>
            <TableCell>{getPhrase("CUSTOMER_MEDIA_CATEGORY")}</TableCell>
            <TableCell>{getPhrase("DESCRIPTION")}</TableCell>
            <TableCell>{getPhrase("UPLOADED_AT")}</TableCell>
            <TableCell>{getPhrase("ACTIONS")}</TableCell>
          </TableHead>
          <TableBody>
            {customer.media.map((document) => (
              <TableRow key={document.id}>
                <TableCell>{document.displayName}</TableCell>
                <TableCell>
                  {getPhrase(
                    `CUSTOMER_MEDIA_CATEGORY.${document.customerMediaCategory}`
                  )}
                </TableCell>
                <TableCell>{document.description}</TableCell>
                <TableCell>
                  {new Date(document.uploadedAt).toLocaleString()}
                </TableCell>
                <TableCell>
                  <Button
                    sx={{ mr: 1 }}
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      handleDownloadMedia(document);
                    }}
                    disabled={isDowloadingOrDeleting}
                  >
                    {getPhrase("DOWNLOAD")}
                  </Button>

                  <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      handleDeleteMedia(document);
                    }}
                    disabled={isDowloadingOrDeleting}
                  >
                    {getPhrase("DELETE")}
                  </Button>
                  <ConfirmationDialog
                    title={`${getPhrase("DELETE_FILE")} - ${
                      selectedDocumentForDeletion?.displayName
                    }`}
                    message={getPhrase(
                      "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_FILE"
                    )}
                    onClose={handleCloseConfirmationDialog}
                    onConfirm={performDeletion}
                    open={isDeleteDialogOpen}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      {/* Contacts Section */}
      <Box mt={5}>
        <Typography gutterBottom variant="h4">
          {getPhrase("CUSTOMER_CONTACTS")}
        </Typography>
        <CustomerContactsTable contacts={customer.contacts} />
      </Box>

      {/* Orders Section */}
      <Box mt={5}>
        <Typography gutterBottom variant="h4">
          {getPhrase("ORDERS")}
        </Typography>
        <Box>
          <CustomerOdersTable customerId={customer.id} />
        </Box>
      </Box>
    </Container>
  );
};
