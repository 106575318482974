/**
 * Removes falsy values from an object recursively.
 * If the object is empty after removing falsy values, it returns undefined to signal removal.
 * @param obj - The object to remove falsy values from.
 * @returns The object with falsy values removed, or undefined if the object is empty.
 */
export const removeFalsy = (obj: any): any => {
  // Check if obj is a Date object and return it as is
  if (obj instanceof Date) {
    return obj;
  }

  // Handle object types (excluding Date and Array)
  if (typeof obj === "object" && obj !== null && !Array.isArray(obj)) {
    const filteredObj = Object.entries(obj)
      .filter(([k, v]) => {
        // Keep Date objects and non-falsy values
        return (
          v instanceof Date || ![undefined, null, ""].includes(v as string)
        );
      })
      .reduce((r, [key, value]) => ({ ...r, [key]: removeFalsy(value) }), {});

    // Check if the object is empty after removing falsy values
    if (Object.keys(filteredObj).length === 0) {
      return undefined; // Return undefined to signal removal
    }

    return filteredObj;
  }

  // Return non-object values as is
  return obj;
};
