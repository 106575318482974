/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CustomerStatus = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type CustomerStatus = typeof CustomerStatus[keyof typeof CustomerStatus];


export function CustomerStatusFromJSON(json: any): CustomerStatus {
    return CustomerStatusFromJSONTyped(json, false);
}

export function CustomerStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerStatus {
    return json as CustomerStatus;
}

export function CustomerStatusToJSON(value?: CustomerStatus | null): any {
    return value as any;
}

