import React, { useCallback, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { orderService } from "../../../services/OrderApi";
import { usePhrases } from "../../../context/PhrasesContext";
import { useNavigate } from "react-router-dom";
import { AddressOutput } from "../../../generated-api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

interface PartialOrderOutput {
  id: string;
  key: string;
  customer: {
    name: string;
  };
  totalAmount: number;
  deliveryAddress?: AddressOutput;
}

export const UpcomingOrders: React.FC = () => {
  dayjs.extend(utc);
  const { getPhrase } = usePhrases();
  const [orders, setOrders] = React.useState<{
    day: {
      date: string;
      orders: PartialOrderOutput[];
      count: number;
    }[];
  }>({
    day: [],
  });

  const [loadingOrders, setLoadingOrders] = React.useState<boolean>(true);
  const navigate = useNavigate();

  const MAX_ORDERS_TO_SHOW = 10;

  const fetchUpcomingOrders = useCallback(async () => {
    const datesToFetch = [
      dayjs().format("YYYY-MM-DD"),
      dayjs().add(1, "day").format("YYYY-MM-DD"),
      dayjs().add(2, "days").format("YYYY-MM-DD"),
    ];

    const promises = await Promise.allSettled(
      datesToFetch.map((date) =>
        orderService.getManyOrders({
          scheduledDateGte: new Date(date),
          scheduledDateLte: new Date(date),
          perPage: 100,
        })
      )
    );
    console.log(
      "fetchedOrders",
      promises.map((p) => p.status === "fulfilled" && p.value.data)
    );

    const day = promises.map((p, i) => ({
      date: datesToFetch[i],
      orders:
        p.status === "fulfilled"
          ? p.value.data.map(({ id, customer, totalAmount, key }) => ({
              id,
              key,
              customer: {
                name: customer?.firstName + " " + customer?.lastName,
              },
              totalAmount,
              deliveryAddress: customer?.address,
            }))
          : [],
      count: p.status === "fulfilled" ? p.value.totalCount : 0,
    }));

    setOrders({
      ...orders,
      day,
    });

    setLoadingOrders(false);
  }, [orders]);

  useEffect(() => {
    if (loadingOrders) fetchUpcomingOrders();
  }, [fetchUpcomingOrders, loadingOrders]);

  // Return TODAY, TOMORROW, and AFTER TOMORROW
  const getDayLabel = (date: string): string => {
    const today = dayjs().format("YYYY-MM-DD");
    const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD");
    const afterTomorrow = dayjs().add(2, "day").format("YYYY-MM-DD");

    if (date === today) {
      return `(${getPhrase("TODAY")})`;
    }
    if (date === tomorrow) {
      return `(${getPhrase("TOMORROW")})`;
    }
    if (date === afterTomorrow) {
      return `(${getPhrase("AFTER_TOMORROW")})`;
    }
    return "";
  };

  return (
    <Grid item xs={12}>
      {loadingOrders && <CircularProgress />}
      {!loadingOrders && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              {getPhrase("DASHBOARD_PAGE.UPCOMING_ORDERS")}
            </Typography>
          </Grid>
          {orders.day.map((day) => (
            <Grid item sm={12} lg={6} xl={4} key={day.date}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" component="h2" align="left" mb={2}>
                    {day.date} {getDayLabel(day.date)}
                  </Typography>
                  <Box>
                    <Typography variant="body1" mb={2}>
                      {getPhrase("NUMBER_OF_ORDERS")}: {day.count}
                    </Typography>
                    <Box>
                      {day.orders
                        .slice(0, MAX_ORDERS_TO_SHOW)
                        .map((order, i) => {
                          return (
                            <Box key={order.id}>
                              <Button
                                style={{
                                  marginBottom: "0.05rem",
                                  textAlign: "left",
                                }}
                                size="small"
                                onClick={() => navigate(`/orders/${order.id}`)}
                              >
                                {order.key} - {order.customer.name} -{" "}
                                {order.deliveryAddress?.neighborhood} -{" "}
                                {order.deliveryAddress?.city}
                              </Button>
                            </Box>
                          );
                        })}
                      {day.count > MAX_ORDERS_TO_SHOW && (
                        <Button>
                          + {day.count - MAX_ORDERS_TO_SHOW}{" "}
                          {getPhrase("ORDERS")}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
