import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { usePhrases } from "../../context/PhrasesContext";
import { CustomerContactOutput } from "../../generated-api";

export interface CustomerContactsTableProps {
  contacts: CustomerContactOutput[];
}

export const CustomerContactsTable: React.FC<CustomerContactsTableProps> = ({
  contacts,
}) => {
  const { getPhrase } = usePhrases();

  return (
    <TableContainer>
      <Table
        className="tableSmallFontSize13"
        size="small"
        stickyHeader
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            <TableCell width={"200px"}>{getPhrase("FIRST_NAME")}</TableCell>
            <TableCell>{getPhrase("LAST_NAME")}</TableCell>
            <TableCell>{getPhrase("PHONE")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact) => (
            <TableRow hover key={contact.firstName + contact.lastName}>
              <TableCell>{contact.firstName}</TableCell>
              <TableCell>{contact.lastName}</TableCell>
              <TableCell>{contact.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
