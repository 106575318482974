import { AddressOutput } from "../generated-api";

export const formatAddress = (address: AddressOutput) => {
  const {
    street,
    city,
    state,
    postalCode,
    additionalInfo,
    neighborhood,
    number,
  } = address;
  let addressString = `${street}, ${number}, ${neighborhood}, ${city}, ${state}, ${postalCode}`;
  if (additionalInfo) {
    addressString += `, ${additionalInfo}`;
  }
  return addressString;
};

export const formatAddressShort = (address: AddressOutput) => {
  const { street, city, neighborhood, number } = address;

  return `${number} ${street}, ${neighborhood}, ${city}`;
};
