import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { AuthContext } from "../context/AuthContext";
import { LoginPage } from "../components/views/LoginPage";
import PrivateRoute from "./PrivateRoute";
import { Action, Resources, buildPermission } from "../utils/authHelpers";
import { DashboardPage } from "../components/views/DashboardPage";
import { ForbiddenPage } from "../components/views/ForbiddenPage";
import { CustomerListPage } from "../components/views/Customer/CustomerListPage";
import { OrderListPage } from "../components/views/Order/OrderListPage";
import { UserListPage } from "../components/views/User/UserListPage";
import { MainLayout } from "../components/layout/MainLayout";
import { NotFoundPage } from "../components/views/NotFoundPage";
import { CustomerCreatePage } from "../components/views/Customer/CustomerCreatePage";
import { CustomerDetailsPage } from "../components/views/Customer/CustomerDetailsPage";
import { UserDetailsPage } from "../components/views/User/UserDetailsPage";
import { UserCreateAndEditPage } from "../components/views/User/UserCreateAndEditPage";
import { OrderCreatePage } from "../components/views/Order/OrderCreatePage";
import { OrderDetailsPage } from "../components/views/Order/OrderDetailsPage";
import { CustomerEditPage } from "../components/views/Customer/CustomerEditPage";
import { OrderEditPage } from "../components/views/Order/OrderEditPage";
import { OrderPrintPage } from "../components/views/Order/OrderPrintPage";
import { ProductListPage } from "../components/views/Product/ProductListPage";
import { EquipmentListPage } from "../components/views/Equipment/EquipmentListPage";
import { ProductCreateAndEditPage } from "../components/views/Product/ProductCreateAndEditPage";
import { EquipmentCreateAndEditPage } from "../components/views/Equipment/EquipmentCreateAndEditPage";
import { OrderPaymentsPage } from "../components/views/Order/OrderPaymentsPage";
import { AnalyticsListPage } from "../components/views/Analytics/AnalyticsListPage";
import { OrderSummarizedDataByDay } from "../components/views/Analytics/OrderSummarizedDataByDay";
import { DiscountListPage } from "../components/views/Discounts/DiscountListPage";
import { DiscountCreateAndEditPage } from "../components/views/Discounts/DiscountCreateAndEditPage";

export const MainRouter: React.FC = () => {
  const { authState } = useContext(AuthContext);

  return (
    <Routes>
      {/* Public Route */}
      <Route path="/login" element={<LoginPage />} />

      {/* Private Routes */}
      {authState.isAuthenticated && (
        <Route
          path="*"
          element={
            <MainLayout>
              <Routes>
                {/* Dashboard Page */}
                <Route
                  index
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.CUSTOMERS, Action.LIST),
                      ]}
                    >
                      <DashboardPage />
                    </PrivateRoute>
                  }
                />
                {/* Analytics */}
                <Route
                  path="analytics/summarized-order-data-by-day"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.CUSTOMERS, Action.LIST),
                      ]}
                    >
                      <OrderSummarizedDataByDay />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="analytics"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.CUSTOMERS, Action.LIST),
                      ]}
                    >
                      <AnalyticsListPage />
                    </PrivateRoute>
                  }
                />
                {/* Customers */}
                <Route
                  path="customers/:customerId/orders/create"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.CUSTOMERS, Action.GET),
                        buildPermission(Resources.ORDERS, Action.CREATE),
                      ]}
                    >
                      <OrderCreatePage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="customers/:customerId/edit"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.CUSTOMERS, Action.UPDATE),
                      ]}
                    >
                      <CustomerEditPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="customers/:customerId"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.CUSTOMERS, Action.GET),
                      ]}
                    >
                      <CustomerDetailsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="customers/create"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.CUSTOMERS, Action.CREATE),
                      ]}
                    >
                      <CustomerCreatePage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="customers"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.CUSTOMERS, Action.LIST),
                      ]}
                    >
                      <CustomerListPage />
                    </PrivateRoute>
                  }
                />
                {/* Users */}
                <Route
                  path="users/create"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.USERS_ADMIN, Action.CREATE),
                      ]}
                    >
                      <UserCreateAndEditPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="users/:userId/edit"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.USERS_ADMIN, Action.CREATE),
                      ]}
                    >
                      <UserCreateAndEditPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="users/:userId"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.USERS, Action.GET),
                      ]}
                    >
                      <UserDetailsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="users"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.USERS, Action.LIST),
                      ]}
                    >
                      <UserListPage />
                    </PrivateRoute>
                  }
                />
                {/* Orders */}
                <Route
                  path="orders/:orderId/payments"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.ORDERS, Action.UPDATE),
                      ]}
                    >
                      <OrderPaymentsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="orders/:orderId/edit"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.ORDERS, Action.UPDATE),
                      ]}
                    >
                      <OrderEditPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="orders/:orderId/print"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.ORDERS, Action.GET),
                      ]}
                    >
                      <OrderPrintPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="orders/:orderId"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.ORDERS, Action.GET),
                      ]}
                    >
                      <OrderDetailsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="orders"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.ORDERS, Action.LIST),
                      ]}
                    >
                      <OrderListPage />
                    </PrivateRoute>
                  }
                />
                {/* Products */}
                <Route
                  path="products/create"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.ORDERS, Action.CREATE),
                      ]}
                    >
                      <ProductCreateAndEditPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="products/:productId/edit"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.ORDERS, Action.UPDATE),
                      ]}
                    >
                      <ProductCreateAndEditPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="products"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.PRODUCTS, Action.LIST),
                      ]}
                    >
                      <ProductListPage />
                    </PrivateRoute>
                  }
                />
                {/* Equipments */}
                <Route
                  path="equipments/create"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.EQUIPMENTS, Action.CREATE),
                      ]}
                    >
                      <EquipmentCreateAndEditPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="equipments/:equipmentId/edit"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.EQUIPMENTS, Action.UPDATE),
                      ]}
                    >
                      <EquipmentCreateAndEditPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="equipments"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.EQUIPMENTS, Action.LIST),
                      ]}
                    >
                      <EquipmentListPage />
                    </PrivateRoute>
                  }
                />
                {/* Discounts */}
                <Route
                  path="discounts/:discountId/edit"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.DISCOUNTS, Action.UPDATE),
                      ]}
                    >
                      <DiscountCreateAndEditPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="discounts/create"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.DISCOUNTS, Action.CREATE),
                      ]}
                    >
                      <DiscountCreateAndEditPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="discounts"
                  element={
                    <PrivateRoute
                      requiredPermissions={[
                        buildPermission(Resources.DISCOUNTS, Action.LIST),
                      ]}
                    >
                      <DiscountListPage />
                    </PrivateRoute>
                  }
                />
                {/* Fallbacks */}
                <Route
                  path="unauthorized"
                  element={
                    <PrivateRoute requiredPermissions={[]}>
                      <ForbiddenPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateRoute requiredPermissions={[]}>
                      <NotFoundPage />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </MainLayout>
          }
        ></Route>
      )}

      {/* Fallback Public Route */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
