/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductStatus = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type ProductStatus = typeof ProductStatus[keyof typeof ProductStatus];


export function ProductStatusFromJSON(json: any): ProductStatus {
    return ProductStatusFromJSONTyped(json, false);
}

export function ProductStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductStatus {
    return json as ProductStatus;
}

export function ProductStatusToJSON(value?: ProductStatus | null): any {
    return value as any;
}

