/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderMetadataOutputUndefined } from './OrderMetadataOutputUndefined';
import {
    OrderMetadataOutputUndefinedFromJSON,
    OrderMetadataOutputUndefinedFromJSONTyped,
    OrderMetadataOutputUndefinedToJSON,
} from './OrderMetadataOutputUndefined';

/**
 * 
 * @export
 * @interface OrderMetadataOutput
 */
export interface OrderMetadataOutput {
    /**
     * 
     * @type {OrderMetadataOutputUndefined}
     * @memberof OrderMetadataOutput
     */
    undefined?: OrderMetadataOutputUndefined;
}

/**
 * Check if a given object implements the OrderMetadataOutput interface.
 */
export function instanceOfOrderMetadataOutput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrderMetadataOutputFromJSON(json: any): OrderMetadataOutput {
    return OrderMetadataOutputFromJSONTyped(json, false);
}

export function OrderMetadataOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderMetadataOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'undefined': !exists(json, 'undefined') ? undefined : OrderMetadataOutputUndefinedFromJSON(json['undefined']),
    };
}

export function OrderMetadataOutputToJSON(value?: OrderMetadataOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'undefined': OrderMetadataOutputUndefinedToJSON(value.undefined),
    };
}

