import React from "react";
import { Sidebar } from "./Sidebar";

interface MainLayoutProps {
  children?: React.ReactNode;
}

// Can provide additional wrapping if needed
export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return <Sidebar>{children}</Sidebar>;
};
