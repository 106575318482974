/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderMediaCategory } from './OrderMediaCategory';
import {
    OrderMediaCategoryFromJSON,
    OrderMediaCategoryFromJSONTyped,
    OrderMediaCategoryToJSON,
} from './OrderMediaCategory';

/**
 * 
 * @export
 * @interface OrderMediaOutput
 */
export interface OrderMediaOutput {
    /**
     * 
     * @type {string}
     * @memberof OrderMediaOutput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderMediaOutput
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderMediaOutput
     */
    originalname: string;
    /**
     * 
     * @type {string}
     * @memberof OrderMediaOutput
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderMediaOutput
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof OrderMediaOutput
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof OrderMediaOutput
     */
    size: number;
    /**
     * 
     * @type {Date}
     * @memberof OrderMediaOutput
     */
    uploadedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderMediaOutput
     */
    uploadedBy: string;
    /**
     * 
     * @type {OrderMediaCategory}
     * @memberof OrderMediaOutput
     */
    orderMediaCategory: OrderMediaCategory;
}

/**
 * Check if a given object implements the OrderMediaOutput interface.
 */
export function instanceOfOrderMediaOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "originalname" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "uploadedAt" in value;
    isInstance = isInstance && "uploadedBy" in value;
    isInstance = isInstance && "orderMediaCategory" in value;

    return isInstance;
}

export function OrderMediaOutputFromJSON(json: any): OrderMediaOutput {
    return OrderMediaOutputFromJSONTyped(json, false);
}

export function OrderMediaOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderMediaOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'originalname': json['originalname'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'key': json['key'],
        'type': json['type'],
        'size': json['size'],
        'uploadedAt': (new Date(json['uploadedAt'])),
        'uploadedBy': json['uploadedBy'],
        'orderMediaCategory': OrderMediaCategoryFromJSON(json['orderMediaCategory']),
    };
}

export function OrderMediaOutputToJSON(value?: OrderMediaOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'originalname': value.originalname,
        'description': value.description,
        'key': value.key,
        'type': value.type,
        'size': value.size,
        'uploadedAt': (value.uploadedAt.toISOString()),
        'uploadedBy': value.uploadedBy,
        'orderMediaCategory': OrderMediaCategoryToJSON(value.orderMediaCategory),
    };
}

