// NotFoundPage.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Typography, Box } from "@mui/material";
import { usePhrases } from "../../context/PhrasesContext";

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { getPhrase } = usePhrases();

  const goHome = () => {
    navigate("/");
  };

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h2" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
          {getPhrase("NOT_FOUND_PAGE.TITLE")}
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          {getPhrase("NOT_FOUND_PAGE.MESSAGE")}
        </Typography>
        <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={goHome}>
          {getPhrase("NOT_FOUND_PAGE.GO_TO_HOME")}
        </Button>
      </Box>
    </Container>
  );
};
