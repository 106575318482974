import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  Container,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import {
  OrderOutput,
  OrderUpdateInput,
  OrderPaymentUpdateInput,
  PaymentMethod,
} from "../../../generated-api";
import {
  orderService,
  orderUpdatePaymentsValidationSchema,
} from "../../../services/OrderApi";
import { useSnackbar } from "../../../context/SnackbarContext";
import { usePhrases } from "../../../context/PhrasesContext";
import { Chip } from "@mui/joy";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { removeFalsy } from "../../../utils/removeFalsy";
import { joiResolver } from "@hookform/resolvers/joi";
import { OrderValueSummary } from "../../layout/OrderValuesSummary";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export const OrderPaymentsPage: React.FC = () => {
  dayjs.extend(require("dayjs/plugin/utc"));
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<OrderOutput | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const { getPhrase } = usePhrases();

  // Form setup
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<{
    payments: OrderPaymentUpdateInput[];
  }>({
    resolver: joiResolver(orderUpdatePaymentsValidationSchema),
    defaultValues: {
      payments: [
        {
          amount: 0,
          paymentMethod: PaymentMethod.CreditCardVisa,
          notes: "",
          referenceCode: "",
          date: new Date(),
        },
      ],
    },
  });

  // Fetch order data
  const fetchOrderData = useCallback(
    async (orderId: string) => {
      try {
        const data = await orderService.getOneOrder({
          orderId,
          joinCustomer: false,
          joinEquipment: false,
          joinProduct: false,
        });

        setOrder(data);
        if (data.payments.length !== 0) {
          console.log(data.payments);
          console.log(data.payments.map((p) => dayjs(p.date)));
          reset({
            payments: data.payments.map((p) => ({
              paymentMethod: p.paymentMethod,
              amount: p.amount,
              notes: p.notes,
              referenceCode: p.referenceCode,
              date: p.date?.toISOString().split("T")[0] as unknown as Date,
            })),
          });
        }
      } catch (error) {
        console.error("Error:", error);
        showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
      } finally {
        setLoading(false);
      }
    },
    [showMessage, getPhrase, reset]
  );

  useEffect(() => {
    if (!orderId) return;

    fetchOrderData(orderId);
  }, [orderId, fetchOrderData]);

  const watchedPayments = watch("payments");

  // UseFieldArray for dynamic order items and equipments
  const {
    fields: orderPaymentsFields,
    append: appendOrderPayment,
    remove: removeOrderPayment,
  } = useFieldArray({
    control,
    name: "payments",
  });

  // Modify onSubmit for update logic
  const onSubmit: SubmitHandler<OrderUpdateInput> = async (data) => {
    if (!orderId) return;
    console.log("Submitting updated data:", data);
    try {
      // Logic to update the order
      const cleanedData: OrderUpdateInput = removeFalsy(data);
      console.log("Cleaned data:", cleanedData);
      await orderService.updateOrder({
        orderId,
        orderUpdateInput: {
          payments: (cleanedData.payments ?? []).map((payment) =>
            removeFalsy({
              paymentMethod: payment.paymentMethod,
              amount: payment.amount,
              notes: payment.notes,
              referenceCode: payment.referenceCode,
              date: payment.date,
            })
          ),
        },
      });
      showMessage(getPhrase("ORDER_UPDATED_SUCCESSFULLY"), "success");
      navigate(`/orders/${orderId}`);
    } catch (error) {
      showMessage(getPhrase("ERROR.GENERIC.UPDATE_DATA"), "error");
      console.error("Unexpected error when updating order:", error);
    }
  };

  // Mapping of PaymentMethod enum to its display values
  const paymentMethodOptions = Object.values(PaymentMethod).map((value) => ({
    value,
    label: getPhrase(`PAYMENT_METHOD.${value}`),
  }));

  console.log("errors:", errors);

  if (loading) {
    return <CircularProgress />;
  }

  if (!order) {
    return <Typography variant="h6">{getPhrase("ORDER_NOT_FOUND")}</Typography>;
  }

  return (
    <Container>
      <Box my={5}>
        <Typography component="h1" variant="h4">
          {getPhrase("ORDER_PAYMENTS_PAGE_TITLE")}
          <Chip size="lg" color="success" style={{ margin: "0 .5rem" }}>
            {order.branch}
          </Chip>
        </Typography>
      </Box>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* Dynamic Order Items */}
            <Grid
              mt={5}
              container
              item
              rowSpacing={2}
              columnSpacing={2}
              xs={12}
            >
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">
                    {getPhrase("ORDER_PAYMENTS")}
                  </Typography>
                  {/* Button to Add Payment */}
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() =>
                      appendOrderPayment({
                        amount: 0,
                        paymentMethod: PaymentMethod.CreditCardVisa,
                        notes: "",
                        referenceCode: "",
                        date: dayjs().startOf("day").toDate(),
                      })
                    }
                  >
                    {getPhrase("ORDER_ADD_PAYMENT")}
                  </Button>
                </Box>
              </Grid>
              {orderPaymentsFields.map((payment, index) => {
                // console.log(dayjs(payment.date).format("YYYY-MM-DD"));
                // console.log(dayjs(payment.date, { utc: true }));
                return (
                  <Grid
                    item
                    container
                    key={payment.id}
                    columnSpacing={1}
                    rowSpacing={2}
                    alignItems="center"
                    mb={3}
                  >
                    {/* Product Selection */}
                    {/* <Grid item xs={3}>
                    <Controller
                      name={`payments.${index}.paymentMethod`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Autocomplete
                            size="small"
                            options={Object.values(PaymentMethod)}
                            value={field.value}
                            onChange={(e, value) => field.onChange(value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={getPhrase("PAYMENT_METHOD")}
                                variant="outlined"
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Grid> */}
                    <Grid item xs={4}>
                      <Controller
                        name={`payments.${index}.paymentMethod`}
                        control={control}
                        render={({ field }) => {
                          // Finding the current option based on the field value
                          const currentOption = paymentMethodOptions.find(
                            (option) => option.value === field.value
                          );

                          return (
                            <Autocomplete
                              disableClearable
                              size="small"
                              options={paymentMethodOptions}
                              value={currentOption}
                              onChange={(event, option) =>
                                field.onChange(option ? option.value : "")
                              }
                              // Defines how each option is rendered in the dropdown
                              getOptionLabel={(option) => option.label}
                              // This is important for displaying the selected option in the input
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={getPhrase("PAYMENT_METHOD")}
                                  variant="outlined"
                                />
                              )}
                              // Filter options based on the label
                              filterOptions={(options, params) => {
                                const filtered = options.filter((option) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(params.inputValue.toLowerCase())
                                );
                                // Return the filtered options
                                return filtered;
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>

                    {/* Amount */}
                    <Grid item xs={2}>
                      <Controller
                        name={`payments.${index}.amount`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            label={getPhrase("AMOUNT")}
                            variant="outlined"
                            type="number"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>

                    {/* Date */}
                    <Grid item xs={4}>
                      <Controller
                        name={`payments.${index}.date`}
                        control={control}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...field}
                              label={getPhrase("PAYMENT_DATE")}
                              value={dayjs(field.value)}
                              format="DD/MM/YYYY"
                              onChange={(date) =>
                                field.onChange(date?.toDate())
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                            />
                            {
                              // Display error message if there is an error
                              errors.payments?.[index]?.date && (
                                <Typography color="error">
                                  {errors.payments[index]?.date?.message}
                                </Typography>
                              )
                            }
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>

                    {/* Remove Button */}
                    <Grid display={"flex"} justifyContent={"end"} item xs={2}>
                      <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={() => removeOrderPayment(index)}
                      >
                        {getPhrase("ORDER_REMOVE_PAYMENT")}
                      </Button>
                    </Grid>

                    {/* Notes */}
                    <Grid item xs={6}>
                      <Controller
                        name={`payments.${index}.notes`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            label={getPhrase("NOTES")}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>

                    {/* Reference Code */}
                    <Grid item xs={6}>
                      <Controller
                        name={`payments.${index}.referenceCode`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            label={getPhrase("ORDER_PAYMENT_REFERENCE_CODE")}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                );
              })}

              <OrderValueSummary
                order={order}
                payments={[...watchedPayments]}
              />
            </Grid>
          </Grid>

          <Box display={"flex"} justifyContent={"center"} my={5}>
            <Box width={"25%"}>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                onClick={() => navigate(`/orders/${orderId}`)}
                sx={{ mt: 3, mb: 2 }}
              >
                {getPhrase("RETURN_AND_NOT_SAVE")}
              </Button>
            </Box>
            <Box width={"25%"} mx={5}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                {getPhrase("SAVE")}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
};
