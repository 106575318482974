/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderStatus = {
    Pending: 'PENDING',
    Confirmed: 'CONFIRMED',
    OutForDelivery: 'OUT_FOR_DELIVERY',
    Delivered: 'DELIVERED',
    ReturnScheduled: 'RETURN_SCHEDULED',
    Returned: 'RETURNED',
    Closed: 'CLOSED',
    Verified: 'VERIFIED',
    Canceled: 'CANCELED'
} as const;
export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


export function OrderStatusFromJSON(json: any): OrderStatus {
    return OrderStatusFromJSONTyped(json, false);
}

export function OrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderStatus {
    return json as OrderStatus;
}

export function OrderStatusToJSON(value?: OrderStatus | null): any {
    return value as any;
}

