import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Container,
  Button,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { usePhrases } from "../../../context/PhrasesContext";
import { useSnackbar } from "../../../context/SnackbarContext";
import { UserOutput } from "../../../generated-api";
import { userService } from "../../../services/UserApi";

export const UserDetailsPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<UserOutput | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { getPhrase } = usePhrases();
  const { showMessage } = useSnackbar();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const data = await userService.getOneUser({ userId });
          setUser(data);
        } catch (error) {
          console.error("Error:", error);
          showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, [userId, showMessage, getPhrase]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`/users/${userId}/edit`);
    handleClose();
  };

  // Function to process permissions into a table format
  const processPermissions = (permissions: string[]) => {
    const actionSet = new Set<string>();
    const resourceMap: Record<string, Set<string>> = {};

    permissions.forEach((perm) => {
      const [resource, action] = perm.split(":");
      actionSet.add(action);
      if (!resourceMap[resource]) {
        resourceMap[resource] = new Set();
      }
      resourceMap[resource].add(action);
    });

    // Sort resources A-Z
    const sortedResources = Object.entries(resourceMap).sort(
      ([resourceA], [resourceB]) => resourceA.localeCompare(resourceB)
    );

    return { actions: Array.from(actionSet), resources: sortedResources };
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!user) {
    return <Typography variant="h6">User not found</Typography>;
  }

  const { actions, resources } = processPermissions(user.permissions);

  return (
    <Container>
      <Box
        my={5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" gutterBottom>
          {getPhrase(`USER_DETAILS`)}
        </Typography>
        <Button
          variant="contained"
          endIcon={<ArrowDropDownIcon />}
          onClick={handleClick}
        >
          {getPhrase(`ACTIONS`)}
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
        </Menu>
      </Box>
      <Grid container spacing={2}>
        {/* Displaying the user details */}
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("USER_ID")}:</strong> {user.id}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>{getPhrase("FIRST_NAME")}:</strong> {user.firstName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>{getPhrase("LAST_NAME")}:</strong> {user.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("EMAIL")}:</strong> {user.email}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("STATUS")}:</strong> {user.status}
          </Typography>
        </Grid>
      </Grid>
      <Box my={5}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Table size="small" aria-label="permissions table">
            <TableHead>
              <TableRow>
                <TableCell>{getPhrase("RESOURCE_AND_ACTION")}</TableCell>
                {actions.map((action) => (
                  <TableCell key={action} align="center">
                    {getPhrase(`ACTION.${action}` as any)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.map(([resource, actionsSet]) => (
                <TableRow key={resource}>
                  <TableCell>
                    {getPhrase(`RESOURCE.${resource}` as any)}
                  </TableCell>
                  {actions.map((action) => (
                    <TableCell key={action} align="center">
                      {actionsSet.has(action) ? "X" : ""}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </Container>
  );
};
