import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton,
  TableFooter,
  TablePagination,
  Typography,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from "../../constants/api";
import { OrderOutput } from "../../generated-api";
import { usePhrases } from "../../context/PhrasesContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { orderService } from "../../services/OrderApi";
import { formatAddressShort } from "../../utils/formatAddress";

export const CustomerOdersTable: React.FC<{ customerId: string }> = ({
  customerId,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);

  // Table state
  const [orders, setOrders] = useState<OrderOutput[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [loadingOrders, setLoadingOrders] = useState(true);

  const navigate = useNavigate();
  const { getPhrase } = usePhrases();
  const { showMessage } = useSnackbar();

  const handleClick = (
    orderId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedOrderId(orderId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewOrder = () => {
    setAnchorEl(null);
    if (selectedOrderId) {
      navigate(`/orders/${selectedOrderId}`);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    const fetchCustomerOrders = async () => {
      try {
        const customerOrders = await orderService.getManyOrders({
          customerId,
        });
        setOrders(customerOrders.data);
        setTotalCount(customerOrders.totalCount);
      } catch (error) {
        showMessage("Failed to fetch customer orders", "error");
        console.error("Error:", error);
      } finally {
        setLoadingOrders(false);
      }
    };

    fetchCustomerOrders();
  }, [customerId, showMessage]);

  if (loadingOrders) {
    return <CircularProgress />;
  }

  if (orders.length === 0) {
    return (
      <Typography>{getPhrase("NO_ORDERS_FOUND_FOR_THIS_CUSTOMER")}</Typography>
    );
  }

  return (
    <TableContainer>
      <Table
        className="tableSmallFontSize13"
        size="small"
        stickyHeader
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow>
            <TableCell width={"120px"}>{getPhrase("ORDER_KEY")}</TableCell>
            <TableCell width={"350px"}>
              {getPhrase("DELIVERY_ADDRESS")}
            </TableCell>
            <TableCell width={"135px"}>{getPhrase("SCHEDULED_DATE")}</TableCell>
            <TableCell width={"50px"}>{getPhrase("TIME_OF_DAY")}</TableCell>
            <TableCell width={"120px"}>{getPhrase("TOTAL_AMOUNT")}</TableCell>
            <TableCell width={"50px"}>
              {getPhrase("ORDER.LIST_PAGE.TYPE")}
            </TableCell>
            <TableCell width={"50px"}>{getPhrase("STATUS")}</TableCell>
            <TableCell sx={{ textAlign: "right" }}>
              {getPhrase("ACTIONS")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow hover key={order.id}>
              <TableCell>{order.key}</TableCell>
              <TableCell>{formatAddressShort(order.deliveryAddress)}</TableCell>
              <TableCell>
                {new Date(order.scheduledDate).toLocaleDateString()}
              </TableCell>
              <TableCell>
                {getPhrase(`TIME_OF_DAY.${order.timeOfDay}`)}
              </TableCell>
              <TableCell>{`${getPhrase(
                "CURRENCY.SYMBOL"
              )} ${order.totalAmount.toFixed(2)}`}</TableCell>
              <TableCell>
                {getPhrase(`ORDER_TYPE.${order.type}` as any)}
              </TableCell>
              <TableCell>
                {getPhrase(`ORDER_STATUS.${order.status}` as any)}
              </TableCell>
              <TableCell>
                <Box display={"flex"} justifyContent={"end"}>
                  <IconButton
                    sx={{ padding: 0 }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleClick(order.id, event)}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleViewOrder}>
                      {getPhrase("ORDER.LIST_PAGE.VIEW")}
                    </MenuItem>
                  </Menu>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Typography variant="body2" sx={{ p: 2 }}>
              {getPhrase("ORDER.LIST_PAGE.TOTAL_ORDERS")}: {totalCount}
            </Typography>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={totalCount}
              rowsPerPage={perPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
