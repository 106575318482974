import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Container,
  Button,
  MenuItem,
  Menu,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { formatAddress } from "../../../utils/formatAddress";
import {
  OrderPaymentOutput,
  OrderEnrichedOutput,
  OrderItemEnrichedOutput,
  OrderEquipmentEnrichedOutput,
  OrderMediaOutput,
  OrderStatus,
  CancellationReason,
  TransitionOrderStatusDeliveredRequest,
  OrderMediaCategory,
} from "../../../generated-api";
import { orderService } from "../../../services/OrderApi";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { usePhrases } from "../../../context/PhrasesContext";
import { Chip } from "@mui/joy";
import { formatDateTime } from "../../../utils/formatDateTime";
import { useSnackbar } from "../../../context/SnackbarContext";
import { OrderValueSummary } from "../../layout/OrderValuesSummary";
import FileUploadDialog from "../../common/FileUploadDialog";
import { authFetch } from "../../../utils/authFetch";
import {
  ConfirmationDialog,
  DialogTemplate,
} from "../../common/ConfirmationDialog";

export const OrderDetailsPage: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<OrderEnrichedOutput | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [selectedDocumentForDeletion, setSelectedDocumentForDeletion] =
    useState<OrderMediaOutput | null>(null);

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);

  const [isDowloadingOrDeleting, setIsDownloadingOrDeleting] =
    useState<boolean>(false);

  // Dialogs
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);
  const [isTransitionDialogOpen, setIsTransitionDialogOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { getPhrase } = usePhrases();
  const { showMessage } = useSnackbar();

  const fetchOrderData = useCallback(
    async (orderId: string) => {
      try {
        const data = await orderService.getOneOrder({
          orderId,
          joinCustomer: true,
          joinEquipment: true,
          joinProduct: true,
        });
        setOrder(data);
      } catch (error) {
        console.error("Error:", error);
        showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
      } finally {
        setLoading(false);
      }
    },
    [showMessage, getPhrase]
  );

  useEffect(() => {
    if (!orderId) return;

    fetchOrderData(orderId);
  }, [orderId, fetchOrderData]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`/orders/${orderId}/edit`);
    handleClose();
  };

  const handleGoToEditPaymentsPage = () => {
    navigate(`/orders/${orderId}/payments`);
    handleClose();
  };

  const handleGoToOrderCustomer = () => {
    if (order?.customerId) {
      navigate(`/customers/${order.customerId}`);
    }
  };

  const handleGoToPrintPage = () => {
    navigate(`/orders/${orderId}/print`);
    handleClose();
  };

  const printCurrencyValue = (value: number) => {
    return `${getPhrase("CURRENCY.SYMBOL")} ${value.toFixed(2)}`;
  };

  // TODO: I can probably extract the code here to a separate component that handles the media table and actions
  // This is very similar to what we have in the customer details page
  // Handlers for media related functionality
  const handleDownloadMedia = async (media: OrderMediaOutput) => {
    setIsDownloadingOrDeleting(true);
    try {
      // const response = await mediaService.getMedia({ key });
      const response = await authFetch(
        `${process.env.REACT_APP_API_URL}/v1/media?key=${media.key}`
      );
      if (!response.ok) {
        console.error("Error downloading file:", response.statusText);
        showMessage(getPhrase("ERROR.GENERIC.DOWNLOAD_FILE"), "error");
        return;
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = media.originalname;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
      showMessage(getPhrase("ERROR.GENERIC.DOWNLOAD_FILE"), "error");
    } finally {
      setIsDownloadingOrDeleting(false);
      showMessage(getPhrase("FILE_DOWNLOADED_SUCCESSFULLY"), "success");
    }
  };

  const handleDeleteMedia = async (media: OrderMediaOutput) => {
    setIsDownloadingOrDeleting(true);
    setIsDeleteDialogOpen(true);
    setSelectedDocumentForDeletion(media);
  };

  const handleCloseConfirmationDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedDocumentForDeletion(null);
    setIsDownloadingOrDeleting(false);
  };

  const performDeletion = async () => {
    if (!selectedDocumentForDeletion || !order) return;

    setIsDownloadingOrDeleting(true);
    try {
      // Perform the deletion operation with the selected document
      await orderService.deleteFileFromOrder({
        orderId: order.id,
        mediaId: selectedDocumentForDeletion.id,
      });
      showMessage(getPhrase("FILE_DELETED_SUCCESSFULLY"), "success");
      // refetch the customer data
      await fetchOrderData(order.id);
    } catch (error) {
      console.error("Error deleting file:", error);
      showMessage(getPhrase("ERROR.GENERIC.DELETE_FILE"), "error");
    } finally {
      setIsDeleteDialogOpen(false);
      setIsDownloadingOrDeleting(false);
      setSelectedDocumentForDeletion(null);
    }
  };

  const performCancelOrder = async ({
    reason,
    reasonDetails,
  }: {
    reason: CancellationReason;
    reasonDetails: string;
  }) => {
    if (!orderId) {
      return;
    }

    try {
      await orderService.transitionOrderStatusCanceled({
        orderId,
        transitionOrderStatusCanceledRequest: {
          reason,
          reasonDetails,
        },
      });
      showMessage(getPhrase("ORDER_CANCELED_SUCCESSFULLY"), "success");
      await fetchOrderData(orderId);
    } catch (error) {
      console.error("Error canceling order:", error);
      showMessage(getPhrase("ERROR.GENERIC.SAVE_DATA"), "error");
    }
  };

  const performTransitionOrderStatus = async (
    currentStatus: OrderStatus,
    res?: TransitionOrderStatusDeliveredRequest
  ) => {
    if (!orderId) {
      return;
    }

    if (currentStatus === OrderStatus.Pending) return;

    try {
      if (currentStatus === OrderStatus.Confirmed) {
        await orderService.transitionOrderStatusConfirmed({ orderId });
      } else if (currentStatus === OrderStatus.OutForDelivery) {
        await orderService.transitionOrderStatusOutForDelivery({ orderId });
      } else if (currentStatus === OrderStatus.Delivered) {
        if (!res) {
          console.error(
            "Error transitioning order status: invalid status",
            res
          );
          showMessage(
            getPhrase("ERROR.GENERIC.TRANSITION_ORDER_STATUS"),
            "error"
          );
          return;
        }
        await orderService.transitionOrderStatusDelivered({
          orderId,
          transitionOrderStatusDeliveredRequest: res,
        });
      } else if (currentStatus === OrderStatus.ReturnScheduled) {
        await orderService.transitionOrderStatusReturnScheduled({ orderId });
      } else if (currentStatus === OrderStatus.Returned) {
        await orderService.transitionOrderStatusReturned({ orderId });
      } else if (currentStatus === OrderStatus.Closed) {
        await orderService.transitionOrderStatusClosed({ orderId });
      } else if (currentStatus === OrderStatus.Verified) {
        await orderService.transitionOrderStatusVerified({ orderId });
      } else {
        console.error("Error transitioning order status: invalid status");
        showMessage(
          getPhrase("ERROR.GENERIC.TRANSITION_ORDER_STATUS"),
          "error"
        );
        return;
      }

      showMessage(getPhrase("ORDER_STATUS_TRANSITION_SUCCESSFULLY"), "success");
      await fetchOrderData(orderId);
    } catch (error) {
      console.error("Error transitioning order status:", error);
      showMessage(getPhrase("ERROR.GENERIC.TRANSITION_ORDER_STATUS"), "error");
    }
  };

  const getNextStatus = (status: OrderStatus): OrderStatus | undefined => {
    if (status === OrderStatus.Pending) {
      return OrderStatus.Confirmed;
    }
    if (status === OrderStatus.Confirmed) {
      return OrderStatus.OutForDelivery;
    }
    if (status === OrderStatus.OutForDelivery) {
      return OrderStatus.Delivered;
    }
    if (status === OrderStatus.Delivered) {
      return OrderStatus.ReturnScheduled;
    }
    if (status === OrderStatus.ReturnScheduled) {
      return OrderStatus.Returned;
    }
    if (status === OrderStatus.Returned) {
      return OrderStatus.Closed;
    }
    if (status === OrderStatus.Closed) {
      return OrderStatus.Verified;
    }
    return;
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!order) {
    return <Typography variant="h6">{getPhrase("ORDER_NOT_FOUND")}</Typography>;
  }

  let confirmDisabled = false;
  const statusToTransition = getNextStatus(order.status);
  const canCancelOrder = [
    OrderStatus.Pending,
    OrderStatus.Confirmed,
    OrderStatus.OutForDelivery,
  ].includes(order.status as any);
  const getErrorMessage = (): string | undefined => {
    if (statusToTransition === OrderStatus.Confirmed && order.balance > 0) {
      return getPhrase("TRYING_TO_CONFIRM_ORDER_WITH_BALANCE");
    }
    if (
      statusToTransition === OrderStatus.Delivered &&
      order.media.filter(
        (m) => m.orderMediaCategory === OrderMediaCategory.ProofOfDelivery
      ).length === 0
    ) {
      return getPhrase("TRYING_TO_DELIVER_ORDER_WITHOUT_PROOF");
    }
    if (
      statusToTransition === OrderStatus.ReturnScheduled &&
      !order.returnDate
    ) {
      confirmDisabled = true;
      return getPhrase("TRYING_TO_SCHEDULE_RETURN_WITHOUT_DATE");
    }
    return;
  };

  return (
    <Container>
      <ConfirmationDialog
        title={getPhrase("CANCEL_ORDER")}
        message={getPhrase("ORDER_CANCELLATION_CONFIRMATION")}
        open={isCancelDialogOpen}
        template={DialogTemplate.OrderCancellation}
        onClose={() => {
          setIsCancelDialogOpen(false);
        }}
        onConfirm={(res) => {
          if (!res || !("reason" in res)) {
            setIsCancelDialogOpen(false);
            console.error(
              "Error canceling order, confirmation dialog returned no data"
            );
            showMessage(
              getPhrase("ERROR.GENERIC.TRANSITION_ORDER_STATUS"),
              "error"
            );
            return;
          }

          performCancelOrder(res);
        }}
      />
      <ConfirmationDialog
        title={getPhrase("TRANSITION_ORDER_STATUS")}
        message={`${getPhrase(
          "ORDER_STATUS_TRANSITION_CONFIRMATION"
        )} "${getPhrase(`ORDER_STATUS.${statusToTransition}` as any)}"?`}
        open={isTransitionDialogOpen}
        onClose={() => setIsTransitionDialogOpen(false)}
        errorMessage={getErrorMessage()}
        template={
          statusToTransition === OrderStatus.Delivered
            ? DialogTemplate.OrderDelivered
            : DialogTemplate.Generic
        }
        confirmDisabled={confirmDisabled}
        onConfirm={(res) => {
          if (res && "reason" in res) {
            setIsTransitionDialogOpen(false);
            console.error("Error transitioning order status, invalid data");
            return;
          }
          performTransitionOrderStatus(
            statusToTransition || OrderStatus.Pending,
            res
          );
        }}
      />
      <Box
        my={5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" gutterBottom>
          {getPhrase("ORDER.DETAILS_PAGE.TITLE")}
          {/* <Chip size="lg" color="success" style={{ margin: "0 .5rem" }}>
            {order.branch}
          </Chip> */}
          <Chip
            size="lg"
            color={order.status === OrderStatus.Canceled ? "danger" : "success"}
            style={{ marginLeft: "2rem", fontSize: "22px" }}
          >
            {getPhrase(`ORDER_STATUS.${order.status}` as any)}
          </Chip>
        </Typography>
        <Button
          variant="contained"
          endIcon={<ArrowDropDownIcon />}
          onClick={handleClick}
        >
          {getPhrase("ACTIONS")}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit}>{getPhrase("EDIT_ORDER")}</MenuItem>
          <MenuItem onClick={handleGoToEditPaymentsPage}>
            {getPhrase("EDIT_ORDER_PAYMENTS")}
          </MenuItem>
          <MenuItem onClick={handleGoToOrderCustomer}>
            {getPhrase("VIEW_CUSTOMER")}
          </MenuItem>
          {statusToTransition && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setIsTransitionDialogOpen(true);
              }}
            >
              {getPhrase("TRANSITION_ORDER_STATUS_TO")}:{" "}
              {getPhrase(`ORDER_STATUS.${statusToTransition}` as any)}
            </MenuItem>
          )}
          {canCancelOrder && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setIsCancelDialogOpen(true);
              }}
            >
              {getPhrase("CANCEL_ORDER")}
            </MenuItem>
          )}
          <MenuItem onClick={handleGoToPrintPage}>
            {getPhrase("PRINT_ORDER")}
          </MenuItem>
        </Menu>
      </Box>

      <Grid container spacing={1}>
        {/* Order Details */}
        {/* <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("ORDER_ID")}:</strong> {order.id}
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("ORDER_KEY")}:</strong> {order.key}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("BRANCH")}:</strong> {order.branch}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("CUSTOMER_ID")}:</strong> {order.customerId}
            {/* add button to view customer, links to /customers/:customerId */}
            {/* <Button
              style={{ marginLeft: "1rem" }}
              size="small"
              variant="outlined"
              onClick={handleGoToOrderCustomer}
            >
              {getPhrase("VIEW_CUSTOMER")}
            </Button> */}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {order.cpf && (
            <Typography variant="body1">
              <strong>{getPhrase("CPF")}:</strong> {order.cpf}
            </Typography>
          )}
          {order.cnpj && (
            <Typography variant="body1">
              <strong>{getPhrase("CNPJ")}:</strong> {order.cnpj}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("DELIVERY_ADDRESS")}:</strong>{" "}
            {formatAddress(order.deliveryAddress)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("NF_NUMBER")}:</strong> {order.nf}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("SCHEDULED_DATE")}:</strong>{" "}
            {formatDateTime(order.scheduledDate)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("RETURN_DATE")}:</strong>{" "}
            {order.returnDate
              ? formatDateTime(order.returnDate)
              : getPhrase("NOT_AVAIABLE")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("TIME_OF_DAY")}:</strong>{" "}
            {getPhrase(`TIME_OF_DAY.${order.timeOfDay}` as any)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("ORDER_TAGS")}:</strong>{" "}
            {order.tags.map((tag) => (
              <Chip
                style={{ marginRight: ".25rem" }}
                color="success"
                variant="outlined"
                size="sm"
                key={tag}
              >
                {tag}
              </Chip>
            ))}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("NOTES")}:</strong> {order.notes}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{getPhrase("ORDER_STATUS")}:</strong>{" "}
            {getPhrase(`ORDER_STATUS.${order.status}` as any)}
          </Typography>
        </Grid>

        {/* Metadata */}
        {/* <Grid item xs={12}>
          <Typography variant="h6">{getPhrase("ORDER_METADATA")}</Typography>
          {order.metadata &&
            Object.entries(order.metadata).map(
              ([key, value]: [string, Date | undefined]) => (
                <Typography key={key} variant="body1">
                  <strong>{key}:</strong>{" "}
                  {value ? formatDateTime(value) : "N/A"}
                </Typography>
              )
            )}
        </Grid> */}

        {/* Order Contacts Table: {firstName, lastName, phone}[] */}
        <Grid mt={3} item xs={12}>
          <Typography variant="h6">{getPhrase("ORDER_CONTACTS")}</Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{getPhrase("FIRST_NAME")}</TableCell>
                  <TableCell>{getPhrase("LAST_NAME")}</TableCell>
                  <TableCell>{getPhrase("PHONE")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.contacts.map((contact, index) => (
                  <TableRow key={index}>
                    <TableCell>{contact.firstName}</TableCell>
                    <TableCell>{contact.lastName}</TableCell>
                    <TableCell>{contact.phone}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item mt={5} xs={12}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignContent={"center"}
          >
            <Typography variant="h6">{getPhrase("ORDER_DOCUMENTS")}</Typography>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  setIsUploadDialogOpen(true);
                }}
              >
                {getPhrase("UPLOAD_FILE")}
              </Button>
            </Box>
          </Box>
          <FileUploadDialog
            onSucess={async () => {
              if (!orderId) {
                return;
              }
              await fetchOrderData(orderId);
            }}
            orderId={order.id}
            onClose={() => setIsUploadDialogOpen(false)}
            open={isUploadDialogOpen}
          />
          {/* List media associated to documents {displayName, description, uploadedAt, button do download } */}
          <Table className="tableSmallFontSize13" size="small">
            <TableHead>
              <TableCell>{getPhrase("DOCUMENT_NAME")}</TableCell>
              <TableCell>{getPhrase("ORDER_MEDIA_CATEGORY")}</TableCell>
              <TableCell>{getPhrase("DESCRIPTION")}</TableCell>
              <TableCell>{getPhrase("UPLOADED_AT")}</TableCell>
              <TableCell>{getPhrase("ACTIONS")}</TableCell>
            </TableHead>
            <TableBody>
              {order.media.map((document) => (
                <TableRow key={document.id}>
                  <TableCell>{document.displayName}</TableCell>
                  <TableCell>
                    {getPhrase(
                      `ORDER_MEDIA_CATEGORY.${document.orderMediaCategory}`
                    )}
                  </TableCell>
                  <TableCell>{document.description}</TableCell>
                  <TableCell>
                    {new Date(document.uploadedAt).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{ mr: 1 }}
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleDownloadMedia(document);
                      }}
                      disabled={isDowloadingOrDeleting}
                    >
                      {getPhrase("DOWNLOAD")}
                    </Button>

                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        handleDeleteMedia(document);
                      }}
                      disabled={isDowloadingOrDeleting}
                    >
                      {getPhrase("DELETE")}
                    </Button>
                    <ConfirmationDialog
                      title={`${getPhrase("DELETE_FILE")} - ${
                        selectedDocumentForDeletion?.displayName
                      }`}
                      message={getPhrase(
                        "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_FILE"
                      )}
                      onClose={handleCloseConfirmationDialog}
                      onConfirm={performDeletion}
                      open={isDeleteDialogOpen}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        {/* Order Items */}
        <Grid mt={3} item xs={12}>
          <Typography variant="h6">{getPhrase("ORDER_ITEMS")}</Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{getPhrase("PRODUCT_NAME")}</TableCell>
                  <TableCell>{getPhrase("QUANTITY")}</TableCell>
                  <TableCell>{getPhrase("PRICE_PER_UNIT")}</TableCell>
                  <TableCell>{getPhrase("SUBTOTAL")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.items.map(
                  (item: OrderItemEnrichedOutput, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>
                        {printCurrencyValue(item.pricePerUnit)}
                      </TableCell>
                      <TableCell>
                        {printCurrencyValue(item.quantity * item.pricePerUnit)}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Order Equipment */}
        <Grid mt={3} item xs={12}>
          <Typography variant="h6">{getPhrase("ORDER_EQUIPMENT")}</Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{getPhrase("EQUIPMENT_NAME")}</TableCell>
                  <TableCell>{getPhrase("QUANTITY")}</TableCell>
                  <TableCell>{getPhrase("PRICE_PER_UNIT")}</TableCell>
                  <TableCell>{getPhrase("EQUIPMENT_REFERENCE_CODE")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.orderEquipment.map(
                  (equipment: OrderEquipmentEnrichedOutput, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{equipment.name}</TableCell>
                      <TableCell>{equipment.quantity}</TableCell>
                      <TableCell>{equipment.pricePerUnit}</TableCell>
                      <TableCell>{equipment.referenceCode}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Payments */}
        <Grid mt={3} item xs={12}>
          <Typography variant="h6">{getPhrase("PAYMENTS")}</Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{getPhrase("PAYMENT_METHOD")}</TableCell>
                  <TableCell>{getPhrase("AMOUNT")}</TableCell>
                  <TableCell>{getPhrase("PAYMENT_DATE")}</TableCell>
                  <TableCell>{getPhrase("PAYMENT_REFERENCE_CODE")}</TableCell>
                  <TableCell>{getPhrase("NOTES")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.payments.map(
                  (payment: OrderPaymentOutput, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        {getPhrase(
                          `PAYMENT_METHOD.${payment.paymentMethod}` as any
                        )}
                      </TableCell>
                      <TableCell>
                        {printCurrencyValue(payment.amount)}
                      </TableCell>
                      <TableCell>{formatDateTime(payment.date)}</TableCell>
                      <TableCell>{payment.referenceCode}</TableCell>
                      <TableCell>{payment.notes}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <OrderValueSummary order={order} payments={order.payments} />
      </Grid>
    </Container>
  );
};
