import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Box, CircularProgress, Button } from "@mui/material";
import { formatAddress } from "../../../utils/formatAddress";
import {
  OrderItemOutput,
  OrderEquipmentOutput,
  OrderEnrichedOutput,
  OrderPaymentOutput,
  EquipmentCategory,
} from "../../../generated-api";
import { orderService } from "../../../services/OrderApi";
import { usePhrases } from "../../../context/PhrasesContext";
import { formatDateTime } from "../../../utils/formatDateTime";
import { useSnackbar } from "../../../context/SnackbarContext";

export const OrderPrintPage: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<OrderEnrichedOutput | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const { getPhrase } = usePhrases();
  const { showMessage } = useSnackbar();

  const fetchOrderData = useCallback(
    async (orderId: string) => {
      try {
        const data = await orderService.getOneOrder({
          orderId,
          joinCustomer: true,
          joinEquipment: true,
          joinProduct: true,
        });
        setOrder(data);
      } catch (error) {
        console.error("Error:", error);
        showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
      } finally {
        setLoading(false);
      }
    },
    [showMessage, getPhrase]
  );

  useEffect(() => {
    if (!orderId) return;

    fetchOrderData(orderId);
  }, [orderId, fetchOrderData]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!order) {
    return <Typography variant="h6">{getPhrase("ORDER_NOT_FOUND")}</Typography>;
  }

  if (!order.customer) {
    return (
      <Typography variant="h6">{getPhrase("CUSTOMER_NOT_FOUND")}</Typography>
    );
  }

  const MINIMUM_LINES = 4;

  const printCurrencyValue = (value: number) => {
    return `${getPhrase("CURRENCY.SYMBOL")} ${value.toFixed(2)}`;
  };

  const handlePrint = () => {
    window.print();
  };

  const getCustomerName = () => {
    return `${order.customer?.firstName} ${order.customer?.lastName}`;
  };

  const getCustomerPhones = () => {
    return order.contacts
      .map(
        (contact) =>
          `${(contact.firstName + " " + contact.lastName).trim()}: ${
            contact.phone
          }`
      )
      .join(", ");
  };

  const renderEmptyLines = (count: number) => {
    return Array(count)
      .fill(0)
      .map((_, index) => (
        <tr key={index}>
          <td style={{ color: "transparent" }} colSpan={6}>
            Blank
          </td>
        </tr>
      ));
  };

  const formatOrderPayment = (payment: OrderPaymentOutput) => {
    return `${getPhrase(
      `PAYMENT_METHOD.${payment.paymentMethod}` as any
    )}: ${printCurrencyValue(payment.amount)}`;
  };

  // Use equipment category to determine if the order has chopeira
  const hasChopeira = () => {
    return order.orderEquipment.some(
      (equipment) => equipment.category === EquipmentCategory.Chopeira
    );
  };

  const renderTable = (n: number) => {
    return (
      <table className="printableTable" style={{ border: "2px solid black" }}>
        <tbody>
          <tr>
            <td style={{ padding: "1px" }} colSpan={2} rowSpan={4}>
              <img
                src="/be-logo.png"
                style={{ width: "100%" }}
                alt="Brahma Express Logo"
              />
            </td>
            <td
              // style={{ fontSize: "11px" }}
              className="bold textCenter"
              colSpan={3}
              rowSpan={3}
            >
              BRAHMA EXPRESS LITORAL
              <br />
              OSWALDO COCHRANE 150, EMBARÉ, SANTOS SP
              <br />
              Tel - (13) 3394-7004
            </td>
            <td className="bold textCenter">PEDIDO {n}ª VIA</td>
          </tr>
          <tr>
            {/* <td className="bold textCenter" colSpan={3}>
              OSWALDO COCHRANE 150
            </td> */}
            <td
              style={{
                fontSize: "25px",
                padding: "0px",
                paddingLeft: "2px",
                paddingRight: "2px",
                margin: "0px",
                backgroundColor: "#ddd",
              }}
              className="bold textCenter"
              rowSpan={3}
            >
              <div style={{ margin: "0px", padding: "0px" }}>
                {order.key.split("-")[0]}
              </div>
              <div style={{ marginTop: "-10px" }}>
                {order.key.split("-")[1]}
              </div>
            </td>
          </tr>

          <tr>
            {/* <td className="bold textCenter" colSpan={3}>
              EMBARÉ - SANTOS - SP
            </td> */}
          </tr>
          <tr>
            {/* <td className="bold textCenter" colSpan={3}>
              Tel - (13) 3394-7004
            </td> */}
          </tr>
          {/* <tr>
            <td className="bold textCenter" colSpan={3}>
              brahmasantosriviera@gmail.com
            </td>
          </tr> */}

          <tr style={{ borderTop: "3px black solid" }}>
            <td className="bold" colSpan={2}>
              Nome:
            </td>
            <td colSpan={4}>{getCustomerName()}</td>
          </tr>
          <tr>
            <td className="bold" colSpan={2}>
              Telefone:
            </td>
            <td colSpan={4}>{getCustomerPhones()}</td>
          </tr>
          <tr>
            <td className="bold" colSpan={2}>
              CPF/CNPJ:
            </td>
            <td colSpan={4}>{order.cpf || order.cnpj}</td>
          </tr>
          <tr>
            <td className="bold" colSpan={2}>
              Entrega em:
            </td>
            <td>{formatDateTime(order.scheduledDate)}</td>
            <td colSpan={2} className="bold">
              Periodo:
            </td>
            <td colSpan={1}>
              {getPhrase(`TIME_OF_DAY.${order.timeOfDay}` as any)}
            </td>
          </tr>
          <tr>
            <td className="bold" rowSpan={1} colSpan={2}>
              Endereço:
            </td>
            <td rowSpan={1} colSpan={4}>
              {formatAddress(order.deliveryAddress)}
            </td>
          </tr>
          <tr>
            <td className="bold" colSpan={2}>
              Bairro:
            </td>
            <td colSpan={1}>{order.deliveryAddress.neighborhood}</td>
            <td className="bold" colSpan={2}>
              Cidade:
            </td>
            <td colSpan={1}>{order.deliveryAddress.city}</td>
          </tr>
          {/* <tr>
            <td colSpan={6}></td>
          </tr> */}

          <tr style={{ borderTop: "3px black solid" }}>
            <td className="bold">Qntd</td>
            <td className="bold" colSpan={3}>
              Produto
            </td>
            <td style={{ width: "65px" }} className="bold">
              Valor unit
            </td>
            <td className="bold">Subtotal</td>
          </tr>
          {order.items.map((item: OrderItemOutput, index: number) => (
            <tr key={index}>
              <td>{item.quantity}</td>
              <td colSpan={3}>
                {"name" in item ? `${item.name}` : item.productId}
              </td>
              <td>{printCurrencyValue(item.pricePerUnit)}</td>
              <td>{printCurrencyValue(item.quantity * item.pricePerUnit)}</td>
            </tr>
          ))}
          {renderEmptyLines(MINIMUM_LINES - order.items.length)}
          {/* <tr>
            <td colSpan={6}></td>
          </tr> */}

          {/* <tr>
            <td className="bold" colSpan={6}>
              Equipamentos
            </td>
          </tr> */}
          <tr style={{ borderTop: "3px black solid" }}>
            <td className="bold" colSpan={3} style={{ textAlign: "center" }}>
              Com chopeira?
            </td>
            <td className="bold" colSpan={3}>
              {hasChopeira() ? "SIM" : "NÃO"}
            </td>
          </tr>
          <tr>
            <td className="bold">Qntd</td>
            <td className="bold" colSpan={3}>
              Equipamento
            </td>
            <td className="bold">Valor unit</td>
            <td className="bold">Subtotal</td>
          </tr>
          {order.orderEquipment.map(
            (equipment: OrderEquipmentOutput, index: number) => (
              <tr key={index}>
                <td>{equipment.quantity}</td>
                <td colSpan={3}>
                  {"name" in equipment
                    ? `${equipment.name}`
                    : equipment.equipmentId}
                </td>
                <td>{printCurrencyValue(equipment.pricePerUnit)}</td>
                <td>
                  {printCurrencyValue(
                    equipment.quantity * equipment.pricePerUnit
                  )}
                </td>
              </tr>
            )
          )}
          {renderEmptyLines(MINIMUM_LINES - order.orderEquipment.length)}
          {/* <tr>
            <td colSpan={6}></td>
          </tr> */}

          <tr style={{ borderTop: "3px black solid" }}>
            <td className="bold" colSpan={5}>
              Total dos produtos:
            </td>
            <td colSpan={1}>
              {printCurrencyValue(
                order.items.reduce(
                  (acc, item) => acc + item.quantity * item.pricePerUnit,
                  0
                )
              )}
            </td>
          </tr>
          <tr>
            <td className="bold" colSpan={5}>
              Taxa de entrega:
            </td>
            <td colSpan={1}>{printCurrencyValue(order.deliveryFee)}</td>
          </tr>
          <tr>
            <td className="bold" colSpan={5}>
              Descontos:
            </td>
            <td colSpan={1}>- {printCurrencyValue(order.discountTotal)}</td>
          </tr>
          <tr>
            <td className="bold" colSpan={5}>
              Total:
            </td>
            <td colSpan={1}>
              {printCurrencyValue(order.totalAmount - order.discountTotal)}
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <strong>Pagamento:</strong>{" "}
              {order.payments
                .map((payment) => formatOrderPayment(payment))
                .join(", ")}
            </td>
            <td>
              {printCurrencyValue(
                order.payments.reduce((acc, payment) => acc + payment.amount, 0)
              )}
            </td>
          </tr>

          <tr style={{ borderTop: "3px black solid" }}>
            <td className="tallCell" colSpan={6}>
              <strong>Observações:</strong> {order.notes}
            </td>
          </tr>
          {/* <tr>
            <td className="tallCell" colSpan={6}>
              {order.notes}
            </td>
          </tr> */}
          <tr>
            <td className="bold" colSpan={2}>
              Vendedor:
            </td>
            <td colSpan={1}>-</td>

            <td className="bold" colSpan={2}>
              Data da venda:
            </td>
            <td colSpan={1}>{formatDateTime(order.createdAt)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <Box>
      <Box sx={{ display: "flex" }} justifyContent={"center"} my={3}>
        <Box mr={5}>
          <Button variant="contained" onClick={handlePrint}>
            {getPhrase("PRINT")}
          </Button>
        </Box>
        <Box>
          <Button
            variant="outlined"
            onClick={() => navigate(`/orders/${orderId}`)}
          >
            {getPhrase("BACK_TO_ORDER_PAGE")}
          </Button>
        </Box>
      </Box>
      <Box
        id="printableArea"
        flexDirection={"row"}
        sx={{ display: "flex" }}
        justifyContent={"space-between"}
      >
        {renderTable(1)}
        {renderTable(2)}
        {renderTable(3)}
      </Box>
    </Box>
  );

  // return (
  //   <Container>
  //     <Box
  //       my={5}
  //       display="flex"
  //       justifyContent="space-between"
  //       alignItems="center"
  //     >
  //       <Typography variant="h4" gutterBottom>
  //         {getPhrase("ORDER.DETAILS_PAGE.TITLE")}
  //         <Chip size="lg" color="success" style={{ margin: "0 .5rem" }}>
  //           {order.branch}
  //         </Chip>
  //       </Typography>
  //     </Box>

  //     <Grid container spacing={2}>
  //       {/* Order Details */}
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("ORDER_ID")}:</strong> {order.id}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("CUSTOMER_ID")}:</strong> {order.customerId}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("CPF")}:</strong> {order.cpf}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("CNPJ")}:</strong> {order.cnpj}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("DELIVERY_ADDRESS")}:</strong>{" "}
  //           {formatAddress(order.deliveryAddress)}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("NF_NUMBER")}:</strong> {order.nf}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("SCHEDULED_DATE")}:</strong>{" "}
  //           {formatDateTime(order.scheduledDate)}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("TIME_OF_DAY")}:</strong> {order.timeOfDay}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("ORDER_TAGS")}:</strong>{" "}
  //           {order.tags.map((tag) => (
  //             <Chip
  //               style={{ marginRight: ".25rem" }}
  //               color="success"
  //               variant="outlined"
  //               size="sm"
  //               key={tag}
  //             >
  //               {tag}
  //             </Chip>
  //           ))}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("NOTES")}:</strong> {order.notes}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("ORDER_STATUS")}:</strong> {order.status}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("DELIVERY_FEE")}:</strong> {order.deliveryFee}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           <strong>{getPhrase("TOTAL_AMOUNT")}:</strong> {order.totalAmount}
  //         </Typography>
  //       </Grid>

  //       {/* Metadata */}
  //       <Grid item xs={12}>
  //         <Typography variant="h6">{getPhrase("ORDER_METADATA")}</Typography>
  //         {order.metadata &&
  //           Object.entries(order.metadata).map(
  //             ([key, value]: [string, Date | undefined]) => (
  //               <Typography key={key} variant="body1">
  //                 <strong>{key}:</strong>{" "}
  //                 {value ? formatDateTime(value) : "N/A"}
  //               </Typography>
  //             )
  //           )}
  //       </Grid>

  //       {/* Order Items */}
  //       <Grid item xs={12}>
  //         <Typography variant="h6">{getPhrase("ORDER_ITEMS")}</Typography>
  //         {order.items.map((item: OrderItemOutput, index: number) => (
  //           <Box key={index}>
  //             <Typography variant="body1">
  //               {getPhrase("PRODUCT_ID")}: {item.productId}
  //             </Typography>
  //             <Typography variant="body1">Quantity: {item.quantity}</Typography>
  //             <Typography variant="body1">
  //               {getPhrase("PRICE_PER_UNIT")}: {item.pricePerUnit}
  //             </Typography>
  //           </Box>
  //         ))}
  //       </Grid>

  //       {/* Order Equipment */}
  //       <Grid item xs={12}>
  //         <Typography variant="h6">{getPhrase("ORDER_EQUIPMENT")}</Typography>
  //         {order.orderEquipment.map(
  //           (equipment: OrderEquipmentOutput, index: number) => (
  //             <Box key={index}>
  //               <Typography variant="body1">
  //                 {getPhrase("EQUIPMENT_ID")}: {equipment.equipmentId}
  //               </Typography>
  //               <Typography variant="body1">
  //                 {getPhrase("QUANTITY")}: {equipment.quantity}
  //               </Typography>
  //               <Typography variant="body1">
  //                 {getPhrase("PRICE_PER_UNIT")}: {equipment.pricePerUnit}
  //               </Typography>
  //               <Typography variant="body1">
  //                 {getPhrase("EQUIPMENT_REFERENCE_CODE")}:{" "}
  //                 {equipment.referenceCode}
  //               </Typography>
  //             </Box>
  //           )
  //         )}
  //       </Grid>

  //       {/* Payments */}
  //       <Grid item xs={12}>
  //         <Typography variant="h6">{getPhrase("PAYMENTS")}</Typography>
  //         {order.payments.map((payment: OrderPaymentOutput, index: number) => (
  //           <Box key={index}>
  //             <Typography variant="body1">
  //               {getPhrase("PAYMENT_METHOD")}: {payment.paymentMethod}
  //             </Typography>
  //             <Typography variant="body1">
  //               {getPhrase("AMOUNT")}: {payment.amount}
  //             </Typography>
  //             <Typography variant="body1">
  //               {getPhrase("PAYMENT_REFERENCE_CODE")}: {payment.referenceCode}
  //             </Typography>
  //             <Typography variant="body1">
  //               {getPhrase("NOTES")}: {payment.notes}
  //             </Typography>
  //           </Box>
  //         ))}
  //       </Grid>
  //     </Grid>
  //   </Container>
  // );
};
