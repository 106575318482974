/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductCategory } from './ProductCategory';
import {
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';

/**
 * 
 * @export
 * @interface OrderItemEnrichedOutput
 */
export interface OrderItemEnrichedOutput {
    /**
     * 
     * @type {string}
     * @memberof OrderItemEnrichedOutput
     */
    productId: string;
    /**
     * 
     * @type {ProductCategory}
     * @memberof OrderItemEnrichedOutput
     */
    category: ProductCategory;
    /**
     * 
     * @type {number}
     * @memberof OrderItemEnrichedOutput
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemEnrichedOutput
     */
    pricePerUnit: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemEnrichedOutput
     */
    name?: string;
}

/**
 * Check if a given object implements the OrderItemEnrichedOutput interface.
 */
export function instanceOfOrderItemEnrichedOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "pricePerUnit" in value;

    return isInstance;
}

export function OrderItemEnrichedOutputFromJSON(json: any): OrderItemEnrichedOutput {
    return OrderItemEnrichedOutputFromJSONTyped(json, false);
}

export function OrderItemEnrichedOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemEnrichedOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'category': ProductCategoryFromJSON(json['category']),
        'quantity': json['quantity'],
        'pricePerUnit': json['pricePerUnit'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function OrderItemEnrichedOutputToJSON(value?: OrderItemEnrichedOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'category': ProductCategoryToJSON(value.category),
        'quantity': value.quantity,
        'pricePerUnit': value.pricePerUnit,
        'name': value.name,
    };
}

