import React, { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Box,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  EQUIPMENT_CREATE_INPUT_SCHEMA,
  EQUIPMENT_UPDATE_INPUT_SCHEMA,
  equipmentService,
} from "../../../services/EquipmentApi";

import { useSnackbar } from "../../../context/SnackbarContext";
import {
  EquipmentCategory,
  EquipmentCreateInput,
  EquipmentUpdateInput,
} from "../../../generated-api";
import { usePhrases } from "../../../context/PhrasesContext";
import { removeFalsy } from "../../../utils/removeFalsy";

export const EquipmentCreateAndEditPage: React.FC = () => {
  const { equipmentId } = useParams<{ equipmentId: string }>();
  const isEditMode = Boolean(equipmentId);

  const { getPhrase } = usePhrases();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [loading, setLoading] = useState(!!isEditMode);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<EquipmentCreateInput & EquipmentUpdateInput>({
    resolver: joiResolver(
      isEditMode ? EQUIPMENT_UPDATE_INPUT_SCHEMA : EQUIPMENT_CREATE_INPUT_SCHEMA
    ),
    defaultValues: {
      name: "",
      description: "",
      pricePerUnit: 0,
      referenceCode: "",
      category: EquipmentCategory.Chopeira,
    },
  });

  useEffect(() => {
    if (isEditMode && equipmentId) {
      // Fetch equipment data and populate form
      const fetchEquipmentData = async () => {
        try {
          const equipmentData = await equipmentService.getEquipment({
            equipmentId,
          });
          reset({
            name: equipmentData.name,
            description: equipmentData.description,
            pricePerUnit: equipmentData.pricePerUnit,
            referenceCode: equipmentData.referenceCode,
            category: equipmentData.category,
          });
          setLoading(false);
        } catch (error) {
          console.error("Error fetching equipment data:", error);
          showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
        }
      };
      fetchEquipmentData();
    }
  }, [isEditMode, equipmentId, reset, showMessage, getPhrase]);

  const onSubmit: SubmitHandler<
    EquipmentCreateInput & EquipmentUpdateInput
  > = async (equipmentData) => {
    const data = removeFalsy(equipmentData);
    try {
      if (isEditMode && equipmentId) {
        await equipmentService.updateEquipment({
          equipmentId,
          equipmentUpdateInput: data,
        });
        showMessage(getPhrase("EQUIPMENT_UPDATED_SUCCESSFULLY"), "success");
        navigate(`/equipments`);
      } else {
        await equipmentService.createEquipment({
          equipmentCreateInput: data,
        });
        showMessage(getPhrase("EQUIPMENT_CREATED_SUCCESSFULLY"), "success");
        navigate(`/equipments`);
      }
    } catch (error) {
      console.error("Error saving equipment:", error);
      showMessage(getPhrase("ERROR.GENERIC.SAVE_DATA"), "error");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Box my={5}>
        <Typography component="h1" variant="h4">
          {isEditMode
            ? getPhrase("EDIT_EQUIPMENT")
            : getPhrase("CREATE_EQUIPMENT")}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {/* Name */}
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth
                  label={getPhrase("EQUIPMENT_NAME")}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  size="small"
                />
              )}
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={getPhrase("EQUIPMENT_DESCRIPTION")}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  size="small"
                />
              )}
            />
          </Grid>

          {/* Price Per Unit */}
          <Grid item xs={4}>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  select
                  label={getPhrase("EQUIPMENT_CATEGORY")}
                  variant="outlined"
                  fullWidth
                  error={!!errors.category}
                  helperText={errors.category?.message}
                >
                  {/* Map OrderType enum to menu items */}
                  {Object.values(EquipmentCategory).map((type) => (
                    <MenuItem key={type} value={type}>
                      {getPhrase(`EQUIPMENT_CATEGORY.${type}` as any)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {/* Category */}
          <Grid item xs={4}>
            <Controller
              name="pricePerUnit"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth
                  label={getPhrase("EQUIPMENT_PRICE_PER_UNIT")}
                  type="number"
                  error={!!errors.pricePerUnit}
                  helperText={errors.pricePerUnit?.message}
                  size="small"
                />
              )}
            />
          </Grid>

          {/* Reference Code */}
          <Grid item xs={4}>
            <Controller
              name="referenceCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth
                  label={getPhrase("EQUIPMENT_REFERENCE_CODE")}
                  error={!!errors.referenceCode}
                  helperText={errors.referenceCode?.message}
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>

        <Box display={"flex"} justifyContent={"center"}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, width: "25%" }}
          >
            {isEditMode
              ? getPhrase("SAVE_CHANGES")
              : getPhrase("CREATE_EQUIPMENT")}
          </Button>
        </Box>
      </form>
    </Container>
  );
};
