import React, { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Box,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useSnackbar } from "../../../context/SnackbarContext";
import { usePhrases } from "../../../context/PhrasesContext";
import { removeFalsy } from "../../../utils/removeFalsy";
import {
  DiscountCreateInput,
  DiscountType,
  DiscountUpdateInput,
  ProductCategory,
} from "../../../generated-api";
import {
  DISCOUNT_CREATE_INPUT_SCHEMA,
  DISCOUNT_UPDATE_INPUT_SCHEMA,
  discountService,
} from "../../../services/DiscountApi";

export const DiscountCreateAndEditPage: React.FC = () => {
  const { discountId } = useParams<{ discountId: string }>();
  const isEditMode = Boolean(discountId);

  const { getPhrase } = usePhrases();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [loading, setLoading] = useState(!!isEditMode);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DiscountCreateInput & DiscountUpdateInput>({
    resolver: joiResolver(
      isEditMode ? DISCOUNT_UPDATE_INPUT_SCHEMA : DISCOUNT_CREATE_INPUT_SCHEMA
    ),
    defaultValues: {
      name: "",
      type: DiscountType.Percentage,
      description: "",
      value: 0,
      productCategory: ProductCategory.Other,
    },
  });

  useEffect(() => {
    if (isEditMode && discountId) {
      // Fetch discount data and populate form
      const fetchDiscountData = async () => {
        try {
          const discountData = await discountService.getDiscount({
            discountId,
          });
          reset({
            name: discountData.name,
            description: discountData.description,
            type: discountData.type,
            value: discountData.value,
            productCategory: discountData.productCategory,
          });
          setLoading(false);
        } catch (error) {
          console.error("Error fetching discount data:", error);
          showMessage(getPhrase("ERROR.GENERIC.FETCH_DATA"), "error");
        }
      };
      fetchDiscountData();
    }
  }, [isEditMode, discountId, reset, showMessage, getPhrase]);

  const onSubmit: SubmitHandler<
    DiscountCreateInput | DiscountUpdateInput
  > = async (discountData) => {
    const data = removeFalsy(discountData);
    try {
      if (isEditMode && discountId) {
        await discountService.updateDiscount({
          discountId,
          discountUpdateInput: data,
        });
        showMessage(getPhrase("DISCOUNT_UPDATED_SUCCESSFULLY"), "success");
        navigate(`/discounts`);
      } else {
        await discountService.createDiscount({
          discountCreateInput: data,
        });
        showMessage(getPhrase("DISCOUNT_CREATED_SUCCESSFULLY"), "success");
        navigate(`/discounts`);
      }
    } catch (error) {
      console.error("Error saving discount:", error);
      showMessage(getPhrase("ERROR.GENERIC.SAVE_DATA"), "error");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Box my={5}>
        <Typography component="h1" variant="h4">
          {isEditMode
            ? getPhrase("EDIT_DISCOUNT")
            : getPhrase("CREATE_DISCOUNT")}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {/* Name */}
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth
                  label={getPhrase("DISCOUNT_NAME")}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  size="small"
                />
              )}
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={getPhrase("DISCOUNT_DESCRIPTION")}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  size="small"
                />
              )}
            />
          </Grid>

          {/* Discount Type */}
          <Grid item xs={4}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  select
                  label={getPhrase("DISCOUNT_TYPE")}
                  variant="outlined"
                  fullWidth
                  error={!!errors.type}
                  helperText={errors.type?.message}
                >
                  {/* Map OrderType enum to menu items */}
                  {Object.values(DiscountType).map((type) => (
                    <MenuItem key={type} value={type}>
                      {getPhrase(`DISCOUNT_TYPE.${type}` as any)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {/* Product Category */}
          <Grid item xs={4}>
            <Controller
              name="productCategory"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  select
                  label={getPhrase("PRODUCT_CATEGORY")}
                  variant="outlined"
                  fullWidth
                  error={!!errors.productCategory}
                  helperText={errors.productCategory?.message}
                >
                  {/* Map ProductCategory enum to menu items */}
                  {Object.values(ProductCategory).map((category) => (
                    <MenuItem key={category} value={category}>
                      {getPhrase(`PRODUCT_CATEGORY.${category}` as any)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {/* Discount Value */}
          <Grid item xs={4}>
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth
                  label={getPhrase("DISCOUNT_VALUE")}
                  type="number"
                  error={!!errors.value}
                  helperText={errors.value?.message}
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>

        <Box display={"flex"} justifyContent={"center"}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, width: "25%" }}
          >
            {isEditMode
              ? getPhrase("SAVE_CHANGES")
              : getPhrase("CREATE_DISCOUNT")}
          </Button>
        </Box>
      </form>
    </Container>
  );
};
