import Joi from "joi";
import { Cities, Configuration, CustomerApi } from "../generated-api";
import { getAccessToken } from "../utils/getAccessToken";
import { emailRegex } from "../constants/validation";

// Address validation schema
export const addressValidationSchema = Joi.object({
  number: Joi.string().trim().required(),
  neighborhood: Joi.string().trim().required(),
  street: Joi.string().trim().required(),
  city: Joi.string()
    .trim()
    .valid(...Object.values(Cities))
    .required(),
  state: Joi.string().trim().required(),
  postalCode: Joi.string().trim().required(),
  additionalInfo: Joi.string().trim().allow("").optional(), // Allow empty string
});

const address2ValidationSchema = Joi.object({
  number: Joi.string().allow("").optional(),
  neighborhood: Joi.string().allow("").optional(),
  street: Joi.string().allow("").optional(),
  city: Joi.string()
    .allow("")
    .valid(...Object.values(Cities))
    .optional(),
  state: Joi.string().allow("").optional(),
  postalCode: Joi.string().allow("").optional(),
  additionalInfo: Joi.string().allow("").optional(),
});

export const contactValidationSchema = Joi.object({
  firstName: Joi.string().trim().required(),
  lastName: Joi.string().trim().required(),
  phone: Joi.string().trim().required(),
});

// Joi schema for validation
export const customerCreateValidationSchema = Joi.object({
  firstName: Joi.string().trim().required(),
  lastName: Joi.string().trim().required(),
  cpf: Joi.string().trim().allow("").optional(), // Allow empty string
  cnpj: Joi.string().trim().allow("").optional(), // Allow empty string
  email: Joi.string().trim().pattern(emailRegex).required().messages({
    "string.pattern.base": `"email" must be a valid email address`,
  }),
  email2: Joi.string()
    .trim()
    .pattern(emailRegex)
    .allow("")
    .optional()
    .messages({
      "string.pattern.base": `"email" must be a valid email address`,
    }),
  phone: Joi.string().trim().required(),
  phone2: Joi.string().trim().allow("").optional(), // Allow empty string
  address: addressValidationSchema.required(),
  address2: address2ValidationSchema.optional(), // Secondary address is optional
  dob: Joi.date().optional(),
  notes: Joi.string().trim().allow("").optional(), // Allow empty string
  contacts: Joi.array().items(contactValidationSchema).optional(),
});

export const contactUpdateValidationSchema = Joi.object({
  firstName: Joi.string().trim().optional(),
  lastName: Joi.string().trim().optional(),
  phone: Joi.string().trim().optional(),
});

export const customerUpdateValidationSchema = Joi.object({
  firstName: Joi.string().trim().optional(),
  lastName: Joi.string().trim().optional(),
  cpf: Joi.string().trim().optional(),
  cnpj: Joi.string().trim().optional(),
  email: Joi.string().pattern(emailRegex).optional().messages({
    "string.pattern.base": `"email" must be a valid email address`,
  }),
  email2: Joi.string().pattern(emailRegex).allow("").optional().messages({
    "string.pattern.base": `"email" must be a valid email address`,
  }),
  phone: Joi.string().trim().optional(),
  phone2: Joi.string().trim().optional(),
  contacts: Joi.array().items(contactUpdateValidationSchema).optional(),
  address: addressValidationSchema.optional(),
  address2: address2ValidationSchema.optional(),
  dob: Joi.date().optional(),
  notes: Joi.string().trim().allow("").optional(),
});

class CustomerService extends CustomerApi {
  constructor() {
    const config = new Configuration({
      accessToken: getAccessToken,
      basePath: process.env.REACT_APP_API_URL,
    });

    super(config);
  }
}

export const customerService = new CustomerService();
