import React, { useContext } from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  styled,
  AppBarProps as MuiAppBarProps,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { usePhrases } from "../../context/PhrasesContext";
import { Chip } from "@mui/joy";
import MenuIcon from "@mui/icons-material/Menu";

import { version } from "../../version";
import { drawerWidth } from "./Sidebar";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Navbar = ({
  handleDrawerOpen,
  open,
}: {
  handleDrawerOpen: () => void;
  open: boolean;
}) => {
  const navigate = useNavigate();
  const { authState, logout, setSelectedBranch } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { getPhrase } = usePhrases(); // Use the getPhrase function

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewProfile = () => {
    handleClose();
    navigate(`/users/${authState.user?.id}`);
  };

  // Function to handle click on 'Maestro'
  const handleTitleClick = () => {
    navigate("/");
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, cursor: "pointer" }}
          onClick={handleTitleClick}
        >
          {getPhrase("NAVBAR.TITLE")} - {version}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1, // Creates a gap between text and icon
          }}
        >
          <Box>
            {authState.user?.branches.map((branch) => (
              <Chip
                style={{ margin: "0 0.5rem" }}
                key={branch}
                size="md"
                variant="soft"
                color={
                  branch === authState.user?.selectedBranch
                    ? "success"
                    : "neutral"
                }
                onClick={() => {
                  setSelectedBranch(branch);
                }}
              >
                {branch.replace("_", " ")}
              </Chip>
            ))}
          </Box>
          <Typography variant="subtitle1">
            {authState.user?.firstName} {authState.user?.lastName}
          </Typography>
          <IconButton
            size="large"
            edge="end"
            aria-label={getPhrase("NAVBAR.ACCOUNT_BUTTON_ARIA_LABEL")} // Internationalized ARIA label
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleViewProfile}>
              {getPhrase("NAVBAR.VIEW_PROFILE")}
            </MenuItem>{" "}
            {/* Internationalized menu item */}
            <MenuItem onClick={logout}>
              {getPhrase("NAVBAR.LOGOUT")}
            </MenuItem>{" "}
            {/* Internationalized menu item */}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
