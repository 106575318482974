/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderTags = {
    Choppback: 'CHOPPBACK',
    CreditBalance: 'CREDIT_BALANCE',
    Voucher: 'VOUCHER',
    Coupon: 'COUPON'
} as const;
export type OrderTags = typeof OrderTags[keyof typeof OrderTags];


export function OrderTagsFromJSON(json: any): OrderTags {
    return OrderTagsFromJSONTyped(json, false);
}

export function OrderTagsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTags {
    return json as OrderTags;
}

export function OrderTagsToJSON(value?: OrderTags | null): any {
    return value as any;
}

