/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountType } from './DiscountType';
import {
    DiscountTypeFromJSON,
    DiscountTypeFromJSONTyped,
    DiscountTypeToJSON,
} from './DiscountType';
import type { ProductCategory } from './ProductCategory';
import {
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';

/**
 * 
 * @export
 * @interface DiscountUpdateInput
 */
export interface DiscountUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof DiscountUpdateInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountUpdateInput
     */
    description?: string;
    /**
     * 
     * @type {DiscountType}
     * @memberof DiscountUpdateInput
     */
    type?: DiscountType;
    /**
     * 
     * @type {number}
     * @memberof DiscountUpdateInput
     */
    value?: number;
    /**
     * 
     * @type {ProductCategory}
     * @memberof DiscountUpdateInput
     */
    productCategory?: ProductCategory;
}

/**
 * Check if a given object implements the DiscountUpdateInput interface.
 */
export function instanceOfDiscountUpdateInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DiscountUpdateInputFromJSON(json: any): DiscountUpdateInput {
    return DiscountUpdateInputFromJSONTyped(json, false);
}

export function DiscountUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : DiscountTypeFromJSON(json['type']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'productCategory': !exists(json, 'productCategory') ? undefined : ProductCategoryFromJSON(json['productCategory']),
    };
}

export function DiscountUpdateInputToJSON(value?: DiscountUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'type': DiscountTypeToJSON(value.type),
        'value': value.value,
        'productCategory': ProductCategoryToJSON(value.productCategory),
    };
}

