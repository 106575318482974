/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderType = {
    Normal: 'NORMAL',
    Consigned: 'CONSIGNED'
} as const;
export type OrderType = typeof OrderType[keyof typeof OrderType];


export function OrderTypeFromJSON(json: any): OrderType {
    return OrderTypeFromJSONTyped(json, false);
}

export function OrderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderType {
    return json as OrderType;
}

export function OrderTypeToJSON(value?: OrderType | null): any {
    return value as any;
}

