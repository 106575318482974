/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TimeOfDay = {
    Morning: 'MORNING',
    Afternoon: 'AFTERNOON',
    Evening: 'EVENING'
} as const;
export type TimeOfDay = typeof TimeOfDay[keyof typeof TimeOfDay];


export function TimeOfDayFromJSON(json: any): TimeOfDay {
    return TimeOfDayFromJSONTyped(json, false);
}

export function TimeOfDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeOfDay {
    return json as TimeOfDay;
}

export function TimeOfDayToJSON(value?: TimeOfDay | null): any {
    return value as any;
}

