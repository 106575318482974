/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EquipmentCreateInput,
  EquipmentOutput,
  EquipmentUpdateInput,
  PagedOutputEquipmentOutput,
} from '../models/index';
import {
    EquipmentCreateInputFromJSON,
    EquipmentCreateInputToJSON,
    EquipmentOutputFromJSON,
    EquipmentOutputToJSON,
    EquipmentUpdateInputFromJSON,
    EquipmentUpdateInputToJSON,
    PagedOutputEquipmentOutputFromJSON,
    PagedOutputEquipmentOutputToJSON,
} from '../models/index';

export interface CreateEquipmentRequest {
    equipmentCreateInput: EquipmentCreateInput;
}

export interface DeleteEquipmentRequest {
    equipmentId: string;
}

export interface GetEquipmentRequest {
    equipmentId: string;
}

export interface GetEquipmentsRequest {
    page?: number;
    perPage?: number;
    search?: string;
}

export interface UpdateEquipmentRequest {
    equipmentId: string;
    equipmentUpdateInput: EquipmentUpdateInput;
}

/**
 * 
 */
export class EquipmentApi extends runtime.BaseAPI {

    /**
     * Create equipment
     */
    async createEquipmentRaw(requestParameters: CreateEquipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EquipmentOutput>> {
        if (requestParameters.equipmentCreateInput === null || requestParameters.equipmentCreateInput === undefined) {
            throw new runtime.RequiredError('equipmentCreateInput','Required parameter requestParameters.equipmentCreateInput was null or undefined when calling createEquipment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/equipments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EquipmentCreateInputToJSON(requestParameters.equipmentCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentOutputFromJSON(jsonValue));
    }

    /**
     * Create equipment
     */
    async createEquipment(requestParameters: CreateEquipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EquipmentOutput> {
        const response = await this.createEquipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete (archive) equipment
     */
    async deleteEquipmentRaw(requestParameters: DeleteEquipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.equipmentId === null || requestParameters.equipmentId === undefined) {
            throw new runtime.RequiredError('equipmentId','Required parameter requestParameters.equipmentId was null or undefined when calling deleteEquipment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/equipments/{equipmentId}`.replace(`{${"equipmentId"}}`, encodeURIComponent(String(requestParameters.equipmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete (archive) equipment
     */
    async deleteEquipment(requestParameters: DeleteEquipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEquipmentRaw(requestParameters, initOverrides);
    }

    /**
     * Get equipment by id
     */
    async getEquipmentRaw(requestParameters: GetEquipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EquipmentOutput>> {
        if (requestParameters.equipmentId === null || requestParameters.equipmentId === undefined) {
            throw new runtime.RequiredError('equipmentId','Required parameter requestParameters.equipmentId was null or undefined when calling getEquipment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/equipments/{equipmentId}`.replace(`{${"equipmentId"}}`, encodeURIComponent(String(requestParameters.equipmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentOutputFromJSON(jsonValue));
    }

    /**
     * Get equipment by id
     */
    async getEquipment(requestParameters: GetEquipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EquipmentOutput> {
        const response = await this.getEquipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get multiple pieces of equipment
     */
    async getEquipmentsRaw(requestParameters: GetEquipmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedOutputEquipmentOutput>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/equipments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedOutputEquipmentOutputFromJSON(jsonValue));
    }

    /**
     * Get multiple pieces of equipment
     */
    async getEquipments(requestParameters: GetEquipmentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedOutputEquipmentOutput> {
        const response = await this.getEquipmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update equipment
     */
    async updateEquipmentRaw(requestParameters: UpdateEquipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EquipmentOutput>> {
        if (requestParameters.equipmentId === null || requestParameters.equipmentId === undefined) {
            throw new runtime.RequiredError('equipmentId','Required parameter requestParameters.equipmentId was null or undefined when calling updateEquipment.');
        }

        if (requestParameters.equipmentUpdateInput === null || requestParameters.equipmentUpdateInput === undefined) {
            throw new runtime.RequiredError('equipmentUpdateInput','Required parameter requestParameters.equipmentUpdateInput was null or undefined when calling updateEquipment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/equipments/{equipmentId}`.replace(`{${"equipmentId"}}`, encodeURIComponent(String(requestParameters.equipmentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: EquipmentUpdateInputToJSON(requestParameters.equipmentUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentOutputFromJSON(jsonValue));
    }

    /**
     * Update equipment
     */
    async updateEquipment(requestParameters: UpdateEquipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EquipmentOutput> {
        const response = await this.updateEquipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
