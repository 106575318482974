/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransitionOrderStatusDeliveredRequest
 */
export interface TransitionOrderStatusDeliveredRequest {
    /**
     * 
     * @type {string}
     * @memberof TransitionOrderStatusDeliveredRequest
     */
    deliveredBy: string;
    /**
     * 
     * @type {Date}
     * @memberof TransitionOrderStatusDeliveredRequest
     */
    deliveredAt: Date;
}

/**
 * Check if a given object implements the TransitionOrderStatusDeliveredRequest interface.
 */
export function instanceOfTransitionOrderStatusDeliveredRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveredBy" in value;
    isInstance = isInstance && "deliveredAt" in value;

    return isInstance;
}

export function TransitionOrderStatusDeliveredRequestFromJSON(json: any): TransitionOrderStatusDeliveredRequest {
    return TransitionOrderStatusDeliveredRequestFromJSONTyped(json, false);
}

export function TransitionOrderStatusDeliveredRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransitionOrderStatusDeliveredRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveredBy': json['deliveredBy'],
        'deliveredAt': (new Date(json['deliveredAt'])),
    };
}

export function TransitionOrderStatusDeliveredRequestToJSON(value?: TransitionOrderStatusDeliveredRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveredBy': value.deliveredBy,
        'deliveredAt': (value.deliveredAt.toISOString()),
    };
}

