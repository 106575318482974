import React, { createContext, useContext, useState, ReactNode } from "react";
import { PhraseKeys } from "../constants/PhraseKeys";

export interface Phrase {
  en: string;
  pt: string;
}

export interface Phrases {
  [key: string]: Phrase;
}

interface PhrasesContextProps {
  getPhrase: (key: PhraseKeys) => string;
  setLanguage: (language: "en" | "pt") => void;
}

const PhrasesContext = createContext<PhrasesContextProps | undefined>(
  undefined
);

export const usePhrases = () => {
  const context = useContext(PhrasesContext);
  if (!context) {
    throw new Error("usePhrases must be used within a PhrasesProvider");
  }
  return context;
};

interface PhrasesProviderProps {
  children: ReactNode;
  initialLanguage?: "en" | "pt";
  phrases: Phrases;
}

export const PhrasesProvider: React.FC<PhrasesProviderProps> = ({
  children,
  initialLanguage = "pt",
  phrases,
}) => {
  const [language, setLanguage] = useState<"en" | "pt">(initialLanguage);

  const getPhrase = (key: PhraseKeys) => {
    const phrase = phrases[key];
    return phrase ? phrase[language] : key;
  };

  return (
    <PhrasesContext.Provider value={{ getPhrase, setLanguage }}>
      {children}
    </PhrasesContext.Provider>
  );
};
