import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import { usePhrases } from "../../context/PhrasesContext";

export const ForbiddenPage: React.FC = () => {
  const navigate = useNavigate();
  const { getPhrase } = usePhrases();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          my: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ErrorOutlineIcon sx={{ fontSize: 100, mb: 2, color: "error.main" }} />

        <Typography variant="h4" component="h1" gutterBottom>
          {getPhrase("FORBIDDEN_PAGE.TITLE")}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: 3 }}>
          {getPhrase("FORBIDDEN_PAGE.MESSAGE")}
        </Typography>

        <Button
          variant="contained"
          onClick={() => {
            navigate("/");
          }}
          sx={{ mt: 3 }}
        >
          {getPhrase("FORBIDDEN_PAGE.GO_TO_HOME")}
        </Button>
      </Box>
    </Container>
  );
};
