/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderEquipmentOutput
 */
export interface OrderEquipmentOutput {
    /**
     * 
     * @type {string}
     * @memberof OrderEquipmentOutput
     */
    equipmentId: string;
    /**
     * 
     * @type {number}
     * @memberof OrderEquipmentOutput
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof OrderEquipmentOutput
     */
    pricePerUnit: number;
    /**
     * 
     * @type {string}
     * @memberof OrderEquipmentOutput
     */
    referenceCode?: string;
}

/**
 * Check if a given object implements the OrderEquipmentOutput interface.
 */
export function instanceOfOrderEquipmentOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "equipmentId" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "pricePerUnit" in value;

    return isInstance;
}

export function OrderEquipmentOutputFromJSON(json: any): OrderEquipmentOutput {
    return OrderEquipmentOutputFromJSONTyped(json, false);
}

export function OrderEquipmentOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderEquipmentOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equipmentId': json['equipmentId'],
        'quantity': json['quantity'],
        'pricePerUnit': json['pricePerUnit'],
        'referenceCode': !exists(json, 'referenceCode') ? undefined : json['referenceCode'],
    };
}

export function OrderEquipmentOutputToJSON(value?: OrderEquipmentOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equipmentId': value.equipmentId,
        'quantity': value.quantity,
        'pricePerUnit': value.pricePerUnit,
        'referenceCode': value.referenceCode,
    };
}

