export enum Resources {
  CUSTOMERS = "CUSTOMERS",
  EQUIPMENTS = "EQUIPMENTS",
  ORDERS = "ORDERS",
  PRODUCTS = "PRODUCTS",
  USERS = "USERS",
  USERS_ADMIN = "USERS_ADMIN",
  MEDIA = "MEDIA",
  ANALYTICS = "ANALYTICS",
  DISCOUNTS = "DISCOUNTS",
}

export enum Action {
  GET = "GET",
  LIST = "LIST",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export const buildPermission = (resource: Resources, action: Action) =>
  `${resource}:${action}`;
