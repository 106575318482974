import React, { useMemo } from "react";
import { Typography, Grid, Box } from "@mui/material";
import {
  OrderOutput,
  OrderPaymentOutput,
  OrderPaymentUpdateInput,
} from "../../generated-api";
import { usePhrases } from "../../context/PhrasesContext";
import { Chip } from "@mui/joy";

export const OrderValueSummary: React.FC<{
  order: Pick<
    OrderOutput,
    | "items"
    | "itemsTotal"
    | "orderEquipment"
    | "orderEquipmentTotal"
    | "deliveryFee"
    | "discounts"
    | "totalAmount"
    | "discountTotal"
    | "paymentTotal"
    | "payments"
    | "balance"
  >;
  payments: (OrderPaymentUpdateInput | OrderPaymentOutput)[];
}> = ({ order, payments }) => {
  const { getPhrase } = usePhrases();
  const debug = false;

  const printCurrencyValue = (value: number) => {
    return `${getPhrase("CURRENCY.SYMBOL")} ${(Number(value) ?? 0).toFixed(2)}`;
  };

  const totalPayments = useMemo(() => {
    return payments.reduce(
      (acc, payment) => Number(acc) + Number(payment.amount),
      0
    );
  }, [payments]);

  const totalEquipments = useMemo(() => {
    return order.orderEquipment.reduce(
      (acc, equipment) =>
        Number(acc) +
        Number(equipment.quantity) * Number(equipment.pricePerUnit),
      0
    );
  }, [order]);

  const totalProducts = useMemo(() => {
    return order.items.reduce(
      (acc, item) =>
        Number(acc) + Number(item.quantity) * Number(item.pricePerUnit),
      0
    );
  }, [order]);

  const totalDiscounts = useMemo(() => {
    return order.discounts.reduce(
      (acc, discount) => Number(acc) + Number(discount.discounted),
      0
    );
  }, [order.discounts]);

  const totalToPay =
    totalEquipments + totalProducts + order.deliveryFee - totalDiscounts;

  const totalRemaining = totalToPay - totalPayments;

  return (
    <Grid item container xs={12} spacing={1}>
      {/* Equipments total */}
      <Grid item container xs={12} mt={3} spacing={1}>
        <Grid item xs={3}>
          <Typography variant="body1" fontWeight={"bold"}>
            {getPhrase("ORDER_EQUIPMENT_TOTAL")}:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" align="left">
            {printCurrencyValue(totalEquipments)}{" "}
            {debug && printCurrencyValue(order.orderEquipmentTotal)}
          </Typography>
        </Grid>
        {/* Products Total */}
        <Grid item xs={3}>
          <Typography variant="body1" fontWeight={"bold"}>
            {getPhrase("ORDER_ITEMS_TOTAL")}:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" align="left">
            {printCurrencyValue(totalProducts)}{" "}
            {debug && printCurrencyValue(order.itemsTotal)}
          </Typography>
        </Grid>
        {/* Deliver Fee */}
        <Grid item xs={3}>
          <Typography fontWeight={"bold"} variant="body1">
            {getPhrase("DELIVERY_FEE")}:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" align="left">
            {printCurrencyValue(order.deliveryFee)}
          </Typography>
        </Grid>
        {/* Discounts */}
        <Grid item xs={3}>
          <Typography variant="body1" fontWeight={"bold"}>
            {getPhrase("ORDER_DISCOUNTS")}:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Box display="flex">
            <Typography variant="body1" align="left">
              - {printCurrencyValue(totalDiscounts)}{" "}
              {debug && printCurrencyValue(order.discountTotal)}
            </Typography>
            {order.discounts.map((discount, index) => (
              <Chip sx={{ ml: 1 }} key={discount.discountId}>
                {discount.name} - {printCurrencyValue(discount.discounted)}
              </Chip>
            ))}
          </Box>
        </Grid>
        {/* Total to pay */}
        <Grid item xs={3}>
          <Typography variant="body1" fontWeight={"bold"}>
            {getPhrase("ORDER_TOTAL")}:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1">
            {printCurrencyValue(totalToPay)}
          </Typography>
        </Grid>
        {/* Total of payments */}
        <Grid item container xs={12} spacing={1} mt={1}>
          <Grid item xs={3}>
            <Typography variant="body1" fontWeight={"bold"}>
              {getPhrase("ORDER_TOTAL_PAYMENTS")}:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" align="left">
              {printCurrencyValue(totalPayments)}{" "}
              {debug && printCurrencyValue(order.paymentTotal)}
            </Typography>
          </Grid>
        </Grid>
        {/* Remaining balance */}
        <Grid item container xs={12} spacing={1} mt={2}>
          <Grid item xs={3}>
            <Typography variant="h6" fontWeight={"bold"}>
              {getPhrase("ORDER_REMAINING_BALANCE")}:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h6" align="left">
              {printCurrencyValue(totalRemaining)}{" "}
              {debug && printCurrencyValue(order.balance)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
