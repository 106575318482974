/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EquipmentCategory = {
    Chopeira: 'CHOPEIRA',
    Other: 'OTHER'
} as const;
export type EquipmentCategory = typeof EquipmentCategory[keyof typeof EquipmentCategory];


export function EquipmentCategoryFromJSON(json: any): EquipmentCategory {
    return EquipmentCategoryFromJSONTyped(json, false);
}

export function EquipmentCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentCategory {
    return json as EquipmentCategory;
}

export function EquipmentCategoryToJSON(value?: EquipmentCategory | null): any {
    return value as any;
}

