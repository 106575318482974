/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductCategory } from './ProductCategory';
import {
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';
import type { SizeUnit } from './SizeUnit';
import {
    SizeUnitFromJSON,
    SizeUnitFromJSONTyped,
    SizeUnitToJSON,
} from './SizeUnit';

/**
 * 
 * @export
 * @interface ProductCreateInput
 */
export interface ProductCreateInput {
    /**
     * 
     * @type {string}
     * @memberof ProductCreateInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateInput
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateInput
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateInput
     */
    size: number;
    /**
     * 
     * @type {SizeUnit}
     * @memberof ProductCreateInput
     */
    sizeUnit: SizeUnit;
    /**
     * 
     * @type {ProductCategory}
     * @memberof ProductCreateInput
     */
    category: ProductCategory;
}

/**
 * Check if a given object implements the ProductCreateInput interface.
 */
export function instanceOfProductCreateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "sizeUnit" in value;
    isInstance = isInstance && "category" in value;

    return isInstance;
}

export function ProductCreateInputFromJSON(json: any): ProductCreateInput {
    return ProductCreateInputFromJSONTyped(json, false);
}

export function ProductCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'price': json['price'],
        'size': json['size'],
        'sizeUnit': SizeUnitFromJSON(json['sizeUnit']),
        'category': ProductCategoryFromJSON(json['category']),
    };
}

export function ProductCreateInputToJSON(value?: ProductCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'size': value.size,
        'sizeUnit': SizeUnitToJSON(value.sizeUnit),
        'category': ProductCategoryToJSON(value.category),
    };
}

