/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SummarizedOrderDataByDayOutputDaysInnerEquipmentsInner } from './SummarizedOrderDataByDayOutputDaysInnerEquipmentsInner';
import {
    SummarizedOrderDataByDayOutputDaysInnerEquipmentsInnerFromJSON,
    SummarizedOrderDataByDayOutputDaysInnerEquipmentsInnerFromJSONTyped,
    SummarizedOrderDataByDayOutputDaysInnerEquipmentsInnerToJSON,
} from './SummarizedOrderDataByDayOutputDaysInnerEquipmentsInner';
import type { SummarizedOrderDataByDayOutputDaysInnerPaymentsInner } from './SummarizedOrderDataByDayOutputDaysInnerPaymentsInner';
import {
    SummarizedOrderDataByDayOutputDaysInnerPaymentsInnerFromJSON,
    SummarizedOrderDataByDayOutputDaysInnerPaymentsInnerFromJSONTyped,
    SummarizedOrderDataByDayOutputDaysInnerPaymentsInnerToJSON,
} from './SummarizedOrderDataByDayOutputDaysInnerPaymentsInner';
import type { SummarizedOrderDataByDayOutputDaysInnerProductsInner } from './SummarizedOrderDataByDayOutputDaysInnerProductsInner';
import {
    SummarizedOrderDataByDayOutputDaysInnerProductsInnerFromJSON,
    SummarizedOrderDataByDayOutputDaysInnerProductsInnerFromJSONTyped,
    SummarizedOrderDataByDayOutputDaysInnerProductsInnerToJSON,
} from './SummarizedOrderDataByDayOutputDaysInnerProductsInner';

/**
 * 
 * @export
 * @interface SummarizedOrderDataByDayOutputDaysInner
 */
export interface SummarizedOrderDataByDayOutputDaysInner {
    /**
     * 
     * @type {Array<SummarizedOrderDataByDayOutputDaysInnerPaymentsInner>}
     * @memberof SummarizedOrderDataByDayOutputDaysInner
     */
    payments: Array<SummarizedOrderDataByDayOutputDaysInnerPaymentsInner>;
    /**
     * 
     * @type {number}
     * @memberof SummarizedOrderDataByDayOutputDaysInner
     */
    deliveryFees: number;
    /**
     * 
     * @type {Array<SummarizedOrderDataByDayOutputDaysInnerEquipmentsInner>}
     * @memberof SummarizedOrderDataByDayOutputDaysInner
     */
    equipments: Array<SummarizedOrderDataByDayOutputDaysInnerEquipmentsInner>;
    /**
     * 
     * @type {Array<SummarizedOrderDataByDayOutputDaysInnerProductsInner>}
     * @memberof SummarizedOrderDataByDayOutputDaysInner
     */
    products: Array<SummarizedOrderDataByDayOutputDaysInnerProductsInner>;
    /**
     * 
     * @type {number}
     * @memberof SummarizedOrderDataByDayOutputDaysInner
     */
    totalValue: number;
    /**
     * 
     * @type {number}
     * @memberof SummarizedOrderDataByDayOutputDaysInner
     */
    ordersCount: number;
    /**
     * 
     * @type {Date}
     * @memberof SummarizedOrderDataByDayOutputDaysInner
     */
    date: Date;
}

/**
 * Check if a given object implements the SummarizedOrderDataByDayOutputDaysInner interface.
 */
export function instanceOfSummarizedOrderDataByDayOutputDaysInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "payments" in value;
    isInstance = isInstance && "deliveryFees" in value;
    isInstance = isInstance && "equipments" in value;
    isInstance = isInstance && "products" in value;
    isInstance = isInstance && "totalValue" in value;
    isInstance = isInstance && "ordersCount" in value;
    isInstance = isInstance && "date" in value;

    return isInstance;
}

export function SummarizedOrderDataByDayOutputDaysInnerFromJSON(json: any): SummarizedOrderDataByDayOutputDaysInner {
    return SummarizedOrderDataByDayOutputDaysInnerFromJSONTyped(json, false);
}

export function SummarizedOrderDataByDayOutputDaysInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummarizedOrderDataByDayOutputDaysInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payments': ((json['payments'] as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerPaymentsInnerFromJSON)),
        'deliveryFees': json['deliveryFees'],
        'equipments': ((json['equipments'] as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerEquipmentsInnerFromJSON)),
        'products': ((json['products'] as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerProductsInnerFromJSON)),
        'totalValue': json['totalValue'],
        'ordersCount': json['ordersCount'],
        'date': (new Date(json['date'])),
    };
}

export function SummarizedOrderDataByDayOutputDaysInnerToJSON(value?: SummarizedOrderDataByDayOutputDaysInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payments': ((value.payments as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerPaymentsInnerToJSON)),
        'deliveryFees': value.deliveryFees,
        'equipments': ((value.equipments as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerEquipmentsInnerToJSON)),
        'products': ((value.products as Array<any>).map(SummarizedOrderDataByDayOutputDaysInnerProductsInnerToJSON)),
        'totalValue': value.totalValue,
        'ordersCount': value.ordersCount,
        'date': (value.date.toISOString()),
    };
}

