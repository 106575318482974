/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Cities,
  GetOneOrder200Response,
  OrderCreateInput,
  OrderOutput,
  OrderSource,
  OrderStatus,
  OrderTags,
  OrderType,
  OrderUpdateInput,
  PagedOutputOrderOutput,
  StoreBranch,
  TransitionOrderStatusCanceledRequest,
  TransitionOrderStatusDeliveredRequest,
} from '../models/index';
import {
    CitiesFromJSON,
    CitiesToJSON,
    GetOneOrder200ResponseFromJSON,
    GetOneOrder200ResponseToJSON,
    OrderCreateInputFromJSON,
    OrderCreateInputToJSON,
    OrderOutputFromJSON,
    OrderOutputToJSON,
    OrderSourceFromJSON,
    OrderSourceToJSON,
    OrderStatusFromJSON,
    OrderStatusToJSON,
    OrderTagsFromJSON,
    OrderTagsToJSON,
    OrderTypeFromJSON,
    OrderTypeToJSON,
    OrderUpdateInputFromJSON,
    OrderUpdateInputToJSON,
    PagedOutputOrderOutputFromJSON,
    PagedOutputOrderOutputToJSON,
    StoreBranchFromJSON,
    StoreBranchToJSON,
    TransitionOrderStatusCanceledRequestFromJSON,
    TransitionOrderStatusCanceledRequestToJSON,
    TransitionOrderStatusDeliveredRequestFromJSON,
    TransitionOrderStatusDeliveredRequestToJSON,
} from '../models/index';

export interface AddFileToOrderRequest {
    orderId: string;
    file: Blob;
    displayName: string;
    orderMediaCategory: string;
    description?: string;
}

export interface CreateOrderRequest {
    orderCreateInput: OrderCreateInput;
}

export interface DeleteFileFromOrderRequest {
    orderId: string;
    mediaId: string;
}

export interface GetManyOrdersRequest {
    branch?: StoreBranch;
    cnpj?: string;
    cpf?: string;
    customerId?: string;
    source?: OrderSource;
    customerName?: string;
    scheduledDateGte?: Date;
    scheduledDateLte?: Date;
    returnDateGte?: Date;
    returnDateLte?: Date;
    status?: Array<OrderStatus>;
    type?: Array<OrderType>;
    tags?: Array<OrderTags>;
    key?: string;
    deliveryAddressNeighborhood?: string;
    deliveryAddressCities?: Array<Cities>;
    page?: number;
    perPage?: number;
    joinCustomer?: boolean;
    sort?: string;
}

export interface GetOneOrderRequest {
    orderId: string;
    joinCustomer?: boolean;
    joinProduct?: boolean;
    joinEquipment?: boolean;
}

export interface TransitionOrderStatusCanceledOperationRequest {
    orderId: string;
    transitionOrderStatusCanceledRequest: TransitionOrderStatusCanceledRequest;
}

export interface TransitionOrderStatusClosedRequest {
    orderId: string;
}

export interface TransitionOrderStatusConfirmedRequest {
    orderId: string;
}

export interface TransitionOrderStatusDeliveredOperationRequest {
    orderId: string;
    transitionOrderStatusDeliveredRequest: TransitionOrderStatusDeliveredRequest;
}

export interface TransitionOrderStatusOutForDeliveryRequest {
    orderId: string;
}

export interface TransitionOrderStatusReturnScheduledRequest {
    orderId: string;
}

export interface TransitionOrderStatusReturnedRequest {
    orderId: string;
}

export interface TransitionOrderStatusVerifiedRequest {
    orderId: string;
}

export interface UpdateOrderRequest {
    orderId: string;
    orderUpdateInput: OrderUpdateInput;
}

/**
 * 
 */
export class OrderApi extends runtime.BaseAPI {

    /**
     * Add a file to an order
     */
    async addFileToOrderRaw(requestParameters: AddFileToOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling addFileToOrder.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addFileToOrder.');
        }

        if (requestParameters.displayName === null || requestParameters.displayName === undefined) {
            throw new runtime.RequiredError('displayName','Required parameter requestParameters.displayName was null or undefined when calling addFileToOrder.');
        }

        if (requestParameters.orderMediaCategory === null || requestParameters.orderMediaCategory === undefined) {
            throw new runtime.RequiredError('orderMediaCategory','Required parameter requestParameters.orderMediaCategory was null or undefined when calling addFileToOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.displayName !== undefined) {
            formParams.append('displayName', requestParameters.displayName as any);
        }

        if (requestParameters.orderMediaCategory !== undefined) {
            formParams.append('orderMediaCategory', requestParameters.orderMediaCategory as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        const response = await this.request({
            path: `/v1/orders/{orderId}/files`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Add a file to an order
     */
    async addFileToOrder(requestParameters: AddFileToOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.addFileToOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an order
     */
    async createOrderRaw(requestParameters: CreateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderCreateInput === null || requestParameters.orderCreateInput === undefined) {
            throw new runtime.RequiredError('orderCreateInput','Required parameter requestParameters.orderCreateInput was null or undefined when calling createOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderCreateInputToJSON(requestParameters.orderCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Create an order
     */
    async createOrder(requestParameters: CreateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.createOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a file from an order
     */
    async deleteFileFromOrderRaw(requestParameters: DeleteFileFromOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling deleteFileFromOrder.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling deleteFileFromOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}/files/{mediaId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Delete a file from an order
     */
    async deleteFileFromOrder(requestParameters: DeleteFileFromOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.deleteFileFromOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get multiple orders
     */
    async getManyOrdersRaw(requestParameters: GetManyOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedOutputOrderOutput>> {
        const queryParameters: any = {};

        if (requestParameters.branch !== undefined) {
            queryParameters['branch'] = requestParameters.branch;
        }

        if (requestParameters.cnpj !== undefined) {
            queryParameters['cnpj'] = requestParameters.cnpj;
        }

        if (requestParameters.cpf !== undefined) {
            queryParameters['cpf'] = requestParameters.cpf;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.customerName !== undefined) {
            queryParameters['customerName'] = requestParameters.customerName;
        }

        if (requestParameters.scheduledDateGte !== undefined) {
            queryParameters['scheduledDateGte'] = (requestParameters.scheduledDateGte as any).toISOString();
        }

        if (requestParameters.scheduledDateLte !== undefined) {
            queryParameters['scheduledDateLte'] = (requestParameters.scheduledDateLte as any).toISOString();
        }

        if (requestParameters.returnDateGte !== undefined) {
            queryParameters['returnDateGte'] = (requestParameters.returnDateGte as any).toISOString();
        }

        if (requestParameters.returnDateLte !== undefined) {
            queryParameters['returnDateLte'] = (requestParameters.returnDateLte as any).toISOString();
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        if (requestParameters.deliveryAddressNeighborhood !== undefined) {
            queryParameters['deliveryAddressNeighborhood'] = requestParameters.deliveryAddressNeighborhood;
        }

        if (requestParameters.deliveryAddressCities) {
            queryParameters['deliveryAddressCities'] = requestParameters.deliveryAddressCities;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.joinCustomer !== undefined) {
            queryParameters['joinCustomer'] = requestParameters.joinCustomer;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedOutputOrderOutputFromJSON(jsonValue));
    }

    /**
     * Get multiple orders
     */
    async getManyOrders(requestParameters: GetManyOrdersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedOutputOrderOutput> {
        const response = await this.getManyOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get order by id
     */
    async getOneOrderRaw(requestParameters: GetOneOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOneOrder200Response>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling getOneOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.joinCustomer !== undefined) {
            queryParameters['joinCustomer'] = requestParameters.joinCustomer;
        }

        if (requestParameters.joinProduct !== undefined) {
            queryParameters['joinProduct'] = requestParameters.joinProduct;
        }

        if (requestParameters.joinEquipment !== undefined) {
            queryParameters['joinEquipment'] = requestParameters.joinEquipment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOneOrder200ResponseFromJSON(jsonValue));
    }

    /**
     * Get order by id
     */
    async getOneOrder(requestParameters: GetOneOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOneOrder200Response> {
        const response = await this.getOneOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transition order status to CANCELED
     */
    async transitionOrderStatusCanceledRaw(requestParameters: TransitionOrderStatusCanceledOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling transitionOrderStatusCanceled.');
        }

        if (requestParameters.transitionOrderStatusCanceledRequest === null || requestParameters.transitionOrderStatusCanceledRequest === undefined) {
            throw new runtime.RequiredError('transitionOrderStatusCanceledRequest','Required parameter requestParameters.transitionOrderStatusCanceledRequest was null or undefined when calling transitionOrderStatusCanceled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}/transition-status/canceled`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TransitionOrderStatusCanceledRequestToJSON(requestParameters.transitionOrderStatusCanceledRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Transition order status to CANCELED
     */
    async transitionOrderStatusCanceled(requestParameters: TransitionOrderStatusCanceledOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.transitionOrderStatusCanceledRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transition order status to CLOSED
     */
    async transitionOrderStatusClosedRaw(requestParameters: TransitionOrderStatusClosedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling transitionOrderStatusClosed.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}/transition-status/closed`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Transition order status to CLOSED
     */
    async transitionOrderStatusClosed(requestParameters: TransitionOrderStatusClosedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.transitionOrderStatusClosedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transition order status to CONFIRMED
     */
    async transitionOrderStatusConfirmedRaw(requestParameters: TransitionOrderStatusConfirmedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling transitionOrderStatusConfirmed.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}/transition-status/confirmed`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Transition order status to CONFIRMED
     */
    async transitionOrderStatusConfirmed(requestParameters: TransitionOrderStatusConfirmedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.transitionOrderStatusConfirmedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transition order status to DELIVERED
     */
    async transitionOrderStatusDeliveredRaw(requestParameters: TransitionOrderStatusDeliveredOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling transitionOrderStatusDelivered.');
        }

        if (requestParameters.transitionOrderStatusDeliveredRequest === null || requestParameters.transitionOrderStatusDeliveredRequest === undefined) {
            throw new runtime.RequiredError('transitionOrderStatusDeliveredRequest','Required parameter requestParameters.transitionOrderStatusDeliveredRequest was null or undefined when calling transitionOrderStatusDelivered.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}/transition-status/delivered`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TransitionOrderStatusDeliveredRequestToJSON(requestParameters.transitionOrderStatusDeliveredRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Transition order status to DELIVERED
     */
    async transitionOrderStatusDelivered(requestParameters: TransitionOrderStatusDeliveredOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.transitionOrderStatusDeliveredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transition order status to OUT_FOR_DELIVERY
     */
    async transitionOrderStatusOutForDeliveryRaw(requestParameters: TransitionOrderStatusOutForDeliveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling transitionOrderStatusOutForDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}/transition-status/out-for-delivery`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Transition order status to OUT_FOR_DELIVERY
     */
    async transitionOrderStatusOutForDelivery(requestParameters: TransitionOrderStatusOutForDeliveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.transitionOrderStatusOutForDeliveryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transition order status to RETURN_SCHEDULED
     */
    async transitionOrderStatusReturnScheduledRaw(requestParameters: TransitionOrderStatusReturnScheduledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling transitionOrderStatusReturnScheduled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}/transition-status/return-scheduled`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Transition order status to RETURN_SCHEDULED
     */
    async transitionOrderStatusReturnScheduled(requestParameters: TransitionOrderStatusReturnScheduledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.transitionOrderStatusReturnScheduledRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transition order status to RETURNED
     */
    async transitionOrderStatusReturnedRaw(requestParameters: TransitionOrderStatusReturnedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling transitionOrderStatusReturned.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}/transition-status/returned`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Transition order status to RETURNED
     */
    async transitionOrderStatusReturned(requestParameters: TransitionOrderStatusReturnedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.transitionOrderStatusReturnedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transition order status to VERIFIED
     */
    async transitionOrderStatusVerifiedRaw(requestParameters: TransitionOrderStatusVerifiedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling transitionOrderStatusVerified.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}/transition-status/verified`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Transition order status to VERIFIED
     */
    async transitionOrderStatusVerified(requestParameters: TransitionOrderStatusVerifiedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.transitionOrderStatusVerifiedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update order
     */
    async updateOrderRaw(requestParameters: UpdateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderOutput>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling updateOrder.');
        }

        if (requestParameters.orderUpdateInput === null || requestParameters.orderUpdateInput === undefined) {
            throw new runtime.RequiredError('orderUpdateInput','Required parameter requestParameters.orderUpdateInput was null or undefined when calling updateOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OrderUpdateInputToJSON(requestParameters.orderUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderOutputFromJSON(jsonValue));
    }

    /**
     * Update order
     */
    async updateOrder(requestParameters: UpdateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderOutput> {
        const response = await this.updateOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
