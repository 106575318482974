/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents the input for refreshing a user's access token.
 * @export
 * @interface RefreshTokenInput
 */
export interface RefreshTokenInput {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenInput
     */
    refreshToken: string;
}

/**
 * Check if a given object implements the RefreshTokenInput interface.
 */
export function instanceOfRefreshTokenInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "refreshToken" in value;

    return isInstance;
}

export function RefreshTokenInputFromJSON(json: any): RefreshTokenInput {
    return RefreshTokenInputFromJSONTyped(json, false);
}

export function RefreshTokenInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshTokenInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'refreshToken': json['refreshToken'],
    };
}

export function RefreshTokenInputToJSON(value?: RefreshTokenInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'refreshToken': value.refreshToken,
    };
}

