/* tslint:disable */
/* eslint-disable */
/**
 * maestro-api
 * Maestro API
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerMediaCategory } from './CustomerMediaCategory';
import {
    CustomerMediaCategoryFromJSON,
    CustomerMediaCategoryFromJSONTyped,
    CustomerMediaCategoryToJSON,
} from './CustomerMediaCategory';

/**
 * 
 * @export
 * @interface CustomerMediaOutput
 */
export interface CustomerMediaOutput {
    /**
     * 
     * @type {string}
     * @memberof CustomerMediaOutput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMediaOutput
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMediaOutput
     */
    originalname: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMediaOutput
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMediaOutput
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerMediaOutput
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerMediaOutput
     */
    size: number;
    /**
     * 
     * @type {Date}
     * @memberof CustomerMediaOutput
     */
    uploadedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomerMediaOutput
     */
    uploadedBy: string;
    /**
     * 
     * @type {CustomerMediaCategory}
     * @memberof CustomerMediaOutput
     */
    customerMediaCategory: CustomerMediaCategory;
}

/**
 * Check if a given object implements the CustomerMediaOutput interface.
 */
export function instanceOfCustomerMediaOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "originalname" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "uploadedAt" in value;
    isInstance = isInstance && "uploadedBy" in value;
    isInstance = isInstance && "customerMediaCategory" in value;

    return isInstance;
}

export function CustomerMediaOutputFromJSON(json: any): CustomerMediaOutput {
    return CustomerMediaOutputFromJSONTyped(json, false);
}

export function CustomerMediaOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerMediaOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'originalname': json['originalname'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'key': json['key'],
        'type': json['type'],
        'size': json['size'],
        'uploadedAt': (new Date(json['uploadedAt'])),
        'uploadedBy': json['uploadedBy'],
        'customerMediaCategory': CustomerMediaCategoryFromJSON(json['customerMediaCategory']),
    };
}

export function CustomerMediaOutputToJSON(value?: CustomerMediaOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'originalname': value.originalname,
        'description': value.description,
        'key': value.key,
        'type': value.type,
        'size': value.size,
        'uploadedAt': (value.uploadedAt.toISOString()),
        'uploadedBy': value.uploadedBy,
        'customerMediaCategory': CustomerMediaCategoryToJSON(value.customerMediaCategory),
    };
}

